import React from 'react'

import MainFeed from './views/feed/MainFeed'
import SearchFeed from './views/feed/SearchFeed'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CssBaseline from '@material-ui/core/CssBaseline'
//import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import TopBar from './TopBar'
import SideBar from './SideBar'
import BottomBar from './BottomBar'
import ViewFeed from './views/feed/ViewFeed'
import NewVitBuilder from './views/builder/NewVitBuilder'
import UserFeed from './views/feed/UserFeed'
import VitBuilderLoader from './views/builder/VitBuilderLoader'
import { useSpring, animated } from 'react-spring'
import About from './views/About'

const pages = {
  FEED: 'feed',
  VIEW: 'view',
  EDIT: 'edit',
  SEARCH: 'search',
  ADD: 'add',
  USER: 'user',
  ABOUT: 'about',
}

export default function Feed({ path }) {
  const [isSideBarActive, setSideBarActive] = React.useState(false)
  const [isBottomBarActive, setBottomBarActive] = React.useState(false)

  const pathParts = path.split('/').slice(1)

  let subPath = []
  let page = pages.FEED
  let search = null
  let userId = null

  // first part is always [empty], then [fractal|search/query], then [path/to/vit]

  console.log('path parts: ', pathParts)

  if (pathParts.length > 1 && pathParts[0] === pages.SEARCH) {
    search = decodeURIComponent(pathParts[1])
    subPath = pathParts.slice(2)
    page = pages.SEARCH
  } else if (pathParts.length > 1 && pathParts[0] === pages.FEED) {
    subPath = pathParts.slice(1)
    page = pages.FEED
  } else if (
    pathParts.length > 1 &&
    (pathParts[0] === pages.VIEW || pathParts[0] === pages.EDIT)
  ) {
    page = pathParts[0]
    subPath = pathParts.slice(1)
  } else if (pathParts.length > 0 && pathParts[0] === pages.ADD) {
    page = pages.ADD
  } else if (pathParts.length > 1 && pathParts[0] === pages.USER) {
    page = pages.USER
    userId = pathParts[1]
    subPath = pathParts.slice(2)
  } else if (pathParts.length === 1 && pathParts[0] === pages.ABOUT) {
    page = pages.ABOUT
  }

  React.useEffect(() => {
    const handler = (event) => {
      const data = event.data
      if (data.vitlineId) {
        setBottomBarActive(true)
      }
    }

    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  }, [])

  React.useEffect(() => {
    if (
      page === pages.EDIT &&
      subPath.length === 1 &&
      subPath[0].startsWith('vitline')
    ) {
      setBottomBarActive(true)
    }
  }, [path])

  const [isScrolled, setScrolled] = React.useState(false)

  const onScrollCallback = React.useCallback((offset) => {
    if (offset > 0) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }, [])

  const urlPrefix = `/${page}/`

  const topBarHeight = 70
  const sideBarWidth = 540
  const bottomBarHeight = 230
  const collapsedBarWidth = 50

  const extraMargins = page === pages.ADD || page === pages.EDIT ? 16 : 0

  const margins = { top: 0, right: 0, bottom: 0, left: 0 }
  margins.top = topBarHeight + extraMargins
  margins.right =
    (isSideBarActive ? sideBarWidth : collapsedBarWidth) + extraMargins
  margins.bottom =
    (isBottomBarActive ? bottomBarHeight : collapsedBarWidth) + extraMargins
  margins.left = extraMargins

  let feedRenderer = null
  if (page === pages.FEED) {
    feedRenderer = (
      <MainFeed
        urlPrefix={urlPrefix}
        path={subPath}
        margins={margins}
        onScroll={onScrollCallback}
      ></MainFeed>
    )
  } else if (page === pages.SEARCH) {
    feedRenderer = (
      <SearchFeed
        urlPrefix={urlPrefix + search + '/'}
        path={subPath}
        searchTerm={search}
        margins={margins}
      ></SearchFeed>
    )
  } else if (page === pages.VIEW) {
    feedRenderer = (
      <ViewFeed
        urlPrefix={urlPrefix}
        path={subPath}
        margins={margins}
      ></ViewFeed>
    )
  } else if (page === pages.EDIT) {
    if (subPath.length === 1) {
      const [type, id] = subPath[0].split(':')

      if (type === 'vitline') {
        feedRenderer = (
          <ViewFeed
            urlPrefix={urlPrefix}
            path={subPath}
            margins={margins}
          ></ViewFeed>
        )
      } else if (type === 'vit') {
        feedRenderer = (
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              paddingTop: `calc(${margins.top}px)`,
              paddingRight: `calc(${margins.right}px)`,
              paddingBottom: `calc(${margins.bottom}px)`,
              paddingLeft: `calc(${margins.left}px)`,
            }}
          >
            <VitBuilderLoader key={id} vitId={id}></VitBuilderLoader>
          </div>
        )
      }
    } else if (subPath.length === 2) {
      const vitlineId = subPath[0].split(':')[1]
      const index = parseInt(subPath[1].split(':')[1])
      feedRenderer = (
        <div
          style={{
            width: '100%',
            height: '100%',
            paddingTop: `calc(${margins.top}px)`,
            paddingRight: `calc(${margins.right}px)`,
            paddingBottom: `calc(${margins.bottom}px)`,
            paddingLeft: `calc(${margins.left}px)`,
          }}
        >
          <div
            style={{
              overflow: 'hidden scroll',
              height: '100%',
              width: '100%',
            }}
          >
            <VitBuilderLoader
              key={vitlineId + ':' + index}
              parentVitlineId={vitlineId}
              index={index}
            ></VitBuilderLoader>
          </div>
        </div>
      )
    }
  } else if (page === pages.ADD) {
    feedRenderer = (
      <div
        style={{
          width: '100%',
          height: '100%',
          paddingTop: `calc(${margins.top}px)`,
          paddingRight: `calc(${margins.right}px)`,
          paddingBottom: `calc(${margins.bottom}px)`,
          paddingLeft: `calc(${margins.left}px)`,
        }}
      >
        <NewVitBuilder></NewVitBuilder>
      </div>
    )
  } else if (page === pages.USER) {
    feedRenderer = (
      <UserFeed
        userId={userId}
        urlPrefix={urlPrefix}
        path={subPath}
        margins={margins}
      ></UserFeed>
    )
  } else if (page === pages.ABOUT) {
    feedRenderer = <About></About>
  }

  const bottomBarCalc = `${bottomBarHeight}px`
  const sidebarCalc = `${sideBarWidth}px`
  const collapsedCalc = `${collapsedBarWidth}px`

  const rightSpringProps = useSpring({
    transform: isSideBarActive
      ? `translate(-${sidebarCalc}, 0)`
      : `translate(-${collapsedCalc}, 0)`,
  })

  const bottomSpringProps = useSpring({
    transform: isBottomBarActive
      ? `translate(0, -${bottomBarCalc})`
      : 'translate(0, -50px)',
  })

  return (
    <div
      style={{
        width: 'calc(100vw)',
        height: 'calc(100vh)',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <div
        className="header"
        style={{
          position: 'absolute',
          width: `calc(100vw - ${
            isSideBarActive ? sidebarCalc : collapsedCalc
          })`,
          height: '60px',
          top: 0,
          left: 0,
          zIndex: 101,
          transition: 'all 0.75s ease-in-out',
        }}
      >
        <ElevateAppBar elevation={isScrolled ? 6 : 0}>
          <TopBar search={search}></TopBar>
        </ElevateAppBar>
      </div>

      <animated.div
        style={{
          position: 'absolute',
          width: sidebarCalc,
          height: isBottomBarActive
            ? `calc(100vh - ${bottomBarCalc})`
            : `calc(100vh - ${collapsedCalc})`,
          right: `calc(-${sideBarWidth}px)`,
          transform: rightSpringProps.transform,
          zIndex: 102,
        }}
      >
        <SideBar expanded={isSideBarActive}></SideBar>
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: `${collapsedBarWidth}px`,
            left: 0,
            top: 0,
            cursor: 'pointer',
          }}
          onClick={(e) => {
            var rect = e.target.getBoundingClientRect()
            var x = e.clientX - rect.left

            if (x < collapsedBarWidth) {
              setSideBarActive(!isSideBarActive)
            }
          }}
        ></div>
      </animated.div>
      <animated.div
        style={{
          position: 'absolute',
          width: `calc(100vw)`,
          height: bottomBarCalc,
          left: 0,
          bottom: `calc(-1 * ${bottomBarCalc})`,
          transform: bottomSpringProps.transform,
          zIndex: 103,
        }}
      >
        <BottomBar expanded={isBottomBarActive}></BottomBar>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: `${collapsedBarWidth}px`,
            left: 0,
            top: 0,
            cursor: 'pointer',
          }}
          onClick={(e) => {
            var rect = e.target.getBoundingClientRect()
            var y = e.clientY - rect.top

            if (y < collapsedBarWidth) {
              setBottomBarActive(!isBottomBarActive)
            }
          }}
        ></div>
      </animated.div>
      {feedRenderer}
    </div>
  )
}
/*
function ElevationScroll(props) {
  const { children } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: props.scrollContainer,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 4,
  })
}*/

function ElevateAppBar(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        style={{ background: 'white', width: '100%', height: '60px' }}
        elevation={props.elevation}
      >
        <Toolbar style={{ width: '100%' }}>{props.children}</Toolbar>
      </AppBar>
    </React.Fragment>
  )
}
