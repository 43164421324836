import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URI || '/graphql',
  cache: new InMemoryCache({
    typePolicies: {
      Vit: {
        keyFields: ['vitId'],
      },
      VitLine: {
        keyFields: ['vitlineId'],
      },
      YouTubeVideo: {
        keyFields: ['videoId'],
      },
      Query: {
        fields: {
          feedAll: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming, { args: { offset } }) {
              if (offset === 0) {
                return incoming
              }
              return [...existing, ...incoming]
            },
          },
          search: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: ['searchString'],
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming, { args: { offset } }) {
              if (offset === 0) {
                return incoming
              }
              return [...existing, ...incoming]
            },
          },
          userFeed: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: ['userId'],
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming, { args: { offset } }) {
              if (offset === 0) {
                return incoming
              }
              return [...existing, ...incoming]
            },
          },
          getUserFavs: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming, { args: { offset } }) {
              if (offset === 0) {
                return incoming
              }
              return [...existing, ...incoming]
            },
          },
          getUserVits: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming, { args: { offset } }) {
              if (offset === 0) {
                return incoming
              }
              return [...existing, ...incoming]
            },
          },
          getUserPublishedVitlines: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming, { args: { offset } }) {
              if (offset === 0) {
                return incoming
              }
              return [...existing, ...incoming]
            },
          },
          getUserDraftVitlines: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming, { args: { offset } }) {
              if (offset === 0) {
                return incoming
              }
              return [...existing, ...incoming]
            },
          },
        },
      },
    },
  }),
})

const Main = () => (
  <ApolloProvider client={client}>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </ApolloProvider>
)

ReactDOM.render(<Main />, document.getElementById('root'))
registerServiceWorker()
