import React from 'react'

import { GraphMapping } from '../graph/GraphMapping'
import { generateLayoutPosibilities } from './FeedHelper'
import VitsAPI from '../../api/VitsAPI'
import GraphFeed from './GraphFeed'
import VitNode from '../graph/nodes/VitNode'
import VitIndexNode from '../graph/nodes/VitIndexNode'
import VitLineNode from '../graph/nodes/VitlineNode'
import YouTubeVideoNode from '../graph/nodes/YouTubeVideoNode'

function DraftVitLineFeed({ ...props }) {
  const [draft] = VitsAPI.useVitlineDraft()
  if (draft) {
    return (
      <GraphFeed
        dataEntries={[draft]}
        zoomScale1={1.0}
        zoomScale2={1.0}
        {...props}
      ></GraphFeed>
    )
  }
  return null
}

function VitLineFeed({ vitlineId, ...props }) {
  const data = VitsAPI.useVitLineData(vitlineId)

  if (data) {
    return <GraphFeed dataEntries={[data]} {...props}></GraphFeed>
  }
  return null
}

function VitFeed({ vitId, ...props }) {
  const data = VitsAPI.useVitData(vitId)

  if (data) {
    return <GraphFeed dataEntries={[data]} {...props}></GraphFeed>
  }
  return null
}

function VideoFeed({ videoId, ...props }) {
  const data = VitsAPI.useVitLineData(videoId)

  if (data) {
    return <GraphFeed dataEntries={[data]} {...props}></GraphFeed>
  }
  return null
}

export default function ViewFeed({ path, urlPrefix, margins, editor }) {
  const [type, id] = path[0].split(':')

  const nodeFactory = React.useMemo(() => {
    return {
      getId: (dataEntry) => {
        if (dataEntry.__typename === 'Vit') {
          return 'vit:' + dataEntry.vitId
        } else if (dataEntry.__typename === 'VitLine') {
          return 'vitline:' + dataEntry.vitlineId
        } else if (dataEntry.__typename === 'YouTubeVideo') {
          return 'video:' + dataEntry.videoId
        } else if (
          dataEntry.__typename === '_VitLineVitIndexes' ||
          dataEntry.__typename === '_BlankVit'
        ) {
          return 'index:' + dataEntry.index
        }
      },
      getLayouts: (dataEntry) => {
        let numChildren = getNumChildren(dataEntry)
        if (dataEntry.__typename === 'VitLine' && editor) {
          numChildren = VitsAPI.maxVits
        } else if (
          dataEntry.__typename === '_VitLineVitIndexes' ||
          dataEntry.__typename === '_BlankVit'
        ) {
          return [
            [
              [
                GraphMapping.FILLED,
                GraphMapping.FILLED,
                GraphMapping.FILLED,
                GraphMapping.FILLED,
              ],
              [
                GraphMapping.FILLED,
                GraphMapping.FILLED,
                GraphMapping.FILLED,
                GraphMapping.FILLED,
              ],
              [
                GraphMapping.FILLED,
                GraphMapping.FILLED,
                GraphMapping.FILLED,
                GraphMapping.FILLED,
              ],
              [
                GraphMapping.FILLED,
                GraphMapping.FILLED,
                GraphMapping.FILLED,
                GraphMapping.FILLED,
              ],
            ],
          ]
        }
        return getLayoutPossibilities(numChildren)
      },
      createNode: ({ graphConfig, data }) => {
        const key = graphConfig.path.join('/')
        if (data.__typename === 'Vit') {
          return (
            <VitNode
              key={key}
              vitId={data.vitId}
              urlPrefix={urlPrefix}
              graphConfig={graphConfig}
              getChildren={() => {
                return undefined
              }}
            ></VitNode>
          )
        } else if (data.__typename === 'VitLine') {
          return (
            <VitLineNode
              key={key}
              vitlineId={data.vitlineId}
              urlPrefix={urlPrefix}
              graphConfig={graphConfig}
              getChildren={(data) => {
                if (editor) {
                  const vitline = data
                  const childrenArray = Array(VitsAPI.maxVits)
                    .fill(null)
                    .map((e, index) => {
                      return {
                        __typename: '_BlankVit',
                        index: index,
                      }
                    })
                  if (vitline.vitIndexes) {
                    vitline.vitIndexes.forEach((vitIndex) => {
                      childrenArray[vitIndex.index] = vitIndex
                    })
                  } else {
                    VitsAPI.vitsFromVitLine(vitline).forEach((vit, index) => {
                      if (vit) {
                        childrenArray[index] = {
                          __typename: '_VitLineVitIndexes',
                          Vit: vit,
                          index: index,
                        }
                      }
                    })
                  }
                  return childrenArray
                } else {
                  if (data.__typename === 'VitLine') {
                    return VitsAPI.uniqueVitsFromVitLine(data)
                  } else if (data.__typename === 'Vit') {
                    return [data]
                  }
                }
              }}
            ></VitLineNode>
          )
        } else if (data.__typename === 'YouTubeVideo') {
          return (
            <YouTubeVideoNode
              key={key}
              videoId={data.videoId}
              urlPrefix={urlPrefix}
              graphConfig={graphConfig}
            ></YouTubeVideoNode>
          )
        } else if (
          data.__typename === '_VitLineVitIndexes' ||
          data.__typename === '_BlankVit'
        ) {
          return (
            <VitIndexNode
              key={key}
              parentVitlineId={id}
              vitIndex={data.index}
              urlPrefix={urlPrefix}
              graphConfig={graphConfig}
              editor={editor}
            ></VitIndexNode>
          )
        }
      },
    }
  }, [editor, path])

  if (type === 'vitline') {
    if (id === 'draft') {
      return (
        <DraftVitLineFeed
          margins={margins}
          path={path}
          nodeFactory={nodeFactory}
        ></DraftVitLineFeed>
      )
    }
    return (
      <VitLineFeed
        vitlineId={id}
        margins={margins}
        path={path}
        nodeFactory={nodeFactory}
      ></VitLineFeed>
    )
  } else if (type === 'vit') {
    return (
      <VitFeed
        vitId={id}
        margins={margins}
        path={path}
        nodeFactory={nodeFactory}
      ></VitFeed>
    )
  } else if (type === 'video') {
    return (
      <VideoFeed
        videoId={id}
        margins={margins}
        path={path}
        nodeFactory={nodeFactory}
      ></VideoFeed>
    )
  }

  return null
}

function getNumChildren(dataEntry) {
  if (dataEntry.__typename === 'VitLine') {
    if (dataEntry.vitlineId === 'draft') {
      return VitsAPI.maxVits
    }
    return VitsAPI.uniqueVitsFromVitLine(dataEntry).length
  } else if (dataEntry.__typename === 'Vit') {
    return 0
  } else if (dataEntry.__typename === 'VitIndex') {
    return 0
  } else if (dataEntry.__typename === 'YouTubeVideo') {
    return 0
  } else {
    return 0
  }
}

function getLayoutPossibilities(childrenCount) {
  return generateLayoutPosibilities(3, childrenCount)
}
