import React from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'
import VitsAPI from './api/VitsAPI'
import ReactWordcloud from 'react-wordcloud'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'
import Modal from '@material-ui/core/Modal'
import About from './views/About'
import Box from '@material-ui/core/Box'
import { CreateButton } from './views/CreateButton'

const style = {
  position: 'absolute',
  top: '0',
  left: '0',
  // transform: 'translate(-50%, -50%)',
  width: '1000px',
  bgcolor: 'red',
  backgroundColor: 'red',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const colorOptions = [
  '#89bea0',
  '#71b18c',
  '#5aa479',
  '#429766',
  '#2b8a53',
  '#137d40',
]

const options = {
  colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
  enableTooltip: false,
  deterministic: true,
  fontFamily: 'impact',
  fontSizes: [16, 60],
  fontStyle: 'normal',
  fontWeight: 'normal',
  padding: 4,
  rotations: 3,
  rotationAngles: [0, 0],
  scale: 'log',
  spiral: 'archimedean',
  transitionDuration: 200,
}

export default function TopBar({ search }) {
  const history = useHistory()
  const [focussed, setFocus] = React.useState(false)
  const tagCloud = VitsAPI.useTagCloud()
  const [hoveredTag, setHoveredTag] = React.useState(undefined)

  const [showAboutModal, setShowAboutModal] = React.useState(false)
  const [userId, loginWithPopup] = VitsAPI.useUserId()

  console.log('tagCloud', tagCloud)

  let maxTagCloudScore = 0
  if (tagCloud) {
    tagCloud.forEach((tag) => {
      maxTagCloudScore = Math.max(maxTagCloudScore, tag.score)
    })
  }

  const inputRef = React.useRef()

  return (
    <div
      style={{
        width: '100%',
        position: 'absolute',
        top: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
    >
      <Link to="/">
        <img
          style={{ display: 'flex', maxHeight: '29px', marginTop: '4px' }}
          src="/img/vits-logo.png"
        ></img>
      </Link>
      <div
        style={{
          marginLeft: '24px',
          marginTop: '5px',

          maxWidth: '600px',
          height: '30px',
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: 'var(white)',
          border: '1.25px solid var(--unnamed-color-137d3f)',
          borderRadius: 5,
          flexGrow: 1,

          justifyContent: 'flex-start',
        }}
      >
        <div style={{ margin: '4px' }}>
          <SearchIcon color="primary" style={{ fontSize: 20 }}></SearchIcon>
        </div>
        <input
          autoComplete={'off'}
          ref={inputRef}
          style={{
            display: 'flex',
            flexGrow: 1,
            border: 'none',
            backgroundColor: 'white',
            borderRadius: 5,
            color: 'var(--unnamed-color-137d3f)',
            fontFamily: 'Courier',
          }}
          type="text"
          name="name"
          value={search ?? ''}
          onChange={(e) => {
            const value = e.target.value
            if (value === '') {
              history.goBack()
            }
            if (search) {
              history.replace('/search/' + encodeURIComponent(e.target.value))
            } else {
              history.push('/search/' + encodeURIComponent(e.target.value))
            }
          }}
          onBlur={() => {
            setTimeout(() => setFocus(false), 100)
          }}
          onFocus={() => {
            setFocus(true)
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'flex-end',
          paddingRight: '100px',
        }}
      >
        <Tooltip title="ABOUT US" aria-label="add">
          <Fab
            size="small"
            color="default"
            className={'fab'}
            onClick={() => {
              //history.push('/about')
              setShowAboutModal(true)
            }}
          >
            <div style={{ color: '#137D3F' }}>?</div>
          </Fab>
        </Tooltip>
        <div style={{ width: 15 }}></div>
        <CreateButton />
      </div>
      <Modal
        open={showAboutModal}
        onClose={() => {
          setShowAboutModal(false)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          css={{
            backgroundColor: 'white',
            left: '50%',
            top: '10%',
            width: '50%',
            height: '80%',
            transform: 'translate(50%, 10%)',
            border: '2px solid darkgray',
          }}
        >
          <About></About>
        </Box>
      </Modal>
      {focussed && (!search || search.length === 0) && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            marginTop: '12px',
            marginLeft: '-24px',
            width: '100%',
            height: 'calc(90vh)',
            backgroundColor: 'white',
            zIndex: 100,
          }}
          onMouseDown={(e) => {
            e.preventDefault()
            e.stopPropagation()
            console.log('MOUSE DOWN')
          }}
        >
          <div
            style={{ position: 'absolute', top: 0, left: 0, fontSize: '300%' }}
          >
            <IconButton
              size="lg"
              color="primary"
              onClick={(e) => {
                e.stopPropagation()
                if (inputRef.current) {
                  inputRef.current.blur()
                }
              }}
            >
              <ArrowBackIcon fontSize={'inherit'}></ArrowBackIcon>
            </IconButton>
          </div>
          <ReactWordcloud
            options={options}
            callbacks={{
              getWordColor: (word) =>
                colorOptions[
                  word.text === hoveredTag
                    ? colorOptions.length - 1
                    : Math.floor(
                        (word.value / (maxTagCloudScore + 1)) *
                          colorOptions.length
                      )
                ],
              /*`rgb(50, ${
                  50 +
                  Math.sqrt(
                    (maxTagCloudScore - word.value) / maxTagCloudScore
                  ) *
                    120
                }, 50)`,*/
              onWordClick: (word, event) => {
                console.log('clicked word')
                event.stopPropagation()
                event.preventDefault()
                history.push('/search/' + encodeURIComponent('#' + word.text))
              },

              onWordMouseOver: (word) => {
                setHoveredTag(word.text)
              },
            }}
            words={
              tagCloud
                ? tagCloud.map((tag) => ({ text: tag.tag, value: tag.score }))
                : []
            }
          ></ReactWordcloud>
        </div>
      )}
    </div>
  )
}
