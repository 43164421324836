import React from 'react'

import LightPlayer from './player/LightPlayer'

function convertThumbnailUrl(thumbnail) {
  if (!thumbnail || !thumbnail.thumbnailUrl) {
    return null
  }
  return thumbnail.thumbnailUrl
}

export default function VitPreview({
  vit,
  url,
  image = '/loader.gif',
  onDragStart,
  onClick,
  fromIndex,
  showPreview,
  color = 'primary',
  draggable = true,
}) {
  const imageRef = React.useRef(null)
  const [isImageLoaded, setImageLoaded] = React.useState(false)

  let imageUrl = image
  if (vit && (vit.thumbnail || vit.source)) {
    imageUrl =
      convertThumbnailUrl(vit.thumbnail) ??
      'https://img.youtube.com/vi/' + vit.source.videoId + '/0.jpg'
  }

  return (
    <div
      draggable={!!vit && draggable}
      onClick={(e) => {
        if (onClick) {
          onClick(e)
        }
      }}
      onDragStart={(e) => {
        e.dataTransfer.setData(
          'text/plain',
          JSON.stringify({ vit: vit, fromIndex: fromIndex })
        )

        if (imageRef.current) {
          e.dataTransfer.setDragImage(imageRef.current, 10, 10)
        }

        if (onDragStart) {
          onDragStart(e)
        }
      }}
      className={vit && vit.thumbnail ? 'vitThumbnail' : 'youtubeThumbnail'}
      style={{
        position: 'relative',
        backgroundImage: `url(${imageUrl})`,
        backgroundColor: isImageLoaded ? 'black' : 'rgb(0, 0, 0, 0.5)',
      }}
    >
      <div
        ref={imageRef}
        style={{
          position: 'absolute',
          zIndex: -1,
          top: 0,
          left: 0,
        }}
      >
        <div
          style={{
            clipPath:
              'polygon(0% 0%, calc(100% - 31px) 0%, 100% 31px, 100% 100%, 0 100%)',
          }}
        >
          <img
            src={imageUrl}
            style={{
              width: '172px',
              height: '96px',
            }}
            onLoad={() => {
              setImageLoaded(true)
            }}
          ></img>
          <img
            src={'/img/vit_drag_overlay.png'}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '172px',
              height: '96px',
            }}
          ></img>
        </div>
      </div>

      {showPreview && (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
          }}
        >
          <LightPlayer
            sequence={[vit]}
            color={color}
            url={url}
            disableTimeline
          ></LightPlayer>
        </div>
      )}
    </div>
  )
}
