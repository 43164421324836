import React from 'react'

import YouTube from 'react-youtube'

export const PLAYER_STATUS = {
  NEW: 0,
  PREPLAYING: 1,
  PLAYING: 2,
  DONE: 3,
}

export default class VitPlayer extends React.Component {
  constructor(props) {
    super(props)

    this.player = null

    this.onReady = this.onReady.bind(this)
    this.onStateChange = this.onStateChange.bind(this)
    this.startPlayer = this.startPlayer.bind(this)
    this.endPlayer = this.endPlayer.bind(this)

    this.state = { status: PLAYER_STATUS.NEW }
  }

  startPlayer(offset = 0) {
    console.log('[' + Date.now() + '] starting')
    this.timeForStart = Date.now()
    if (offset > 0) {
      this.player.loadVideoById({
        videoId: this.props.vit.source.videoId,
        startSeconds: this.props.vit.start + offset,
        endSeconds: this.props.vit.end,
      })
    } else {
      this.player.playVideo()
    }
    this.setState({ status: PLAYER_STATUS.PREPLAYING })
  }

  resumePlayer() {
    console.log('[' + Date.now() + '] manual play')
    this.player.playVideo()
  }

  pausePlayer() {
    console.log('[' + Date.now() + '] manual pause')
    this.player.pauseVideo()
  }

  startPreplay() {
    console.log('[' + Date.now() + '] preplaying')
    this.timeForStart = Date.now()
    this.player.mute()
    this.player.playVideo()
    this.setState({ status: PLAYER_STATUS.PREPLAYING })
  }

  replay() {
    this.player.loadVideoById({
      videoId: this.props.vit.source.videoId,
      startSeconds: this.props.vit.start,
      endSeconds: this.props.vit.end,
    })
  }

  reloadVideo() {
    console.log('reloading')

    this.setState({ status: PLAYER_STATUS.NEW })
    this.player.cueVideoById({
      videoId: this.props.vit.source.videoId,
      startSeconds: this.props.vit.start,
      endSeconds: this.props.vit.end,
    })
  }

  endPlayer() {
    console.log('ending')

    this.setState({ status: PLAYER_STATUS.NEW })
  }

  isPlaying() {
    return this.state.status === PLAYER_STATUS.PLAYING
  }

  render() {
    if (!this.props.vit) {
      return <></>
    }

    const opts = {
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        start: this.props.vit.start,
        end: this.props.vit.end,
        controls: 0,
        showinfo: 0,
        modestbranding: 1,
      },
    }

    const style = {
      position: 'absolute',
      top: 0,
      left: 0,
      //height: this.props.playerHeight ? this.props.playerHeight + 'px' : '100%',
      height: '100%',
      width: this.props.playerWidth ? this.props.playerWidth + 'px' : '100%',
      zIndex: this.props.active ? 1 : -1,
    }

    return (
      <div style={style}>
        <YouTube
          videoId={this.props.vit.source.videoId}
          opts={opts}
          onReady={this.onReady}
          onStateChange={this.onStateChange}
        />
      </div>
    )
  }

  onReady(event) {
    console.log('[' + this.props.index + '] ready')
    this.player = event.target

    this.player.cueVideoById({
      videoId: this.props.vit.source.videoId,
      startSeconds: this.props.vit.start,
      endSeconds: this.props.vit.end,
    })

    this.props.onVitReady(this)
  }

  onStateChange(event) {
    console.log(
      '[' +
        Date.now() +
        '] onStateChange[' +
        this.props.index +
        ' @ ' +
        this.player.getCurrentTime() +
        ' ] status=' +
        Object.keys(PLAYER_STATUS)[this.state.status] +
        ' playerState=' +
        Object.keys(window.YT.PlayerState)[
          Math.min(
            Object.keys(window.YT.PlayerState).length - 1,
            this.player.getPlayerState() + 1
          )
        ]
    )
    if (event.data === window.YT.PlayerState.PLAYING) {
      if (this.state.status === PLAYER_STATUS.PREPLAYING) {
        this.timeout = Date.now() - this.timeForStart
        this.setState({ status: PLAYER_STATUS.PLAYING })
        this.props.onVitStarted(this)
      } else if (this.state.status === PLAYER_STATUS.PLAYING) {
        this.props.onVitStarted(this)
      }
    } else if (event.data === window.YT.PlayerState.ENDED) {
      if (this.state.status === PLAYER_STATUS.PLAYING) {
        this.setState({ status: PLAYER_STATUS.DONE })
        this.props.onVitDone(this)
      }
    } else if (
      event.data === window.YT.PlayerState.BUFFERING ||
      event.data === window.YT.PlayerState.PAUSED
    ) {
      if (this.state.status === PLAYER_STATUS.PLAYING) {
        if (this.props.onVitPaused) {
          // TODO Figure out why this crashes sometimes.
          this.props.onVitPaused(this)
        }
      }
    }
  }

  componentDidMount() {}

  componentWillUnmount() {}
}
