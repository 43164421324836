import React from 'react'
import VitPreview from './VitPreview'
import LightPlayer from './player/LightPlayer'

export default function VitlinePreview({
  id,
  vits,
  url,
  showPreview,
  color = 'primary',
}) {
  if (!showPreview) {
    return (
      <VitPreview
        vit={vits[0]}
        showPreview={false}
        draggable={false}
      ></VitPreview>
    )
  }

  return (
    <LightPlayer id={id} sequence={vits} color={color} url={url}></LightPlayer>
  )
}
