import React from 'react'
import YouTube from 'react-youtube'

export default function Test() {
  const [someState, setSomeState] = React.useState(false)
  return (
    <div>
      <Example></Example>
      <div
        style={{
          position: 'absolute',
          top: 50,
          left: 50,
          width: 200,
          height: 200,
          backgroundColor: 'white',
        }}
      >
        <button onClick={() => setSomeState(!someState)}>Press me</button>
        {someState ? 'STATE=true' : 'STATE=false'}
      </div>
    </div>
  )
}

class Example extends React.Component {
  render() {
    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    }

    return <YouTube videoId="2g811Eo7K8U" opts={opts} onReady={this._onReady} />
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo()
  }
}
