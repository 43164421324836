import React from 'react'

import { Link } from 'react-router-dom'
import { GraphContext, pathEquals } from '../GraphHelpers'
import GraphNode from './GraphNode'
import VitsAPI from '../../../api/VitsAPI'
import VitPreviewPlayer from '../../player/VitPlayer'
import VitPreview from '../../VitPreview'
import VitBuilder from '../../builder/VitBuilder'
import NewVitBuilder from '../../builder/NewVitBuilder'
import { useHistory } from 'react-router'

function DraftVitIndexNode({ index, ...props }) {
  const [draft, draftVits] = VitsAPI.useVitlineDraft()

  if (draft) {
    return (
      <VitNodeRenderer
        vit={draftVits[index]}
        parentVitlineId={draft.vitlineId}
        index={index}
        {...props}
      ></VitNodeRenderer>
    )
  }
  return null
}

function VitLineVitIndexNode({ parentVitlineId, index, ...props }) {
  const data = VitsAPI.useVitAtVitLineIndexData(parentVitlineId, index)

  if (data || data === null) {
    return (
      <VitNodeRenderer
        vit={data}
        parentVitlineId={parentVitlineId}
        index={index}
        {...props}
      ></VitNodeRenderer>
    )
  }
  return null
}

export default function VitIndexNode({
  parentVitlineId,
  vitIndex,
  editor,
  urlPrefix,
  graphConfig,
}) {
  const renderer = React.useMemo(() => {
    if (parentVitlineId === 'draft') {
      return (
        <DraftVitIndexNode
          index={vitIndex}
          urlPrefix={urlPrefix}
          editor={editor}
          graphConfig={graphConfig}
        ></DraftVitIndexNode>
      )
    } else {
      return (
        <VitLineVitIndexNode
          parentVitlineId={parentVitlineId}
          index={vitIndex}
          urlPrefix={urlPrefix}
          graphConfig={graphConfig}
          editor={editor}
        ></VitLineVitIndexNode>
      )
    }
  }, [parentVitlineId, vitIndex, editor])

  return (
    <GraphNode
      graphConfig={graphConfig}
      childData={undefined}
      renderer={renderer}
      urlPrefix={urlPrefix}
    ></GraphNode>
  )
}

function VitNodeRenderer({
  vit,
  parentVitlineId,
  index,
  urlPrefix,
  graphConfig,
  editor,
}) {
  const history = useHistory()
  const mapContext = React.useContext(GraphContext)
  const [isPlaying, setPlaying] = React.useState(false)
  const isSelected = pathEquals(mapContext.currentPath, graphConfig.path)
  let url = urlPrefix + graphConfig.path.join('/')

  const showEditor = editor

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'nowrap',
      }}
    >
      {!showEditor && vit && (
        <div className="vitTitle">
          <Link to={url}>{vit.title}</Link>
        </div>
      )}

      <div>
        <div
          style={{
            opacity: !isSelected && editor && !vit ? '1.0' : '0.0',
            transition: 'opacity 0.75s ease-in-out',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
          }}
        >
          <Link to={url}>
            <img
              style={{ maxWidth: '100%', maxHeight: '100%' }}
              src={'/img/add_vit_box.png'}
            ></img>
          </Link>
        </div>

        {!showEditor && vit && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
            }}
          >
            <Link style={{ width: '100%', height: '100%' }} to={url}>
              <VitPreview vit={vit}></VitPreview>
            </Link>
          </div>
        )}

        {editor && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
            }}
          >
            {!vit && (
              <NewVitBuilder
                parentVitlineId={parentVitlineId}
                index={index}
              ></NewVitBuilder>
            )}
            {vit && (
              <VitBuilder
                vit={vit}
                parentVitlineId={parentVitlineId}
                index={index}
              ></VitBuilder>
            )}
          </div>
        )}
        {!editor && isSelected && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: isPlaying ? 1 : -1,
            }}
          >
            <VitPreviewPlayer
              vit={vit}
              onVitReady={(player) => {
                setTimeout(() => {
                  player.startPlayer()
                }, 1000)
              }}
              onVitStarted={() => {
                setPlaying(true)
              }}
              onVitDone={() => {
                setPlaying(false)
              }}
            ></VitPreviewPlayer>
          </div>
        )}
        {!isSelected && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 5,
            }}
            onClick={() => {
              history.push(url)
            }}
          ></div>
        )}
      </div>
    </div>
  )
}
