import React from 'react'

import VitsAPI from '../../api/VitsAPI'
import DataFeed from './DataFeed'

function filterData(data) {
  const sourceMap = {}
  data.forEach((e) => {
    if (e.__typename === 'Vit') {
      const videoKey = e.source.videoId
      if (!sourceMap[videoKey]) {
        sourceMap[videoKey] = []
      }
      sourceMap[videoKey].push(e)
    }
  })

  let newData = [...data]

  Object.keys(sourceMap)
    .filter((videoId) => {
      return sourceMap[videoId].length > 1
    })
    .forEach((videoId) => {
      const vitIndex = newData.findIndex((dataEntry) => {
        return dataEntry.source && dataEntry.source.videoId === videoId
      })

      const firstVit = newData[vitIndex]

      newData = newData.filter((dataEntry) => {
        return (
          dataEntry.__typename !== 'Vit' ||
          dataEntry.source.videoId !== firstVit.source.videoId
        )
      })

      const newEntry = {
        __typename: '_VitGrouping',
        vits: sourceMap[firstVit.source.videoId].sort(
          (a, b) => a.start - b.start
        ),
        source: firstVit.source,
      }

      newData.splice(vitIndex, 0, newEntry)
    })
  return newData
}

const useFilter = false

export default function MainFeed({ ...props }) {
  const [data, loadMore] = VitsAPI.useMainFeedData()
  const [filteredData, setFilteredData] = React.useState(undefined)
  const [lastIndex, setLastIndex] = React.useState(0)

  React.useEffect(() => {
    if (data && lastIndex < data.length) {
      setFilteredData((prevFilteredData = []) => {
        const newFilteredData = filterData(data.slice(lastIndex))
        return [...prevFilteredData, ...newFilteredData]
      })
      setLastIndex(data.length)
    }
  }, [data, lastIndex])

  return (
    <DataFeed
      data={useFilter ? filteredData : data}
      loadMore={loadMore}
      {...props}
    ></DataFeed>
  )
}
