import React from 'react'

import {
  GraphContext,
  nodePointForConfig,
  childPointsForConfig,
  pathEquals,
} from '../GraphHelpers'
import { GraphMapping } from '../GraphMapping'

export default function GraphNode({
  graphConfig: { path, layoutConfig, graphFrame, nodeFactory },
  childData,
  renderer,
  infoRenderer,
  urlPrefix,
}) {
  const [animating, setAnimating] = React.useState(false)
  const [childNodes, setChildNodes] = React.useState(null)
  const mapContext = React.useContext(GraphContext)

  React.useEffect(() => {
    mapContext.registerPath(path, graphFrame)
    return () => {
      mapContext.unregisterPath(path)
    }
  }, [mapContext.registerPath, mapContext.unregisterPath, graphFrame, path])

  React.useEffect(() => {
    if (
      mapContext.previousPath &&
      mapContext.currentPath.length != mapContext.previousPath.length
    ) {
      setAnimating(true)

      // Clear animation just in case transitionend method doesn't get called.
      // This happens when you navigate to root directly from deep.
      setTimeout(() => {
        setAnimating(false)
      }, 1000)
    }
  }, [mapContext.previousPath, mapContext.currentPath])

  React.useEffect(() => {
    if (childData) {
      const newChildNodes = childData
        .filter((childNodeData) => {
          const childId = nodeFactory.getId(childNodeData)
          return !path.includes(childId)
        })
        .slice(0, numChildSpots)
        .map(childrenMapFunction(nodeFactory, layoutConfig, graphFrame, path))
      setChildNodes(newChildNodes)
    }
  }, [childData])

  const parentPath = path.length > 0 ? path.slice(0, path.length - 1) : null
  const grandParentPath =
    path.length > 1 ? path.slice(0, path.length - 2) : null
  const isGrandParentSelected =
    grandParentPath && pathEquals(mapContext.currentPath, grandParentPath)
  const isParentSelected =
    parentPath && pathEquals(mapContext.currentPath, parentPath)

  const shouldShowNode = (currentPath, currentHoverPath) => {
    const isParentSelected = parentPath && pathEquals(currentPath, parentPath)
    const isGrandParentSelected =
      grandParentPath && pathEquals(currentPath, grandParentPath)
    const isNodeSelected = pathEquals(currentPath, path)
    const isParentHovered =
      parentPath &&
      pathEquals(currentHoverPath, parentPath) &&
      isGrandParentSelected

    return isParentSelected || isNodeSelected || isParentHovered
  }

  const showNode = shouldShowNode(mapContext.currentPath, mapContext.hoverPath)
  const prevShowNode = shouldShowNode(
    mapContext.previousPath,
    mapContext.previousHoverPath
  )

  const isSiblingSelected = pathEquals(
    mapContext.currentPath.slice(0, -1),
    parentPath
  )
  const isChildSelected = pathEquals(mapContext.currentPath.slice(0, -1), path)

  let shouldRenderNode =
    showNode ||
    isSiblingSelected ||
    isChildSelected ||
    (mapContext.direction == -1 && isGrandParentSelected) ||
    showNode !== prevShowNode

  let shouldRenderChildren =
    pathEquals(mapContext.currentPath.slice(0, path.length), path) ||
    shouldRenderNode

  shouldRenderNode = shouldRenderNode && mapContext.pathLoaded

  const zoomingOut = mapContext.direction === -1
  const zoomingIn = mapContext.direction === 1

  const numChildSpots = childPointsForConfig(layoutConfig).length

  const { row, col, numRows, numCols } = graphFrame
  const zoomRow = mapContext.zoomFrame.row
  const zoomCol = mapContext.zoomFrame.col
  const zoomRows = mapContext.zoomFrame.numRows
  const zoomCols = mapContext.zoomFrame.numCols

  const unitHeight = layoutConfig.length + 1
  const unitWidth = layoutConfig[0].length + 1 //nodeRows
  let factor = 0.75

  const getTransform = (startNodePoint, endNodePoint) => {
    const scale = Math.min(
      mapContext.gridColumns / zoomCols,
      mapContext.gridVisibleRows / zoomRows
    )
    const nodeCols =
      numCols *
      ((endNodePoint[1] - startNodePoint[1] + 1) / layoutConfig[0].length)

    let nodeScale = scale * nodeCols

    let nodeRow =
      row +
      (startNodePoint[0] / layoutConfig.length) * numRows +
      mapContext.scrollRowOffset / scale
    let nodeCol = col + (startNodePoint[1] / layoutConfig[0].length) * numCols
    let translateX = (nodeCol - zoomCol) * 100 * scale
    let translateY = (nodeRow - zoomRow) * 100 * scale

    return [nodeScale, translateX, translateY]
  }

  const [startNodePoint, endNodePoint] = nodePointForConfig(layoutConfig)
  const [infoStartNodePoint, infoEndNodePoint] = infoRenderer
    ? nodePointForConfig(layoutConfig, GraphMapping.RESERVED)
    : [undefined, undefined]

  if (
    infoRenderer &&
    (isGrandParentSelected || isParentSelected) &&
    path.length > 1
  ) {
    infoStartNodePoint[0] -= 0.25
    infoStartNodePoint[1] -= 0.75
    infoEndNodePoint[1] += 0.75
  }

  const [nodeScale, translateX, translateY] = getTransform(
    startNodePoint,
    endNodePoint
  )

  const [infoNodeScale, infoTranslateX, infoTranslateY] = infoRenderer
    ? getTransform(infoStartNodePoint, infoEndNodePoint)
    : [undefined, undefined, undefined]

  const transition =
    (zoomingIn
      ? 'transform 0.75s ease-in-out 0.0s, '
      : 'transform 0.75s ease-in-out 0.0s, ') +
    (zoomingOut
      ? 'opacity 0.25s ease-in-out 0.5s'
      : 'opacity 0.75s ease-in-out')

  if (!shouldRenderNode) {
    return (
      <>
        <div key={path.join('/') + '/temp'}></div>
        {shouldRenderChildren && childNodes}
      </>
    )
  }

  return (
    <>
      {shouldRenderNode && (
        <>
          <div
            className={showNode ? 'item-show' : 'item-hide'}
            key={urlPrefix + '/' + path.join('/')}
            style={{
              position: 'absolute',
              left: '0px',
              top: '0px',
              width: `calc(100vw * ${factor} * ${unitWidth} / ${mapContext.gridColumns})`,
              height: `calc(100vw * ${factor} * ${unitHeight} / ${mapContext.gridColumns} / ${mapContext.aspectRatio})`,
              transition: transition,
              fontSize: (unitWidth * 2) / layoutConfig[0].length + 'vw',

              transformOrigin: '0 0',
              transform: `translate(${translateX / unitWidth / factor}%, ${
                translateY / unitHeight / factor
              }%) scale(${nodeScale / unitWidth / factor}, ${
                nodeScale / unitHeight / factor
              }) `,
              willChange: animating ? 'transform, opacity' : 'auto',
            }}
            onTransitionEnd={() => {
              setAnimating(false)
            }}
            onMouseEnter={() => {
              if (isParentSelected && !animating) {
                mapContext.registerHoverPath(path)
              }
            }}
            onMouseLeave={() => {
              if (isParentSelected) {
                //mapContext.unregisterHoverPath(path)
              }
            }}
          >
            {renderer}
          </div>

          {infoRenderer && (
            <div
              className={showNode ? 'item-show' : 'item-hide'}
              key={'info-' + urlPrefix + '/' + path.join('/')}
              style={{
                position: 'absolute',
                left: '0px',
                top: '0px',
                width: `calc(100vw * ${factor} * ${unitWidth} / ${mapContext.gridColumns})`,
                height: `calc(100vw * ${factor} * ${unitHeight} / ${mapContext.gridColumns} / ${mapContext.aspectRatio})`,
                transition: transition,
                fontSize: (unitWidth * 2) / layoutConfig[0].length + 'vw',

                transformOrigin: '0 0',
                transform: `translate(${
                  infoTranslateX / unitWidth / factor
                }%, ${infoTranslateY / unitHeight / factor}%) scale(${
                  infoNodeScale / unitWidth / factor
                }, ${infoNodeScale / unitHeight / factor}) `,
                willChange: animating ? 'transform, opacity' : 'auto',
                pointerEvents: 'none',
              }}
              onTransitionEnd={() => {
                setAnimating(false)
              }}
              onMouseEnter={() => {
                if (isParentSelected && !animating) {
                  mapContext.registerHoverPath(path)
                }
              }}
              onMouseLeave={() => {
                if (isParentSelected) {
                  //mapContext.unregisterHoverPath(path)
                }
              }}
            >
              {infoRenderer}
            </div>
          )}
        </>
      )}
      {shouldRenderChildren && childNodes}
    </>
  )
}

function childrenMapFunction(nodeFactory, layoutConfig, graphFrame, path) {
  const { row, col, numRows, numCols } = graphFrame

  return (child, index, list) => {
    const childId = nodeFactory.getId(child)
    const childLayout = nodeFactory.getLayouts(child)[0]

    const childPath = [...path, childId]
    const [childRow, childCol] = childPointsForConfig(
      layoutConfig,
      list.length
    )[index]

    const rowSize = numRows / layoutConfig.length
    const colSize = numCols / layoutConfig[0].length

    const childFrame = {
      row: row + childRow * rowSize,
      col: col + childCol * colSize,
      numRows: rowSize,
      numCols: colSize,
    }

    return nodeFactory.createNode({
      graphConfig: {
        path: childPath,
        layoutConfig: childLayout,
        graphFrame: childFrame,
        nodeFactory: nodeFactory,
      },
      data: child,
    })
  }
}
