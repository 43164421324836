import React from 'react'
import { SpringValue } from 'react-spring'

export enum GraphLayoutEnum {
  CHILD,
  NODE,
  RESERVED,
  EMPTY,
  INFO,
}

export type GraphLayout = {
  map: GraphLayoutEnum[][]
  childSize: GraphSize
}

export type GraphMargins = {
  left: number
  top: number
  right: number
  bottom: number
}

export type GraphFrame = {
  row: number
  col: number
  numRows: number
  numCols: number
}

export type GraphZoomContext = {
  path: string[]
  graphFrame: GraphFrame
  referenceFrame?: GraphFrame
}

export type GraphSize = {
  numRows: number
  numCols: number
}

export type GraphZoomConfig = [
  marginLeft: number,
  marginTop: number,
  marginRight: number,
  marginBottom: number,

  zoomRow: number,
  zoomCol: number,
  zoomNumRows: number,
  zoomNumCols: number
]

export type FractalGraphZoomState = 'root' | 'prepare' | 'zooming' | 'zoomed'

export type FractalGraphContextProps = {
  /* transformForFrame(
    graphFrames: GraphFrame[],
    graphFrameIndex: SpringValue,
    layout: GraphLayout,
    size: GraphSize
  ): string;*/
  zoomConfig: GraphZoomConfig
  dimensionsForLayout(graphLayout: GraphLayout): {
    width: number
    height: number
  }
  factory: (
    data: any,
    graphFrame: GraphFrame,
    layout: GraphLayout,
    graphSize: GraphSize
  ) => JSX.Element

  zoomContext: GraphZoomContext
  isScrolling: boolean
  numDataEntries: number

  windowWidth: number
  windowHeight: number
  aspectRatio: number
  columns: number
  currentRowOffset: number
  scrollRowOffset: number

  springPropsZoomConfig: SpringValue<number[]>
  springPropsRowOffset: SpringValue<number>
}

export const FractalGraphContext = React.createContext<
  Partial<FractalGraphContextProps>
>({})

export type FractalGraphViewProps = {
  data: any[]
  factory: (
    data: any,
    graphFrame: GraphFrame,
    layout: GraphLayout,
    graphSize: GraphSize
  ) => JSX.Element
  keyPath: (data: any) => string
  layout: (data: any) => GraphLayout
  size: (data: any) => GraphSize
  onZoomContextChange: (zoomContext: GraphZoomContext | undefined) => void
  loadMore?: () => void
  zoomContext: GraphZoomContext | undefined
  margins?: GraphMargins
  columns: number
  aspectRatio: number
  header?: JSX.Element
  footer?: JSX.Element
  onScroll?: (offset: number) => void
}

export type GraphNodeProps = {
  path: string[]
  graphFrame: GraphFrame
  layout: GraphLayout
  node: JSX.Element
  onHover?: (hover: boolean) => void
}
