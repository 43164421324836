import React from 'react'

import { Route, BrowserRouter as Router } from 'react-router-dom'

import Profile from './components/Profile'
import { Auth0Provider } from '@auth0/auth0-react'
import ThumbnailGenerator from './components/ThumbnailGenerator'
import Test from './components/Test'
//import FractalGraphViewFeed from './components/FractalGraphViewFeed'
import Feed from './components/Feed'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#137D3F',
    },
    secondary: {
      main: '#FF0000',
    },
    white: {
      main: '#000000',
    },
    text: {
      primary: '#137D3F',
      secondary: 'unset',
    },
  },
  typography: {
    h5: {
      fontWeight: 600,
    },
    button: {
      fontFamily: ['Space Mono'],
      fontWeight: 'bold',
      fontSize: '22px',
    },
    variantMapping: {
      h1: 'h2',
      h2: 'h2',
      h3: 'h2',
      h4: 'h2',
      h5: 'h2',
      h6: 'h2',
      subtitle1: 'h2',
      subtitle2: 'h2',
      body1: 'span',
      body2: 'span',
    },
    fontFamily: [
      'var(--unnamed-font-family-space-mono)',
      'var(--unnamed-font-family-aktivgrotesk-regular)',
    ].join(','),
  },
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },

    MuiTypography: {
      variantMapping: {
        h1: 'h2',
        h2: 'h2',
        h3: 'h2',
        h4: 'h2',
        h5: 'h2',
        h6: 'h2',
        subtitle1: 'h2',
        subtitle2: 'h2',
        body1: 'span',
        body2: 'span',
      },
    },
  },
})

export default function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <div style={{ width: '100%', height: '100%' }}>
        <Router>
          <Auth0Provider
            domain="dev-cfr9ms22.us.auth0.com"
            clientId="FZjHu2nlQk1mDcQcgceA5jP8ngd68ePL"
            redirectUri={window.location.origin}
          >
            <Route
              render={({ location }) => {
                //const loginPath = '/login'
                //const logoutPath = '/logout'
                const profilePath = '/profile'
                const thumbnailGen = '/thumbs'
                const test = '/test'
                const about = '/about'

                if (location.pathname === profilePath) {
                  return <Profile></Profile>
                } else if (location.pathname === thumbnailGen) {
                  return <ThumbnailGenerator></ThumbnailGenerator>
                } else if (location.pathname === test) {
                  return <Test></Test>
                } else {
                  return <Feed path={location.pathname}></Feed>
                }
              }}
            />
          </Auth0Provider>
        </Router>
      </div>
    </MuiThemeProvider>
  )
}
