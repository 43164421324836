// portal.tsx
import * as React from "react";
import * as ReactDOM from "react-dom";

interface IProps {
  children: React.ReactNode;
}

export const FractalGraphPortal: React.FC<IProps> = ({ children }: IProps) => {
  const rootRef = React.useRef<HTMLElement | undefined | null>();

  React.useEffect(() => {
    rootRef.current = document && document.getElementById("fractal-graph-root");
  }, []);

  if (!rootRef.current) {
    return null;
  }
  return ReactDOM.createPortal(children, rootRef.current!);
};
