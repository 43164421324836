import React from 'react'

import { generateLayoutPosibilities } from './FeedHelper'
import GraphFeed from './GraphFeed'
import VitNode from '../graph/nodes/VitNode'
import VitLineNode from '../graph/nodes/VitlineNode'
import YouTubeVideoNode from '../graph/nodes/YouTubeVideoNode'
import VitGroupNode from '../graph/nodes/VitGroupNode'
import VitsAPI from '../../api/VitsAPI'

export default function DataFeed({
  data,
  loadMore,
  path,
  urlPrefix,
  margins,
  onScroll = undefined,
}) {
  const nodeFactory = React.useMemo(() => {
    return {
      getId: (dataEntry) => {
        if (dataEntry.__typename === 'Vit') {
          return 'vit:' + dataEntry.vitId
        } else if (dataEntry.__typename === 'VitLine') {
          return 'vitline:' + dataEntry.vitlineId
        } else if (dataEntry.__typename === 'YouTubeVideo') {
          return 'video:' + dataEntry.videoId
        } else if (dataEntry.__typename === '_VitGrouping') {
          return 'group:' + dataEntry.source.videoId
        }
      },
      getLayouts: (dataEntry) => {
        return getLayoutPossibilities(dataEntry)
      },
      createNode: ({ graphConfig, data }) => {
        const key = graphConfig.path.join('/')
        if (data.__typename === 'Vit') {
          return (
            <VitNode
              key={key}
              vitId={data.vitId}
              urlPrefix={urlPrefix}
              graphConfig={graphConfig}
              getChildren={(vit) => {
                return [vit.source, ...vit.vitlines]
              }}
            ></VitNode>
          )
        } else if (data.__typename === 'VitLine') {
          return (
            <VitLineNode
              showPlaybackContextualChildren
              key={key}
              vitlineId={data.vitlineId}
              urlPrefix={urlPrefix}
              graphConfig={graphConfig}
              getChildren={(data) => {
                if (data.__typename === 'VitLine') {
                  return VitsAPI.uniqueVitsFromVitLine(data)
                } else if (data.__typename === 'Vit') {
                  return [data.source, ...data.vitlines]
                }
              }}
            ></VitLineNode>
          )
        } else if (data.__typename === 'YouTubeVideo') {
          return (
            <YouTubeVideoNode
              key={key}
              videoId={data.videoId}
              urlPrefix={urlPrefix}
              graphConfig={graphConfig}
              getChildren={(video) => {
                return video.vits
              }}
            ></YouTubeVideoNode>
          )
        } else if (data.__typename === '_VitGrouping') {
          return (
            <VitGroupNode
              key={key}
              data={data}
              urlPrefix={urlPrefix}
              graphConfig={graphConfig}
              getChildren={(grouping) => {
                return grouping.vits
              }}
            ></VitGroupNode>
          )
        }
      },
    }
  }, [urlPrefix])

  return (
    <div>
      {data && (
        <GraphFeed
          margins={margins}
          path={path}
          dataEntries={data}
          nodeFactory={nodeFactory}
          loadMore={loadMore}
          onScroll={onScroll}
        ></GraphFeed>
      )}
    </div>
  )
}

function numUniqueVits(vitline) {
  const uniqueVits = new Set()
  vitline.vitIndexes.forEach((vitIndex) => {
    uniqueVits.add(vitIndex.Vit.vitId)
  })
  return uniqueVits.length
}

function getNumChildren(dataEntry) {
  if (dataEntry.__typename === 'VitLine') {
    return numUniqueVits(dataEntry)
  } else if (dataEntry.__typename === 'Vit') {
    return 1 + dataEntry.vitlines.length
  } else if (dataEntry.__typename === 'YouTubeVideo') {
    return dataEntry.vits.length
  } else if (dataEntry.__typename === '_VitGrouping') {
    return dataEntry.vits.length
  }
}

function getLayoutPossibilities(dataEntry) {
  if (dataEntry === null) {
    return generateLayoutPosibilities(1, childrenCount)
  }

  const childrenCount = getNumChildren(dataEntry)

  if (dataEntry.__typename === 'VitLine') {
    if (childrenCount <= 8) {
      return generateLayoutPosibilities(2, childrenCount)
    } else {
      return generateLayoutPosibilities(3, childrenCount)
    }
  } else if (dataEntry.__typename === 'Vit') {
    if (childrenCount <= 8) {
      return generateLayoutPosibilities(1, childrenCount)
    } else {
      return generateLayoutPosibilities(2, childrenCount)
    }
  } else if (dataEntry.__typename === '_VitGrouping') {
    if (childrenCount <= 8) {
      return generateLayoutPosibilities(2, childrenCount)
    } else {
      return generateLayoutPosibilities(3, childrenCount)
    }
  }

  return generateLayoutPosibilities(1, childrenCount)
}
