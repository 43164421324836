import React from 'react'

//import { Link } from 'react-router-dom'
//import { GraphContext, pathEquals } from '../GraphHelpers'
import GraphNode from './GraphNode'
import VitsAPI from '../../../api/VitsAPI'
//import VitPreviewPlayer from '../../player/VitPlayer'
//import VitPreview from '../../VitPreview'
import NodeCardView from './NodeCardView'

export default function VitNode({
  vitId,
  urlPrefix,
  graphConfig,
  getChildren,
}) {
  const vit = VitsAPI.useVitData(vitId)
  const [userId] = VitsAPI.useUserId()
  const [saveVit, unsaveVit] = VitsAPI.useVitSaveMutation(vitId)

  const renderer = React.useMemo(() => {
    if (vit) {
      const url = urlPrefix + graphConfig.path.join('/')
      let imageUrl =
        'https://img.youtube.com/vi/' + vit.source.videoId + '/0.jpg'
      if (vit && vit.thumbnail) {
        imageUrl = vit.thumbnail.thumbnailUrl
      }

      const saved = vit.saves.find((save) => {
        return save.User.userId === userId
      })

      return (
        <NodeCardView
          mediaOnly
          typename={vit.__typename}
          title={vit.title}
          subHeader={''}
          url={url}
          shareUrl={`https://vits.app/view/vit:${vit.vitId}`}
          imageUrl={imageUrl}
          elevation={3}
          vit={vit}
          saved={saved}
          onSaveToggle={() => {
            if (saved) {
              unsaveVit()
            } else {
              saveVit()
            }
          }}
        ></NodeCardView>
      )
    }
    return null
  })

  const infoRenderer = React.useMemo(() => {
    if (vit) {
      const url = urlPrefix + graphConfig.path.join('/')
      let imageUrl =
        'https://img.youtube.com/vi/' + vit.source.videoId + '/0.jpg'
      if (vit && vit.thumbnail) {
        imageUrl = vit.thumbnail.thumbnailUrl
      }

      const saved = vit.saves.find((save) => {
        return save.User.userId === userId
      })

      return (
        <NodeCardView
          infoOnly
          typename={vit.__typename}
          title={vit.title}
          subHeader={''}
          url={url}
          shareUrl={`https://vits.app/view/vit:${vit.vitId}`}
          imageUrl={imageUrl}
          elevation={3}
          vit={vit}
          saved={saved}
          onSaveToggle={() => {
            if (saved) {
              unsaveVit()
            } else {
              saveVit()
            }
          }}
        ></NodeCardView>
      )
    }
    return null
  })

  return (
    <GraphNode
      graphConfig={graphConfig}
      childData={vit ? getChildren(vit) : undefined}
      renderer={renderer}
      infoRenderer={infoRenderer}
      urlPrefix={urlPrefix}
    ></GraphNode>
  )
}

/*
function VitNodeRenderer({ vit, urlPrefix, graphConfig }) {
  const mapContext = React.useContext(GraphContext)
  const [isPlaying, setPlaying] = React.useState(false)
  const isSelected = pathEquals(mapContext.currentPath, graphConfig.path)
  let url = urlPrefix + graphConfig.path.join('/')

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'nowrap',
      }}
    >
      <div className="vitTitle">
        <Link to={url}>{vit.title}</Link>
      </div>

      <div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
          }}
        >
          <Link style={{ width: '100%', height: '100%' }} to={url}>
            <VitPreview vit={vit}></VitPreview>
          </Link>
        </div>

        {isSelected && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: isPlaying ? 1 : -1,
            }}
          >
            <VitPreviewPlayer
              vit={vit}
              onVitReady={(player) => {
                setTimeout(() => {
                  player.startPlayer()
                }, 1000)
              }}
              onVitStarted={() => {
                setPlaying(true)
              }}
              onVitDone={() => {
                setPlaying(false)
              }}
            ></VitPreviewPlayer>
          </div>
        )}
      </div>
    </div>
  )
}*/
