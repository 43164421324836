import React, { useEffect, useRef } from 'react'

import { useSprings, animated, useTransition } from '@react-spring/web'
import { useDrag } from 'react-use-gesture'
import clamp from 'lodash.clamp'
import swap from 'lodash-move'
import { useDrop } from 'react-dnd'
import AspectRatio from 'r-aspect-ratio'
import './VitSpringTimeline.css'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'

import IconButton from '@material-ui/core/IconButton'

import VitsAPI from '../../api/VitsAPI'

/*
const swap = (arr, from, to) => {
  const newArr = [...arr]
  newArr.splice(from, 1, newArr.splice(to, 1, newArr[from])[0])
  return newArr
}*/

const fn = (
  order,
  immediate = false,
  active = false,
  originalIndex = 0,
  curIndex = 0,
  x = 0
) => (index) => {
  return active && index === originalIndex
    ? {
        x: curIndex * 150 + x,
        scale: 1.1,
        zIndex: 1,
        shadow: 15,
        immediate: (key) => key === 'x' || key === 'zIndex' || immediate,
      }
    : {
        x: order.indexOf(index) * 150,
        scale: 1,
        zIndex: 0,
        shadow: 1,
        immediate: immediate,
      }
}

function DraggableList({ items, onOrderChanged, render, onAddClick }) {
  const order = useRef(items.map((_, index) => index)) // Store indicies as a local ref, this represents the item order

  console.log('draggablelist', order.current, items)

  const [springs, api] = useSprings(items.length, fn(order.current)) // Create springs, each corresponds to an item, controlling its transform, scale, etc.

  React.useEffect(() => {
    console.log('draggablelist useeffect start', order.current, items)
    //itemsRef.current = items.map((_, index) =>
    //  index < order.current.length ? items[order.current[index]] : items[index]
    //)
    order.current = items.map((_, index) => index)
    //order.current = items.map((_, index) =>
    //  index < order.current.length ? order.current[index] : index
    //)
    api.start(fn(order.current, true))

    console.log('draggablelist useeffect end', order.current, items)
  }, [items])

  const bind = useDrag(
    ({ args: [originalIndex], active, movement: [x, y] }) => {
      const curIndex = order.current.indexOf(originalIndex)
      const curRow = clamp(
        Math.round((curIndex * 150 + x) / 150),
        0,
        items.length - 1
      )
      const newOrder = swap(order.current, curIndex, curRow)
      api.start(fn(newOrder, false, active, originalIndex, curIndex, x)) // Feed springs new style data, they'll animate the view without causing a single render
      if (!active && curIndex !== curRow) {
        if (onOrderChanged) {
          onOrderChanged(newOrder)
        }
      }
      if (!active) order.current = newOrder
    },
    { filterTaps: true }
  )
  return (
    <div className={'timeline_content'} style={{ width: items.length * 150 }}>
      {springs.map(({ zIndex, shadow, x, scale }, i) => (
        <animated.div
          {...bind(i)}
          key={i}
          style={{
            zIndex,
            boxShadow: shadow.to(
              (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
            ),
            x,
            scale,
          }}
        >
          {render(items[i])}
        </animated.div>
      ))}
      <div
        className="node-thumbnail-img-clipped"
        style={{
          width: 143,
          height: 80,
          left: items.length * 150,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '400%',
          backgroundColor: '#DDDDDD',
          cursor: 'pointer',
        }}
        onClick={onAddClick}
      >
        <AddIcon fontSize="large"></AddIcon>
      </div>
    </div>
  )
}

export function VitSpringTimeline({ vitline, onChange, onVitClick, disabled }) {
  const vits = VitsAPI.vitsFromVitLine(vitline)

  console.log(vits)
  const [{ isDropping, canDrop }, drop] = useDrop(
    () => ({
      accept: 'vit',
      collect: (monitor) => ({
        isDropping: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
      canDrop: () => true,
      drop(item, monitor) {
        /* const delta = monitor.getDifferenceFromInitialOffset()

        let left = Math.round(item.left + delta.x)
        let top = Math.round(item.top + delta.y)
        if (snapToGrid) {
          //;[left, top] = doSnapToGrid(left, top)
        }

        moveBox(item.id, left, top)
        return undefined */
        // onDrop(item.data)
        //const newVits = [...vits, item.data.vit]
        //onChange(newVits)
        const addedVits = item.data
        const newVits = vits ? [...vits, ...addedVits] : [addedVits]
        onChange(newVits.filter((vit) => !!vit))
      },
    }),
    [vits, onChange]
  )

  return (
    <div
      ref={drop}
      style={{
        minWidth: 'calc(100vw)',
        padding: '8px 16px 8px 16px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        opacity: disabled ? 0.5 : 1.0,
        pointerEvents: disabled ? 'none' : undefined,
        backgroundColor: isDropping ? 'var(--unnamed-color-e7f0eb)' : undefined,
      }}
    >
      <DraggableList
        items={vits}
        onAddClick={() => {
          onVitClick(undefined, vits.length)
        }}
        onOrderChanged={(order) => {
          const newVits = [...vits]
          order.forEach((newIndex, oldIndex) => {
            newVits[oldIndex] = vits[newIndex]
          })
          onChange(newVits.filter((vit) => !!vit))
        }}
        render={(vit) => {
          const index = vits.indexOf(vit)
          return (
            <VitPlacement
              disabled={disabled}
              key={vit ? vit.vitId : ':' + index}
              index={index}
              vit={vit}
              onClick={(e) => {
                onVitClick(vit, index)
                //e.stopPropagation()

                //history.push('/builder')
              }}
              onDrop={(data) => {
                const newVits = [...vits]
                newVits[index] = data.vit
                onChange(newVits.filter((vit) => !!vit))
              }}
              onClear={() => {
                const newVits = [...vits]
                newVits[index] = null
                onChange(newVits.filter((vit) => !!vit))
              }}
            ></VitPlacement>
          )
        }}
      />
      {/* vits.map((e, index) => {
        const vit = vits && index < vits.length ? vits[index] : null
        return (
          <VitPlacement
            disabled={disabled}
            key={':' + index}
            index={index}
            vit={vit}
            onClick={() => {
              onVitClick(vit, index)

              //history.push('/builder')
            }}
            onDrop={(data) => {
              const newVits = [...vits]
              newVits[index] = data.vit
              if (data.fromIndex >= 0 && data.fromIndex !== index) {
                newVits[data.fromIndex] = null
              }
              onChange(newVits)
            }}
            onClear={() => {
              const newVits = [...vits]
              newVits[index] = null
              onChange(newVits)
            }}
          ></VitPlacement>
        )
      }) */}
    </div>
  )
}

function ThumbnailView({ image, showClip }) {
  return (
    <div
      className={
        showClip ? 'node-thumbnail-img-clipped' : 'node-thumbnail-img-noclip'
      }
      style={{
        backgroundColor: '#DDDDDD',
        //width: 'calc((3/18) * 100vw)',
        //height: 'calc((9/16) * (3/18) * 100vw)',
        width: '143px',
        height: '80px',
      }}
    >
      <AspectRatio ratio={9 / 16} width="100%" className="node-thumbnail">
        <img
          className={'node-thumbnail-img '}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          src={image}
        />
      </AspectRatio>
    </div>
  )
}

function convertThumbnailUrl(thumbnail) {
  if (!thumbnail || !thumbnail.thumbnailUrl) {
    return null
  }
  return thumbnail.thumbnailUrl
}

function VitPlacement({ vit, index, onClick, onDrop, onClear, disabled }) {
  const [isHover, setHover] = React.useState(false)

  let imageUrl
  if (vit && (vit.thumbnail || vit.source)) {
    imageUrl =
      convertThumbnailUrl(vit.thumbnail) ??
      'https://img.youtube.com/vi/' + vit.source.videoId + '/0.jpg'
  }

  const [{ isDropping, canDrop }, drop] = useDrop(
    () => ({
      accept: 'vit',
      collect: (monitor) => ({
        isDropping: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
      canDrop: () => true,
      drop(item, monitor) {
        /* const delta = monitor.getDifferenceFromInitialOffset()

        let left = Math.round(item.left + delta.x)
        let top = Math.round(item.top + delta.y)
        if (snapToGrid) {
          //;[left, top] = doSnapToGrid(left, top)
        }

        moveBox(item.id, left, top)
        return undefined */
        onDrop(item.data)
      },
    }),
    [onDrop]
  )

  return (
    <div
      key={'placement:' + index}
      style={{
        width: '143px',
        height: '80px',
      }}
      /*
      onDragOver={(e) => {
        if (disabled) return

        e.preventDefault()
        e.dataTransfer.dropEffect = 'copy'
        setDropping(true)
      }}
      onDragLeave={() => {
        if (disabled) return

        setDropping(false)
      }}
      onMouseOver={() => {}}
      onMouseLeave={() => {
        if (disabled) return

        setDropping(false)
      }}
      onDrop={(e) => {
        if (disabled) return

        e.preventDefault()

        const data = JSON.parse(e.dataTransfer.getData('text/plain'))
        console.log('Dropped: ' + data)
        if (onDrop) {
          onDrop(data)
        }
      }}
      */
      onClick={(e) => {
        if (disabled) return

        if (onClick) {
          onClick(e)
        }
      }}
    >
      {vit ? (
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            opacity: '1.0',
          }}
          onMouseEnter={() => {
            if (disabled) return

            setHover(true)
          }}
          onMouseLeave={() => {
            if (disabled) return

            setHover(false)
          }}
        >
          <img
            style={{
              position: 'absolute',
              right: '0',
              top: '0',
              width: '16px',
              height: '16px',
              opacity: isHover ? '1.0' : '0.0',
              transition: 'opacity 0.25s ease-in-out',
              cursor: 'pointer',
              zIndex: 10,
            }}
            onClick={(e) => {
              if (disabled) return

              e.preventDefault()
              e.stopPropagation()
              onClear()
            }}
            src="/img/clear_box.png"
          ></img>

          {/*<div
            style={{
              position: 'absolute',
              left: '16',
              top: '16',
              width: '32px',
              height: '32px',
              backgroundColor: 'var(--unnamed-color-137d3f)',
              opacity: isHover ? '1.0' : '0.0',
              transition: 'opacity 0.25s ease-in-out',
              cursor: 'pointer',
              zIndex: 10,
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyItems: 'cener',
            }}
          >
            <IconButton
              variant="contained"
              size="small"
              color="inherit"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onClick()
              }}
            >
              <EditIcon color="inherit" />
            </IconButton>
          </div> */}

          <ThumbnailView image={imageUrl} showClip={true} />
        </div>
      ) : (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            backgroundImage: isDropping
              ? `url(/img/add_vit_box_dropping.png)`
              : `url(/img/add_vit_box.png)`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            margin: '0px 0px 0px 0px',
          }}
        ></div>
      )}
    </div>
  )
}
