import React from 'react'

import {
  GraphLayout,
  GraphFrame,
  GraphLayoutEnum,
  GraphSize,
  GraphZoomConfig,
} from './types'

export function frameIsSubFrame(
  containingFrame: GraphFrame,
  subFrame: GraphFrame
): boolean {
  return (
    subFrame.row >= containingFrame.row &&
    subFrame.col >= containingFrame.col &&
    subFrame.row + subFrame.numRows <=
      containingFrame.row + containingFrame.numRows &&
    subFrame.col + subFrame.numCols <=
      containingFrame.col + containingFrame.numCols
  )
}

export function pathEquals(path1: string[], path2: string[]): boolean {
  if (path1 === path2) {
    return true
  }
  if (!path1 || !path2) {
    return false
  }

  if (path1.length !== path2.length) {
    return false
  }

  for (let i = 0; i < path1.length; i++) {
    if (path1[i].localeCompare(path2[i]) !== 0) {
      return false
    }
  }

  return true
}

export function pathBeginsWith(
  fullPath: string[],
  beginning: string[]
): boolean {
  if (beginning.length > fullPath.length) {
    return false
  }

  return pathEquals(beginning, fullPath.slice(0, beginning.length))
}

export function splinksiLayout(size: number, unitSize: number): GraphLayout {
  if (size <= 2) {
    throw new Error('Invalid size')
  }

  const totalSize = size * unitSize

  const layout = Array<Array<GraphLayoutEnum>>()

  for (let row = 0; row < totalSize; row++) {
    const rowData = Array<GraphLayoutEnum>()

    for (let col = 0; col < totalSize; col++) {
      if (
        row < unitSize ||
        row >= totalSize - unitSize ||
        col < unitSize ||
        col >= totalSize - unitSize
      ) {
        if (
          row >= totalSize - unitSize &&
          col >= unitSize &&
          col < totalSize - unitSize
        ) {
          rowData.push(GraphLayoutEnum.INFO)
        } else {
          rowData.push(GraphLayoutEnum.CHILD)
        }
      } else {
        rowData.push(GraphLayoutEnum.NODE)
      }
    }

    layout.push(rowData)
  }
  return {
    map: layout,
    childSize: { numRows: unitSize, numCols: unitSize },
  }
}

export function propsForNode(layout: GraphLayout): React.CSSProperties {
  const frame = searchLayout(layout, GraphLayoutEnum.NODE)
  const totalRows = layout.map.length
  const totalCols = layout.map[0].length

  return {
    top: (frame.row / totalRows) * 100 + '%',
    left: (frame.col / totalCols) * 100 + '%',
    height: (frame.numRows / totalRows) * 100 + '%',
    width: (frame.numRows / totalRows) * 100 + '%',
    position: 'absolute',
  }
}

export function propsForInfo(layout: GraphLayout): React.CSSProperties {
  const frame = searchLayout(layout, GraphLayoutEnum.INFO)
  const totalRows = layout.map.length
  const totalCols = layout.map[0].length

  return {
    top: (frame.row / totalRows) * 100 + '%',
    left: (frame.col / totalCols) * 100 + '%',
    height: (frame.numRows / totalRows) * 100 + '%',
    width: (frame.numCols / totalRows) * 100 + '%',
    position: 'absolute',
  }
}

export function nodeGraphFrame(
  rootGraphFrame: GraphFrame,
  layout: GraphLayout
) {
  return transposeFrameToFrame(
    rootGraphFrame,
    layout,
    searchLayout(layout, GraphLayoutEnum.NODE)
  )
}

export function infoGraphFrame(
  rootGraphFrame: GraphFrame,
  layout: GraphLayout
) {
  return transposeFrameToFrame(
    rootGraphFrame,
    layout,
    searchLayout(layout, GraphLayoutEnum.INFO)
  )
}

export function scaledGraphFrame(
  graphFrame: GraphFrame,
  scale: number
): GraphFrame {
  const extraRows = graphFrame.numRows * scale - graphFrame.numRows
  const extraCols = graphFrame.numCols * scale - graphFrame.numCols

  return {
    row: graphFrame.row - extraRows / 2.0,
    col: graphFrame.col - extraCols / 2.0,
    numRows: graphFrame.numRows + extraRows,
    numCols: graphFrame.numCols + extraCols,
  }
}

/*
export function transformForFrameAndZoom(
  zoomFrame: GraphFrame,
  graphFrames: GraphFrame[],
  graphFrameIndex: number,
  layout: GraphLayout,
  graphSize: GraphSize,
  graphZoomConfig: GraphZoomConfig
) {
  const nodeRow = zoomFrame ? zoomFrame.row : 0;
  const nodeCol = zoomFrame ? zoomFrame.col : 0;
  const nodeCols = zoomFrame ? zoomFrame.numCols : columns;
  const nodeRows = zoomFrame ? zoomFrame.numRows : map.length;
  const nodeAspectRatio = nodeCols / nodeRows;

  const center = zoomFrame ? true : false;

  let zoomRow: number,
    zoomCol: number,
    zoomScale: number,
    visibleRows: number,
    visibleCols: number;

  if (!zoomFrame || visibleAspectRatio <= nodeAspectRatio) {
    visibleRows = zoomFrame ? nodeCols / visibleAspectRatio : nodeRows;
    visibleCols = nodeCols;
    visibleCols +=
      visibleCols *
      (horizontalMarginPercentage / (1 - horizontalMarginPercentage));

    // node is width constrained
    zoomRow = nodeRow; // - margin top
    if (center) {
      zoomRow -= (visibleRows - nodeRows) / 2;
    }
    zoomCol = nodeCol;
    zoomScale = columns / visibleCols;
  } else {
    visibleRows = nodeRows;
    visibleCols = zoomFrame ? nodeRows * visibleAspectRatio : nodeCols;
    visibleRows +=
      visibleRows * (verticalMarginPercentage / (1 - verticalMarginPercentage));

    zoomCol = nodeCol;
    if (center) {
      zoomCol -= (visibleCols - nodeCols) / 2;
    }
    zoomRow = nodeRow;
    zoomScale = rows / visibleRows;
  }
}*/

export function transformForFrame(
  debug: boolean,
  graphFrames: GraphFrame[],
  graphFrameIndex: number,
  layout: GraphLayout,
  graphSize: GraphSize,
  graphZoomConfig: GraphZoomConfig,
  windowWidth: number,
  windowHeight: number,
  aspectRatio: number,
  columns: number,
  currentRowOffset: number
): string {
  const [
    marginLeft,
    marginTop,
    marginRight,
    marginBottom,

    zoomRow,
    zoomCol,
    visibleRows,
    visibleCols,
  ] = graphZoomConfig

  /*
  const zoomFrame = {
    row: zoomFrameRow,
    col: zoomFrameCol,
    numRows: zoomFrameNumRows,
    numCols: zoomFrameNumCols,
  };
*/

  const currentMargins = {
    left: marginLeft,
    right: marginRight,
    top: marginTop,
    bottom: marginBottom,
  }

  //const sizeScale = layout.childSize.numCols / layout.map[0].length;

  /*  const defaultMargins1Level = { left: 50, right: 50, top: 50, bottom: 50 };
  const defaultMargins2Levels = {
    left: 150,
    right: 150,
    top: 150,
    bottom: 150,
  };

  const extraDefaultMargins = state.currentZoomContext
    ? state.currentZoomContext.path.length === 1
      ? defaultMargins1Level
      : defaultMargins2Levels
    : defaultMarginsRoot;*/

  const windowAspectRatio = windowWidth / (windowHeight * aspectRatio)
  const rows = columns / windowAspectRatio

  /*
  const nodeRow = zoomFrame.row;
  const nodeCol = zoomFrame.col;
  const nodeCols = zoomFrame.numCols;
  const nodeRows = zoomFrame.numRows;
  const nodeAspectRatio = nodeCols / nodeRows;

  const center = !isRootZoomFrame ? true : false;

  let zoomRow: number,
    zoomCol: number,
    zoomScale: number,
    visibleRows: number,
    visibleCols: number;

  if (visibleAspectRatio <= nodeAspectRatio) {
    visibleRows = nodeCols / visibleAspectRatio;
    visibleCols = nodeCols;
    visibleCols +=
      visibleCols *
      (horizontalMarginPercentage / (1 - horizontalMarginPercentage));

    // node is width constrained
    zoomRow = nodeRow; // - margin top
    if (center) {
      zoomRow -= (visibleRows - nodeRows) / 2;
    }
    zoomCol = nodeCol;
    zoomScale = columns / visibleCols;
  } else {
    visibleRows = nodeRows;
    visibleCols = nodeRows * visibleAspectRatio;
    visibleRows +=
      visibleRows * (verticalMarginPercentage / (1 - verticalMarginPercentage));

    zoomCol = nodeCol;
    if (center) {
      zoomCol -= (visibleCols - nodeCols) / 2;
    }
    zoomRow = nodeRow;
    zoomScale = rows / visibleRows;
  }*/

  const colSize = windowWidth / columns
  const rowSize = colSize / aspectRatio

  const marginLeftRowOffset = currentMargins ? currentMargins.left / colSize : 0
  const marginTopRowOffset = currentMargins ? currentMargins.top / rowSize : 0

  /*
  const [
    zoomScale,
    zoomRow,
    zoomCol,
    currentRowOffset,
    marginLeftRowOffset,
    marginTopRowOffset,
  ] = graphZoomConfig;
*/

  const zoomScale = Math.min(rows / visibleRows, columns / visibleCols)

  if (debug) {
    //console.log(zoomScale, zoomRow, zoomCol, currentRowOffset);
  }

  let extraScale = 1
  if (layout.childSize.numRows === 1) {
    extraScale = 2
  }

  const graphFrame = interpolateChildFrames(graphFrames, graphFrameIndex)

  const baseScale = graphFrame.numCols / layout.map[0].length
  const scale = baseScale * zoomScale // * sizeScale;

  const totalRows = layout.map.length
  const totalCols = layout.map[0].length

  const translateX =
    ((graphFrame.col - zoomCol + marginLeftRowOffset / zoomScale) / totalCols) *
    zoomScale *
    100

  const translateY =
    ((graphFrame.row -
      zoomRow -
      currentRowOffset /* / zoomScale */ +
      marginTopRowOffset / zoomScale) /
      totalRows) *
    zoomScale *
    100

  return `translate(${translateX / extraScale}%, ${
    translateY / extraScale
  }%) scale(${scale / extraScale}, ${scale / extraScale})`
}

export function interpolatedChildFrame(
  startFrame: GraphFrame,
  endFrame: GraphFrame,
  val: number
): GraphFrame {
  if (val === 0) {
    return startFrame
  }

  if (val === 1) {
    return endFrame
  }

  return {
    row: (endFrame.row - startFrame.row) * val + startFrame.row,
    col: (endFrame.col - startFrame.col) * val + startFrame.col,
    numCols: startFrame.numCols,
    numRows: startFrame.numRows,
  }
}

export function interpolateChildFrames(
  childFrames: GraphFrame[],
  childFrameIndex: number
): GraphFrame {
  const maxChildren = childFrames.length
  let childFrame

  if (childFrameIndex < 0) {
    childFrame = interpolatedChildFrame(
      childFrames[0],
      childFrames[1],
      childFrameIndex
    )
  } else if (childFrameIndex < maxChildren - 1) {
    const beforeIndex = Math.floor(childFrameIndex)
    const afterIndex = beforeIndex + 1
    childFrame = interpolatedChildFrame(
      childFrames[beforeIndex],
      childFrames[afterIndex],
      childFrameIndex - beforeIndex
    )
  } else {
    childFrame = interpolatedChildFrame(
      childFrames[maxChildren - 2],
      childFrames[maxChildren - 1],
      childFrameIndex - maxChildren + 2
    )
  }

  return childFrame
}

export function childFrames(
  rootFrame: GraphFrame,
  layout: GraphLayout,
  order?: 'topdown' | 'circular' | 'lefttoright',
  numChildren?: number
) {
  const childPoints = Array<[number, number]>()
  const cornerLessChildPoints = Array<[number, number]>()

  const totalRows = layout.map.length
  const totalCols = layout.map[0].length

  const childRows = layout.childSize.numRows
  const childCols = layout.childSize.numCols

  if (order === 'lefttoright') {
    const startRow = totalRows - childRows
    const startCol = 0

    let currentRow = startRow
    let currentCol = startCol

    let direction: 'right' | 'down' | 'left' | 'up' = 'up'

    do {
      if (layout.map[currentRow][currentCol] === GraphLayoutEnum.CHILD) {
        childPoints.push([currentRow, currentCol])
        cornerLessChildPoints.push([currentRow, currentCol])
      }

      switch (direction) {
        case 'right': {
          if (currentCol + childCols < totalCols) {
            currentCol += childCols
          } else {
            currentRow += childCols
            direction = 'down'
          }
          break
        }
        case 'down': {
          if (currentRow + childRows < totalRows) {
            currentRow += childCols
          } else {
            currentCol -= childRows
            direction = 'left'
          }
          break
        }

        case 'left': {
          if (currentCol > 0) {
            currentCol -= childRows
          } else {
            currentRow -= childRows
            direction = 'up'
          }
          break
        }

        case 'up': {
          if (currentRow > 0) {
            currentRow -= childRows
          } else {
            currentCol += childCols
            direction = 'right'
          }
          break
        }
      }
    } while (!(currentRow === startRow && currentCol === startCol))
  }

  /*
  if (order === "circular") {
    const startRow = 0;
    const startCol = Math.floor(layout[0].length / 2);

    let currentRow = startRow;
    let currentCol = startCol;

    let direction: "right" | "down" | "left" | "up" = "right";

    do {
      if (layout[currentRow][currentCol] === GraphLayoutEnum.CHILD) {
        childPoints.push([currentRow, currentCol]);
        cornerLessChildPoints.push([currentRow, currentCol]);
      }

      switch (direction) {
        case "right": {
          if (currentCol + 1 < totalCols) {
            currentCol++;
          } else {
            currentRow++;
            direction = "down";
          }
          break;
        }
        case "down": {
          if (currentRow + 1 < totalRows) {
            currentRow++;
          } else {
            currentCol--;
            direction = "left";
          }
          break;
        }

        case "left": {
          if (currentCol > 0) {
            currentCol--;
          } else {
            currentRow--;
            direction = "up";
          }
          break;
        }

        case "up": {
          if (currentRow > 0) {
            currentRow--;
          } else {
            currentCol++;
            direction = "right";
          }
          break;
        }
      }
    } while (!(currentRow === startRow && currentCol === startCol));
  }

  if (order === "topdown") {
    for (let row = 0; row < layout.length; row++) {
      for (let col = 0; col < layout[row].length; col++) {
        if (layout[row][col] === GraphLayoutEnum.CHILD) {
          if (
            (row === 0 && (col === 0 || col === layout[0].length - 1)) ||
            (row === layout.length - 1 &&
              (col === 0 || col === layout[0].length - 1))
          ) {
            childPoints.push([row, col]);
          } else {
            childPoints.push([row, col]);
            cornerLessChildPoints.push([row, col]);
          }
        }
      }
    }
  }*/

  const mapFn = (data: [number, number]) => {
    const [row, col] = data
    return transposeFrameToFrame(rootFrame, layout, {
      row: row,
      col: col,
      numRows: childRows,
      numCols: childCols,
    })
  }

  if (
    numChildren !== undefined &&
    numChildren <= cornerLessChildPoints.length
  ) {
    return cornerLessChildPoints.map(mapFn)
  }

  return childPoints.map(mapFn)
}

function transposeFrameToFrame(
  rootGraphFrame: GraphFrame,
  rootLayout: GraphLayout,
  subFrame: GraphFrame
) {
  const layoutRows = rootLayout.map.length
  const layoutCols = rootLayout.map[0].length

  const row =
    rootGraphFrame.row + (subFrame.row / layoutRows) * rootGraphFrame.numRows
  const col =
    rootGraphFrame.col + (subFrame.col / layoutCols) * rootGraphFrame.numCols
  const numRows = (subFrame.numRows / layoutRows) * rootGraphFrame.numRows
  const numCols = (subFrame.numCols / layoutRows) * rootGraphFrame.numCols

  return {
    row,
    col,
    numRows,
    numCols,
  }
}

function searchLayout(
  layout: GraphLayout,
  search: GraphLayoutEnum
): GraphFrame {
  let startRow: number | undefined
  let startCol: number | undefined
  let endRow: number | undefined
  let endCol: number | undefined

  for (let row = 0; row < layout.map.length; row++) {
    for (let col = 0; col < layout.map[row].length; col++) {
      if (layout.map[row][col] === search) {
        if (!startRow) {
          startRow = row
          startCol = col
        }
        endRow = row
        endCol = col
      }
    }
  }

  if (
    startRow === undefined ||
    startCol === undefined ||
    endRow === undefined ||
    endCol === undefined
  ) {
    throw new Error('Cannot find enum')
  }

  return {
    row: startRow,
    col: startCol,
    numRows: endRow - startRow + 1,
    numCols: endCol - startCol + 1,
  }
}
