import React from 'react'
import LoginButton from './LoginButton'
import { useAuth0 } from '@auth0/auth0-react'
import Collect from './Collect'
import {
  useSpring,
  useTransition,
  animated,
  Spring,
  SpringValue,
  to,
} from 'react-spring'

export default function SideBar({ expanded }) {
  const { user, isAuthenticated } = useAuth0()

  const arrowProps = useSpring({
    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  })

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <div
        style={{
          width: '50px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
          border: '1px #137D3F solid',
          borderBottom: '0px',
          backgroundColor: '#E7F0EB',
        }}
      >
        <animated.img
          style={{
            width: 20,
            height: 20,
            marginTop: 16,
            transform: arrowProps.transform,
          }}
          src={'/img/arrow_left.png'}
        ></animated.img>
        <span style={{ display: 'flex', flexGrow: 1 }}></span>
        <div
          style={{
            fontFamily: 'var(--unnamed-font-family-space-mono)',
            fontWeight: 'bold',
            fontSize: '200%',
            transform: 'rotate(90deg)',
          }}
        >
          COLLECT
        </div>
        <span style={{ display: 'flex', flexGrow: 1 }}></span>
        <img
          style={{
            width: 20,
            height: 20,
            marginBottom: 16,
            transform: arrowProps.transform,
          }}
          src={'/img/arrow_left.png'}
        ></img>
      </div>
      <div
        style={{
          display: 'flex',
          height: '100%',
          flexGrow: 1,
          backgroundColor: 'white',
          flexDirection: 'column',
          padding: '16px',
        }}
      >
        <div>
          <LoginButton></LoginButton>
        </div>
        {isAuthenticated && (
          <>
            <br></br>
            <Collect userId={user['https://vits.app/username']}></Collect>
          </>
        )}
      </div>
    </div>
  )
}
