import React from 'react'

import {
  GraphFrame,
  GraphLayout,
  FractalGraphContext,
  FractalGraphZoomState,
  GraphZoomContext,
  GraphSize,
  GraphZoomConfig,
} from './types'
import {
  propsForNode,
  propsForInfo,
  childFrames,
  pathEquals,
  pathBeginsWith,
  interpolateChildFrames,
} from './utils'
import { SpringValue, useSpring } from 'react-spring'

export function useWindowSize(): { windowWidth: number; windowHeight: number } {
  const [size, setSize] = React.useState([
    window.innerWidth,
    window.innerHeight,
  ])
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return { windowWidth: size[0], windowHeight: size[1] }
}

export function usePrevious<T>(value: T): T | undefined {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = React.useRef<T>()

  // Store current value in ref
  React.useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

export function useGraphNode(
  path: string[],
  graphFrames: GraphFrame | GraphFrame[],
  graphFrameIndex: number,
  graphLayout: GraphLayout,
  graphSize: GraphSize
): [
  containerProps: React.CSSProperties,
  springProps: {
    opacity: SpringValue<string>
    graphFrameIndex: SpringValue<number>
  },
  nodeProps: React.CSSProperties,
  infoProps: React.CSSProperties,
  childFrames: GraphFrame[],
  isSelected: boolean,
  isSomeChildSelected: boolean,
  isParentSelected: boolean,
  isChildSelected: boolean,
  isSomeParentSelected: boolean,
  levelDelta: number,
  isAnimatingScroll: boolean,
  zoomContext: GraphZoomContext | undefined
] {
  const isAnimatingRef = React.useRef(false)
  const { dimensionsForLayout, zoomContext, isScrolling } = React.useContext(
    FractalGraphContext
  )

  const [isAnimatingZoom, setAnimatingZoom] = React.useState(false)

  const nodeProps = React.useMemo(() => propsForNode(graphLayout), [
    graphLayout,
  ])
  const infoProps = React.useMemo(() => propsForInfo(graphLayout), [
    graphLayout,
  ])

  const { width, height } = dimensionsForLayout!(graphLayout)

  const zoomPath = zoomContext ? zoomContext.path : []
  //const nextZoomPath = nextZoomContext ? nextZoomContext.path : [];

  const isSelected = pathEquals(zoomPath, path)
  //const isSelectedNext = pathEquals(nextZoomPath, path);

  const levelDelta = path.length - zoomPath.length

  const isSomeChildSelected = !isSelected
    ? pathBeginsWith(zoomPath, path)
    : false
  //const isSomeChildSelectedNext = !isSelectedNext
  //  ? pathBeginsWith(nextZoomPath, path)
  //  : false;

  const isParentSelected = pathEquals(zoomPath, path.slice(0, -1))
  const isChildSelected = pathEquals(zoomPath.slice(0, -1), path)
  const isSomeParentSelected = pathBeginsWith(path, zoomPath)

  const [springProps, api] = useSpring(() => {
    return {
      opacity: levelDelta < 0 ? '0.5' : '1.0',
      graphFrameIndex: graphFrameIndex,
    }
  })

  React.useEffect(() => {
    if (isAnimatingZoom) {
      const timeout = setTimeout(() => {
        setAnimatingZoom(false)
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isAnimatingZoom])

  React.useEffect(() => {
    api.start({
      graphFrameIndex: graphFrameIndex,
      opacity: levelDelta < 0 ? '0.5' : '1.0',
      onStart: () => {
        if (path.length === 1 && path[0] === '6') {
          //console.log("test");
        }
        isAnimatingRef.current = true
        setAnimatingZoom(true)
      },
      onRest: () => {
        if (path.length === 1 && path[0] === '6') {
          //console.log("test");
        }

        isAnimatingRef.current = false
        setAnimatingZoom(false)
      },
    })

    return () => {
      setAnimatingZoom(false)
    }
  })

  const graphFrame = React.useMemo(() => {
    return Array.isArray(graphFrames)
      ? interpolateChildFrames(graphFrames, graphFrameIndex)
      : graphFrames
  }, [graphFrames, graphFrameIndex])

  const frames = React.useMemo(() => {
    return childFrames(graphFrame, graphLayout, 'lefttoright')
  }, [graphFrame, graphLayout])

  return [
    {
      position: 'absolute',
      left: '0px',
      top: '0px',
      width: Math.round(width),
      height: Math.round(height),
      transformOrigin: '0 0',
    },
    springProps,
    nodeProps,
    infoProps,
    frames,
    isSelected,
    isSomeChildSelected,
    isParentSelected,
    isChildSelected,
    isSomeParentSelected,
    levelDelta,
    isAnimatingZoom || (isScrolling ?? false),
    zoomContext,
  ]
}

function print(value: any) {
  if (typeof value === 'object') {
    return JSON.stringify(value)
  } else {
    return value.toString()
  }
}

export function useLogIfChanged<T>(name: string, value: T): void {
  const previous = React.useRef(value)
  if (!Object.is(previous.current, value)) {
    console.log(
      `${name} changed. Old: ${
        previous.current ? print(previous.current) : 'undefined'
      }, New: ${value ? print(value) : 'undefined'} `
    )
    previous.current = value
  }
}
