import React from 'react'

//import { Link } from 'react-router-dom'
//import { GraphContext, pathEquals } from '../GraphHelpers'
//import VitlinePlayer from '../../player/VitLinePlayer'
import GraphNode from './GraphNode'
import NodeCardView from './NodeCardView'
//import VitThumbnail from '../../VitThumbnail'

//const useVideo = true

export default function VitGroupNode({
  data,
  urlPrefix,
  graphConfig,
  getChildren,
}) {
  const renderer = React.useMemo(() => {
    if (data) {
      let image = '/loader.gif'
      if (data.vits && data.vits.length > 0) {
        const firstVit = data.vits[0]
        if (firstVit && firstVit.thumbnail) {
          image = firstVit.thumbnail.thumbnailUrl
        } else if (firstVit && firstVit.source) {
          image =
            'https://img.youtube.com/vi/' + firstVit.source.videoId + '/0.jpg'
        }
      }
      const url = urlPrefix + graphConfig.path.join('/')
      return (
        <NodeCardView
          typename={data.__typename}
          color={'secondary'}
          title={'YouTube: Some title goes here'}
          subHeader={'Auto-generated YouTube Line'}
          imageUrl={image}
          url={url}
          elevation={3}
          vits={data.vits}
          mediaOnly
        ></NodeCardView>
      )
    }
    return null
  })

  const infoRenderer = React.useMemo(() => {
    if (data) {
      let image = '/loader.gif'
      if (data.vits && data.vits.length > 0) {
        const firstVit = data.vits[0]
        if (firstVit && firstVit.thumbnail) {
          image = firstVit.thumbnail.thumbnailUrl
        } else if (firstVit && firstVit.source) {
          image =
            'https://img.youtube.com/vi/' + firstVit.source.videoId + '/0.jpg'
        }
      }
      const url = urlPrefix + graphConfig.path.join('/')
      return (
        <NodeCardView
          typename={data.__typename}
          color={'secondary'}
          title={'YouTube: Some title goes here'}
          subHeader={'Auto-generated YouTube Line'}
          imageUrl={image}
          url={url}
          elevation={3}
          vits={data.vits}
          infoOnly
        ></NodeCardView>
      )
    }
    return null
  })

  return (
    <GraphNode
      graphConfig={graphConfig}
      childData={data ? getChildren(data) : undefined}
      renderer={renderer}
      infoRenderer={infoRenderer}
      urlPrefix={urlPrefix}
    ></GraphNode>
  )
}

/*
function VitGroupNodeRenderer({ data, urlPrefix, graphConfig }) {
  const mapContext = React.useContext(GraphContext)
  const [isPlaying, setPlaying] = React.useState(false)
  const isSelected = pathEquals(mapContext.currentPath, graphConfig.path)

  let url = urlPrefix + graphConfig.path.join('/')
  let image = '/loader.gif'
  if (data.vits && data.vits.length > 0) {
    const firstVit = data.vits[0]
    if (firstVit && firstVit.thumbnail) {
      image = firstVit.thumbnail.thumbnailUrl
    } else if (firstVit && firstVit.source) {
      image = 'https://img.youtube.com/vi/' + firstVit.source.videoId + '/0.jpg'
    }
  }

  let vitlineTitle = 'YouTube Group'

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
      }}
    >
      <div className="vitTitle">
        <Link to={url}>{vitlineTitle}</Link>
      </div>

      <Link style={{ width: '100%', height: '100%' }} to={url}>
        <div
          className="vitThumbnail"
          style={{
            backgroundImage: `url(${image})`,
          }}
        ></div>
        {useVideo && isSelected && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: isPlaying ? 1 : -1,
            }}
          >
            <VitlinePlayer
              title={vitlineTitle}
              sequence={data.vits}
              onLoad={(player) => {
                setTimeout(() => {
                  setPlaying(true)
                  player.play()
                }, 1000)
              }}
              onVitlineStarted={() => {
                setPlaying(true)
              }}
            ></VitlinePlayer>
          </div>
        )}
      </Link>
    </div>
  )
}*/
