import React from 'react'

import VitlineBuilder from './views/builder/VitlineBuilder'

import {
  useSpring,
  useTransition,
  animated,
  Spring,
  SpringValue,
  to,
} from 'react-spring'

export default function BottomBar({ expanded }) {
  const arrowProps = useSpring({
    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  })

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <div
        style={{
          minHeight: '50px',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          border: '1px #137D3F solid',
          backgroundColor: '#E7F0EB',
        }}
      >
        <animated.img
          style={{
            width: 20,
            height: 20,
            marginLeft: 16,
            transform: arrowProps.transform,
          }}
          src={'/img/arrow_up.png'}
        ></animated.img>
        <span style={{ display: 'flex', flexGrow: 1 }}></span>
        <div
          style={{
            fontFamily: 'var(--unnamed-font-family-space-mono)',
            fontWeight: 'bold',
            fontSize: '200%',
          }}
        >
          VITLINE
        </div>
        <span style={{ display: 'flex', flexGrow: 1 }}></span>
        <animated.img
          style={{
            width: 20,
            height: 20,
            marginRight: 16,
            transform: arrowProps.transform,
          }}
          src={'/img/arrow_up.png'}
        ></animated.img>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          maxHeight: '200px',
          flexGrow: 1,
          backgroundColor: 'white',
        }}
      >
        <VitlineBuilder></VitlineBuilder>
      </div>
    </div>
  )
}
