import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useHistory } from 'react-router'

const LoginButton = () => {
  const { user, isAuthenticated, loginWithPopup, logout } = useAuth0()

  if (isAuthenticated) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          alignContent: 'center',
        }}
      >
        <div>
          {user && (
            <div className="heading-2-character-style">
              <Link to={'/user/' + user['https://vits.app/username']}>
                {user['https://vits.app/username']}
              </Link>
            </div>
          )}

          <div className="subtext-character-style">
            <a
              href="#"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Sign Out
            </a>
          </div>
        </div>
        <CreateButton></CreateButton>
      </div>
    )
  }

  function CreateButton() {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const history = useHistory()

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const handleCreateVitLine = () => {
      history.push('/edit/vitline:draft')
    }

    const handleCreateVit = () => {
      history.push('/add')
    }

    return (
      <div
        className={'heading-character-style'}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Tooltip title="CREATE NEW" aria-label="add">
          <Fab color="primary" className={'fab'} onClick={handleClick}>
            <AddIcon />
          </Fab>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleCreateVitLine}>New Vitline</MenuItem>
          <MenuItem onClick={handleCreateVit}>New Vit</MenuItem>
        </Menu>
      </div>
    )
  }

  return (
    <div className="main-character-style">
      You need to sign in to collect.
      <div className="heading-character-style">
        <a href="#" onClick={() => loginWithPopup()}>
          Log In
        </a>
      </div>
      <div className="heading-character-style" onClick={() => loginWithPopup()}>
        <a href="#" onClick={() => loginWithPopup()}>
          Create Account
        </a>
      </div>
    </div>
  )
}

export default LoginButton
