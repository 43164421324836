import React from 'react'
import YouTube from 'react-youtube'
import MaterialSlider from '@material-ui/core/Slider'

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { withStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router'
import VitsAPI from '../../api/VitsAPI'

import 'rc-slider/assets/index.css'
import Slider from 'rc-slider'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import TimerIcon from '@material-ui/icons/Timer'

import CheckIcon from '@material-ui/icons/Check'


const VIT_MAX_LENGTH = 90

const { createSliderWithTooltip } = Slider
const Range = createSliderWithTooltip(Slider.Range)

export default function VitBuilder({
  vit,
  parentVitlineId,
  attachParentVitId,
  attachParentVitlineId,
  index,
  onSuccess = null,
  onReset = null,
}) {
  const youtubeRef = React.useRef()
  const captionRef = React.useRef()
  const builderContainerRef = React.useRef()

  const [draft, draftVits, setDraftVits] = VitsAPI.useVitlineDraft()
  const history = useHistory()

  const [player, setPlayer] = React.useState(null)
  const [vitDraft, setVitDraft] = React.useState(null)
  const [previousVitDraft, setPreviousVitDraft] = React.useState(null)
  const [currentTime, setCurrentTime] = React.useState(0)
  const [totalTime, setTotalTime] = React.useState(null)
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [committed, setCommitted] = React.useState(true)
  //const [isDirty, setIsDirty] = React.useState(false)

  const updateVit = VitsAPI.useVitMutation(vit.vitId)
  const [createVit, newVitId] = VitsAPI.useNewVitMutation()
  const [createDraftVit, newDraftVitId] = VitsAPI.useNewDraftVitMutation()
  const createVitAtVitlineIndex = VitsAPI.useNewVitAtVitLineIndexMutation(
    parentVitlineId,
    index
  )
  const [vitPublish, vitUnpublish] = VitsAPI.useVitPublishMutation(vit.vitId)
  const [vitAttach, newVitAttach] = VitsAPI.useVitAttachMutation(
    attachParentVitId
  )
  const [vitlineAttach, newVitlineAttach] = VitsAPI.useVitLineAttachMutation(
    attachParentVitlineId
  )

  const isPublished = vit && vit.published && vit.published.formatted
  const [userId] = VitsAPI.useUserId()

  const keyListener = React.useCallback(
    (event) => {
      const { key } = event
      const isCaptionInput = event.target === captionRef.current
      const isBuilderChild = builderContainerRef.current.contains(event.target)
      if (key === ' ') {
        if (!isCaptionInput && isBuilderChild) {
          if (player.getPlayerState() === window.YT.PlayerState.PLAYING) {
            player.pauseVideo()
          } else {
            player.playVideo()
          }
        }
      } else if (key === 37) {
        // left
      } else if (key === 38) {
        // up
      } else if (key === 39) {
        // right
      } else if (key === 40) {
        // down
      }
    },
    [player]
  )

  React.useEffect(() => {
    window.addEventListener('keydown', keyListener, true)
    return () => window.removeEventListener('keydown', keyListener, true)
  }, [keyListener])

  React.useEffect(() => {
    if (newVitId) {
      history.push('/view/vit:' + newVitId)
    }
  }, [newVitId])

  React.useEffect(() => {
    if (newDraftVitId) {
      history.push('/view/vit:' + newDraftVitId)
    }
  }, [newDraftVitId])

  React.useEffect(() => {
    if (player) {
      setTotalTime(player.getDuration())
      if (vitDraft.end > player.getDuration()) {
        const newDraft = { ...vitDraft }
        newDraft.end = player.getDuration()
        setVitDraft(newDraft)
      }
    }
  }, [player])

  React.useEffect(() => {
    if (isPlaying && player) {
      const interval = setInterval(() => {
        setCurrentTime(player.getCurrentTime())
      }, 100)
      return () => clearInterval(interval)
    }
  }, [isPlaying, player])

  React.useEffect(() => {
    setVitDraft(vit)
  }, [vit])

  const opts = {
    playerVars: {
      controls: 0,
      showinfo: 0,
      modestbranding: 1,
      autoplay: 1,
    },
  }

  const handlePreviewEnd = () => {
    if (player) {
      player.loadVideoById({
        videoId: vitDraft.source.videoId,
        startSeconds: Math.max(0, vitDraft.end - 1),
        endSeconds: vitDraft.end,
      })
    }
  }

  const handlePreviewStart = () => {
    if (player) {
      player.loadVideoById({
        videoId: vitDraft.source.videoId,
        startSeconds: vitDraft.start,
        endSeconds: vitDraft.end,
      })
    }
  }

  const [beforeCommitVitDraft, setBeforeCommitVitDraft] = React.useState(null)

  React.useEffect(() => {
    const prevVitDraft = previousVitDraft
    if (prevVitDraft && committed) {
      if (
        (prevVitDraft.end === undefined || prevVitDraft.end === vitDraft.end) &&
        prevVitDraft.start === vitDraft.start
      ) {
        return
      }
      if (prevVitDraft.end === vitDraft.end) {
        handlePreviewStart()
      } else {
        handlePreviewEnd()
      }
    }
  }, [
    vitDraft ? vitDraft.start : null,
    vitDraft ? vitDraft.end : null,
    committed,
    previousVitDraft ? previousVitDraft.start : null,
    previousVitDraft ? previousVitDraft.end : null,
  ])

  const updateTimeAfter = (triggerPreview, newStartTime, newEndTime) => {
    setCommitted(true)
    if (!triggerPreview) {
      return
    }

    if (newStartTime !== beforeCommitVitDraft.start) {
      handlePreviewStart()
    }

    if (newEndTime !== beforeCommitVitDraft.end) {
      handlePreviewEnd()
    }

    //handlePreviewStart()
  }

  const updateTime = (triggerPreview, newStartTime, newEndTime) => {
    if (committed) {
      setBeforeCommitVitDraft({ ...vitDraft })
    }

    setCommitted(false)

    if (triggerPreview && newStartTime !== vitDraft.start) {
      player.seekTo(newStartTime)
      player.pauseVideo()
      setCurrentTime(newStartTime)
    }

    if (triggerPreview && newEndTime !== vitDraft.end) {
      player.seekTo(newEndTime)
      player.pauseVideo()
      setCurrentTime(newEndTime)
    }

    const newDraftVit = {
      ...vitDraft,
      start: newStartTime,
      end: newEndTime,
    }
    setPreviousVitDraft(vitDraft)
    setVitDraft(newDraftVit)
  }

  const onStartFocus = React.useCallback(() => {
    if (!player) {
      player.cueVideoById({
        videoId: vitDraft.source.videoId,
        startSeconds: vitDraft.start,
        endSeconds: vitDraft.end,
      })
    } else {
      player.seekTo(vitDraft.start)
      player.pauseVideo()
      setCurrentTime(vitDraft.start)
    }
  }, [player, vitDraft])
  const onEndFocus = React.useCallback(() => {
    if (!player) {
      player.cueVideoById({
        videoId: vitDraft.source.videoId,
        startSeconds: Math.max(0, vitDraft.end - 1),
        endSeconds: vitDraft.end,
      })
    } else {
      player.seekTo(vitDraft.end)
      player.pauseVideo()
      setCurrentTime(vitDraft.end)
    }
  }, [player, vitDraft])
  const onRangeFocus = React.useCallback(() => {}, [])

  const onStartBlur = React.useCallback(() => {
    //handlePreviewStart()
  }, [handlePreviewStart])
  const onEndBlur = React.useCallback(() => {
    //handlePreviewEnd()
  }, [handlePreviewEnd])

  const onRangeBlur = React.useCallback(() => {}, [])

  if (!vitDraft) {
    return null
  }

  const isVitUnused = !vit.vitlines || vit.vitlines.length === 0

  console.log('VIT UNUSED', vit.vitlines)

  const handleCreate = async () => {
    if (vitDraft.vitId && isVitUnused) {
      updateVit({
        variables: {
          start: vitDraft.start,
          end: vitDraft.end,
          title: vitDraft.title,
        },
      })
    } else {
      if (parentVitlineId) {
        if (parentVitlineId === draft.vitlineId) {
          const result = await createDraftVit({
            variables: {
              start: vitDraft.start,
              end: vitDraft.end,
              title: vitDraft.title,
              videoId: vitDraft.source.videoId,
            },
          })

          if (result) {
            const newDraftVits = [...draftVits]
            newDraftVits[index] = result.data.newVitDraft
            setDraftVits(newDraftVits)
          }
        } else {
          createVitAtVitlineIndex({
            variables: {
              start: vitDraft.start,
              end: vitDraft.end,
              title: vitDraft.title,
              videoId: vitDraft.source.videoId,
            },
          })
        }
      } else if (attachParentVitlineId) {
        newVitlineAttach({
          start: vitDraft.start,
          end: vitDraft.end,
          title: vitDraft.title,
          videoId: vitDraft.source.videoId,
        })
      } else if (attachParentVitId) {
        newVitAttach({
          start: vitDraft.start,
          end: vitDraft.end,
          title: vitDraft.title,
          videoId: vitDraft.source.videoId,
        })
      } else {
        createVit({
          variables: {
            start: vitDraft.start,
            end: vitDraft.end,
            title: vitDraft.title,
            videoId: vitDraft.source.videoId,
          },
        })
      }
      if (onSuccess) {
        onSuccess(vitDraft)
      }
    }
  }

  const validateVit = (vit) => {
    if (!vit.title || vit.title.length === 0) {
      return 'Caption is required'
    }

    if (vit.end - vit.start > VIT_MAX_LENGTH) {
      return 'Maximum duration for a vit is 90 seconds'
    }

    return undefined
  }

  const createError = validateVit(vitDraft)

  return (
    <div
      ref={builderContainerRef}
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <label className="heading-2-character-style">CAPTION</label>
        <input
          ref={captionRef}
          style={{
            display: 'flex',
            flexGrow: 1,
            marginLeft: '24px',
            marginRight: '24px',
          }}
          className="rounded-box code-character-style"
          type="text"
          value={vitDraft.title}
          onChange={(e) => {
            const newDraftVit = {
              ...vitDraft,
              title: e.target.value,
            }
            setVitDraft(newDraftVit)
            //setIsDirty(true)
          }}
        ></input>

        {onReset && (
          <Button color="primary" onClick={onReset}>
            RESET
          </Button>
        )}

        {!vitDraft.vitId || (vit && vit.author && userId !== userId) ? (
          <></>
        ) : (
          <Button
            color="primary"
            onClick={() => {
              if (isPublished) {
                vitUnpublish()
              } else {
                vitPublish()
              }
            }}
          >
            {isPublished ? 'UNPUBLISH' : 'PUBLISH'}
          </Button>
        )}

        {createError && (
          <Tooltip title={createError} placement={'top'}>
            <span>
              <Button
                disabled={true} //{!isDirty}
                color="primary"
                onClick={handleCreate}
              >
                {!vitDraft.vitId ? 'CREATE' : isVitUnused ? 'SAVE' : 'FORK'}
              </Button>
            </span>
          </Tooltip>
        )}
        {!createError && isVitUnused && (
          <Button
            disabled={false} //{!isDirty}
            color="primary"
            onClick={handleCreate}
          >
            {!vitDraft.vitId ? 'CREATE' : 'SAVE'}
          </Button>
        )}
        {!createError && !isVitUnused && (
          <Tooltip
            title={
              'Vit is being used in a vitline, so you need to create a new one to make changes.'
            }
            placement={'top'}
          >
            <Button
              disabled={false} //{!isDirty}
              color="primary"
              onClick={handleCreate}
            >
              {!vitDraft.vitId ? 'CREATE' : 'FORK'}
            </Button>
          </Tooltip>
        )}
      </div>
      <div
        style={{
          position: 'relative',
          flexGrow: 1,
          display: 'flex',
        }}
      >
        <YouTube
          className={'youtube-video'}
          ref={youtubeRef}
          videoId={vit.source.videoId}
          opts={opts}
          onReady={(event) => {
            if (vit.end === undefined) {
              setVitDraft({ ...vit, end: event.target.getDuration() })
            }
            setPlayer(event.target)
          }}
          onStateChange={(event) => {
            setIsPlaying(event.data === window.YT.PlayerState.PLAYING)
          }}
        />
      </div>

      {vitDraft.end !== null && totalTime !== null && (
        <VitBuilderTimeline
          startTime={vitDraft.start}
          endTime={vitDraft.end}
          currentTime={currentTime}
          totalTime={totalTime}
          onChangeTime={(newValue) => {
            player.seekTo(newValue)
            setCurrentTime(newValue)
          }}
          onChangeVit={updateTime}
          onAfterChangeVit={updateTimeAfter}
          onPreviewStart={handlePreviewStart}
          onPreviewEnd={handlePreviewEnd}
          onStartFocus={onStartFocus}
          onStartBlur={onStartBlur}
          onEndFocus={onEndFocus}
          onEndBlur={onEndBlur}
        ></VitBuilderTimeline>
      )}
    </div>
  )
}

function ValueLabelComponent(props) {
  const { children, open, value } = props

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  )
}

const PositionSlider = withStyles({
  root: {
    color: 'var(--unnamed-color-137d3f)',
    height: 15,
    padding: 0,
    border: '0px solid var(--unnamed-color-137d3f)',
    top: 0,
    boxSizing: 'border-box',
  },
  thumb: {
    height: 14,
    width: 14,
    marginTop: 0,
    marginLeft: -7,
    opacity: 0.5,
    backgroundColor: 'var(--unnamed-color-137d3f)',
    clipPath: 'polygon(0% 50%, 50% 0%, 100% 50%)',
    border: '0px solid currentColor',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    pointerEvents: 'none',
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 0,
    borderRadius: 4,
  },
  rail: {
    height: 0,
    borderRadius: 4,
  },
})(MaterialSlider)

const PositionSliderDisabled = withStyles({
  root: {
    color: 'var(--unnamed-color-137d3f)',
    height: 15,
    padding: 0,
    border: '0px solid var(--unnamed-color-137d3f)',
    top: 0,
    boxSizing: 'border-box',
  },
  thumb: {
    height: 14,
    width: 14,
    marginTop: 0,
    marginLeft: -7,
    opacity: 0.5,
    pointerEvents: 'none',
    backgroundColor: 'var(--unnamed-color-137d3f)',
    border: '2px solid currentColor',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 0,
    borderRadius: 4,
  },
  rail: {
    height: 0,
    borderRadius: 4,
  },
})(MaterialSlider)

function ArrowThumbComponent(props) {
  return (
    <span {...props}>
      <KeyboardArrowUpIcon />
    </span>
  )
}

function VitBuilderTimeline({
  startTime,
  endTime,
  currentTime,
  totalTime,
  onChangeTime,
  onAfterChangeVit,
  onChangeVit,
  onPreviewStart,
  onPreviewEnd,
  onStartFocus,
  onStartBlur,
  onEndFocus,
  onEndBlur,
}) {
  const [zoom, setZoom] = React.useState([0, 1])
  const scrollRef = React.useRef()
  const rangeRef = React.useRef()

  const [hoverProgress, setHoverProgress] = React.useState(0)

  const keyListener = React.useCallback((event) => {
    const { key } = event

    if (event.target === rangeRef.current) {
      return
    }

    if (key === 37) {
      // left
    } else if (key === 38) {
      // up
    } else if (key === 39) {
      // right
    } else if (key === 40) {
      // down
    }
  }, [])

  React.useEffect(() => {
    window.addEventListener('keydown', keyListener, true)
    return () => window.removeEventListener('keydown', keyListener, true)
  }, [keyListener])

  const [zoomStart, zoomEnd] = zoom

  const handleZoomInStart = () => {
    const currentZoomSize = zoomEnd - zoomStart
    const nextZoomSize = currentZoomSize / 4.0
    setZoom([
      Math.max(0, (startTime - nextZoomSize * totalTime) / totalTime),
      Math.min(1, (startTime + nextZoomSize * totalTime) / totalTime),
    ])
  }

  const handleZoomInEnd = () => {
    const currentZoomSize = zoomEnd - zoomStart
    const nextZoomSize = currentZoomSize / 4.0
    setZoom([
      Math.max(0, (endTime - nextZoomSize * totalTime) / totalTime),
      Math.min(1, (endTime + nextZoomSize * totalTime) / totalTime),
    ])
  }

  const handleZoomOutStart = () => {
    const currentZoomSize = zoomEnd - zoomStart
    const nextZoomSize = currentZoomSize * 4.0
    setZoom([
      Math.max(0, (startTime - nextZoomSize * totalTime) / totalTime),
      Math.min(1, (startTime + nextZoomSize * totalTime) / totalTime),
    ])
  }

  const handleZoomOutEnd = () => {
    const currentZoomSize = zoomEnd - zoomStart
    const nextZoomSize = currentZoomSize * 4.0
    setZoom([
      Math.max(0, (endTime - nextZoomSize * totalTime) / totalTime),
      Math.min(1, (endTime + nextZoomSize * totalTime) / totalTime),
    ])
  }

  React.useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft =
        zoomStart * (scrollRef.current.scrollWidth - 0)
    }
  }, [zoom, scrollRef.current])

  const steps = [0.1, 1, 5, 10, 30, 60, 60 * 5, 60 * 10, 60 * 30, 60 * 60]

  let stepIndex = 0
  while (
    ((zoomEnd - zoomStart) * totalTime) / steps[stepIndex] > 10 &&
    stepIndex < steps.length
  ) {
    stepIndex++
  }

  const rangeMarks = { 0: '0' }
  const marks = [{ value: 0, label: '0' }]
  for (let i = 0; i < totalTime; i += steps[stepIndex]) {
    rangeMarks[`${i}`] = labelForStep(i, totalTime, false, true)
    marks.push({ value: i, label: labelForStep(i, totalTime) })
  }

  const isMaxZoomedOut = zoomStart === 0 && zoomEnd === 1
  const isMaxZoomedIn = (zoomEnd - zoomStart) * totalTime <= 5

  const [isStartFocussed, setStartFocussed] = React.useState(false)
  const [isEndFocussed, setEndFocussed] = React.useState(false)
  const [isRangeFocussed, setRangeFocussed] = React.useState(false)

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          ref={scrollRef}
          style={{
            width: 'calc(100% + 32px)',

            marginLeft: '-16px',
            marginRight: '-16px',
          }}
        >
          <div
            style={{
              left: '16px',
              width:
                'calc(' + (1 / (zoomEnd - zoomStart)) * 100 + '%' + ' - 32px)',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              marginTop: '4px',
            }}
          >
            <div
              style={{
                position: 'relative',
                width: '100%',
                marginBottom: '32px',
              }}
            >
              <Range
                ref={rangeRef}
                min={0}
                max={totalTime}
                step={0.02}
                defaultValue={[startTime, endTime]}
                value={[startTime, endTime]}
                tipFormatter={(value) => labelForStep(value, totalTime)}
                onChange={(newValue) => {
                  let [newStartTime, newEndTime] = newValue

                  if (newStartTime !== startTime || newEndTime !== endTime) {
                    setRangeFocussed(true)
                    onChangeVit(true, newStartTime, newEndTime)
                  }
                }}
                onAfterChange={(newValue) => {
                  if (isRangeFocussed) {
                    let [newStartTime, newEndTime] = newValue
                    onAfterChangeVit(true, newStartTime, newEndTime)
                    setRangeFocussed(false)
                  }
                }}
                tipProps={{
                  placement: 'bottom',
                }}
                marks={rangeMarks}
                allowCross={false}
              />
            </div>

            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '20px',
                pointerEvents: 'none',
                opacity: 1.0,
              }}
            >
              <PositionSliderDisabled
                ValueLabelComponent={ValueLabelComponent}
                value={currentTime}
                onChange={(event, newValue) => {
                  onChangeTime(newValue)
                }}
                step={0.02}
                min={0}
                max={totalTime}
                valueLabelDisplay="on"
                aria-labelledby="range-slider"
                getAriaValueText=""
                valueLabelFormat={(value) => labelForStep(value, totalTime)}
              ></PositionSliderDisabled>
            </div>

            <div
              className="show-on-hover"
              style={{
                position: 'absolute',
                width: '100%',
                top: '20px',
                height: '20px',
              }}
              onMouseMove={(e) => {
                const x = e.nativeEvent.offsetX
                //const rect = e.target.getBoundingClientRect()

                const hoverProgress = x / e.target.offsetWidth
                setHoverProgress(hoverProgress)
              }}
            >
              <PositionSlider
                value={hoverProgress * totalTime}
                onChange={(event, newValue) => {
                  onChangeTime(newValue)
                }}
                step={0.02}
                min={0}
                max={totalTime}
                valueLabelDisplay="off"
                aria-labelledby="range-slider"
                valueLabelFormat={(value) => labelForStep(value, totalTime)}
              ></PositionSlider>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              color: 'black',
              minHeight: 70,
            }}
          >
            <FormControl>
              <VitTimeInput
                label={'START TIME'}
                value={startTime}
                playerValue={currentTime}
                totalTime={totalTime}
                rangeStart={0}
                rangeEnd={endTime}
                onZoomIn={isMaxZoomedIn ? undefined : handleZoomInStart}
                onZoomOut={isMaxZoomedOut ? undefined : handleZoomOutStart}
                onChange={(newStartTime) => {
                  onChangeVit(true, newStartTime, endTime)
                }}
                onFocus={() => {
                  setStartFocussed(true)
                  onStartFocus()
                }}
                onBlur={(preview) => {
                  onAfterChangeVit(preview, startTime, endTime)
                  setStartFocussed(false)
                  onStartBlur()
                  // onAfterChangeVit()
                }}
              ></VitTimeInput>
            </FormControl>
          </div>
          <div style={{ display: 'flex', flexGrow: 1, minWidth: '12px' }}></div>
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                backgroundColor: 'var(--unnamed-color-e7f0eb)',
              }}
            >
              {currentTime >= startTime &&
                currentTime <= endTime &&
                labelForStep(currentTime - startTime, totalTime) + ' / '}

              {labelForStep(endTime - startTime, totalTime)}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                maxHeight: '35px',
              }}
            >
              <Tooltip title="Preview vit">
                <Button color="primary" onClick={onPreviewStart}>
                  <PlayArrowIcon></PlayArrowIcon>PLAY
                </Button>
              </Tooltip>
              <Tooltip title="Preview last second">
                <Button color="primary" onClick={onPreviewEnd}>
                  <PlayArrowIcon></PlayArrowIcon>END
                </Button>
              </Tooltip>
            </div>
          </div>
          <div style={{ display: 'flex', flexGrow: 1, minWidth: '12px' }}></div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              color: 'black',
              minHeight: 70,
            }}
          >
            <FormControl>
              <VitTimeInput
                label={'END TIME'}
                value={endTime}
                playerValue={currentTime}
                totalTime={totalTime}
                rangeStart={startTime}
                rangeEnd={startTime + VIT_MAX_LENGTH}
                onZoomIn={isMaxZoomedIn ? undefined : handleZoomInEnd}
                onZoomOut={isMaxZoomedOut ? undefined : handleZoomOutEnd}
                onChange={(newEndTime) => {
                  onChangeVit(true, startTime, newEndTime)
                }}
                onFocus={() => {
                  setEndFocussed(true)
                  onEndFocus()
                }}
                onBlur={(preview) => {
                  onAfterChangeVit(preview, startTime, endTime)
                  setEndFocussed(true)
                  onEndBlur()
                  //onAfterChangeVit()
                }}
                totalVitTime={endTime - startTime}
              ></VitTimeInput>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  )
}

function timeForLabel(timeString) {
  if (!isNaN(timeString)) {
    return parseFloat(timeString)
  }

  const regex = /((\d+)h)?((\d+)m)?((\d*(\.\d+)?)s?)?/

  let regexValue = regex.exec(timeString)
  if (regexValue != null) {
    let hours = parseInt(regexValue[1])
    let minutes = parseInt(regexValue[4])
    let seconds = parseFloat(regexValue[6])

    if (isNaN(hours) && isNaN(minutes) && isNaN(seconds)) {
      return -1
    }

    hours = isNaN(hours) ? 0 : hours
    minutes = isNaN(minutes) ? 0 : minutes
    seconds = isNaN(seconds) ? 0 : seconds

    return seconds + minutes * 60 + hours * 60 * 60
  }

  return -1
}

function seconds(value, full) {
  const extraSeconds = value % 60
  if (full) {
    return Math.round(extraSeconds * 100) / 100
  }
  return Math.floor(extraSeconds)
}

function labelForStep(time, totalTime, minimize = false, full = false) {
  const value = full ? time : Math.floor(time)

  if (value === 0) {
    return '0s'
  }

  if (totalTime < 60 || (minimize && value < 60)) {
    return seconds(value, full) + 's'
  }

  if (totalTime < 60 * 60 || (minimize && value < 60 * 60)) {
    return Math.floor(value / 60) + 'm' + seconds(value, full) + 's'
  }

  if (value % (60 * 60) === 0) {
    return Math.floor(value / (60 * 60)) + 'h'
  }

  if (value % 60 === 0) {
    return Math.floor(value / (60 * 60)) + 'h' + Math.floor(value / 60) + 'm'
  }

  return (
    Math.floor(value / (60 * 60)) +
    'h' +
    Math.floor((value % (60 * 60)) / 60) +
    'm' +
    seconds(value, full) +
    's'
  )
}

var InputErrors = {
  RANGE_LOW: {
    value: 0,
    helperText: 'Ends before start time. Fix the start time.',
  },
  RANGE_HIGH: {
    value: 1,
    helperText: 'Maximum length for vit is 90 seconds.',
  },
  FORMAT: { value: 2, helperText: 'Format error, use ##m##s format.' },
}

function VitTimeInput({
  label,
  value,
  playerValue,
  totalTime,
  rangeStart,
  rangeEnd,
  onZoomIn,
  onZoomOut,
  onChange,
  onFocus,
  onBlur,
  totalVitTime,
}) {
  const [isTyping, setIsTyping] = React.useState(false)
  const [inputValue, setInputValue] = React.useState(
    labelForStep(value, totalTime, true, true)
  )
  const [isFocussed, setFocussed] = React.useState(false)

  const [error, setError] = React.useState(undefined)

  React.useEffect(() => {
    const newSeconds = timeForLabel(inputValue)
    if (newSeconds === -1) {
      setError(InputErrors.FORMAT)
      return
    }
    if (newSeconds >= rangeStart && newSeconds <= rangeEnd) {
      setError(undefined)
    } else {
      if (newSeconds < rangeStart) {
        setError(InputErrors.RANGE_LOW)
      } else if (newSeconds > rangeEnd) {
        setError(InputErrors.RANGE_HIGH)
      }
    }
  }, [rangeStart, rangeEnd, inputValue])

  React.useEffect(() => {
    setError(undefined)
    if (!isTyping) {
      setInputValue(labelForStep(value, totalTime, true, true))
    }
  }, [value, isTyping])

  const handleValueChange = (newValue) => {
    setInputValue(newValue)

    const newSeconds = timeForLabel(newValue)
    if (newSeconds === -1) {
      setError(InputErrors.FORMAT)
      return
    }
    if (newSeconds >= rangeStart && newSeconds <= rangeEnd) {
      setError(undefined)
      onChange(newSeconds)
    } else {
      if (newSeconds < rangeStart) {
        setError(InputErrors.RANGE_LOW)
      } else if (newSeconds > rangeEnd) {
        setError(InputErrors.RANGE_HIGH)
      }
    }
  }

  const inputRef = React.useRef()
  return (
    <TextField
      inputRef={inputRef}
      error={error !== undefined}
      label={label}
      helperText={error ? error.helperText : ''}
      color="primary"
      type={'text'}
      value={inputValue}
      onChange={(event) => {
        setIsTyping(true)
        handleValueChange(event.target.value)
      }}
      inputProps={{
        onFocus: (event) => {
          setFocussed(true)
          if (onFocus) {
            onFocus(event)
          }
        },
        onBlur: (event) => {
          setFocussed(false)
          if (onBlur) {
            onBlur(false)
          }
        },
      }}
      onFocus={(event) => {
        setFocussed(true)
        if (onFocus) {
          onFocus(event)
        }
      }}
      onBlur={(event) => {
        setFocussed(false)
        if (onBlur) {
          onBlur(event)
        }
      }}
      InputProps={{
        onBlur: () => {
          setIsTyping(false)
        },
        endAdornment: (
          <InputAdornment position="end">
            {isFocussed ? (
              <Tooltip title="Accept change">
                <IconButton
                  color="primary"
                  aria-label="Accept change"
                  onMouseDown={(e) => {
                    e.preventDefault()
                  }}
                  onClick={() => {
                    inputRef.current.blur()
                    setFocussed(false)
                    onBlur(true)
                  }}
                >
                  <CheckIcon></CheckIcon>
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Set to current player position">
                <IconButton
                  color="primary"
                  aria-label="Set to current player position"
                  onClick={() => {
                    const newTimeValue = Math.round(playerValue * 100) / 100
                    const newInputValue = labelForStep(
                      newTimeValue,
                      totalTime,
                      true,
                      true
                    )
                    setInputValue(newInputValue)
                    handleValueChange(newInputValue)
                    inputRef.current.focus()
                  }}
                >
                  <TimerIcon></TimerIcon>
                </IconButton>
              </Tooltip>
            )}

            <>
              <Tooltip title="Fine adjustement">
                <IconButton
                  color="primary"
                  disabled={value <= rangeStart}
                  onMouseDown={(e) => {
                    e.preventDefault()
                  }}
                  onClick={(e) => {
                    e.preventDefault()

                    if (!isFocussed) {
                      inputRef.current.focus()
                    }

                    const newValue = Math.max(
                      rangeStart,
                      Math.min(rangeEnd, value - 1 / 30)
                    )
                    onChange(newValue)
                  }}
                >
                  <ChevronLeft></ChevronLeft>
                </IconButton>
              </Tooltip>
              <Tooltip title="Fine adjustement">
                <IconButton
                  color="primary"
                  disabled={value >= rangeEnd}
                  onMouseDown={(e) => {
                    e.preventDefault()
                  }}
                  onClick={(e) => {
                    e.preventDefault()

                    if (!isFocussed) {
                      inputRef.current.focus()
                    }

                    const newValue = Math.max(
                      rangeStart,
                      Math.min(rangeEnd, value + 1 / 30)
                    )
                    onChange(newValue)
                  }}
                >
                  <ChevronRight></ChevronRight>
                </IconButton>
              </Tooltip>
            </>
          </InputAdornment>
        ),
      }}
    />
  )
}
