import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActionArea from '@material-ui/core/CardActionArea'
import IconButton from '@material-ui/core/IconButton'
import { red } from '@material-ui/core/colors'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ShareIcon from '@material-ui/icons/Share'
import { Link as RouterLink } from 'react-router-dom'
import VitPreview from '../../VitPreview'
import VideoPreview from '../../VideoPreview'
import VitlinePreview from '../../VitlinePreview'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LinkIcon from '@material-ui/icons/Link'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

export default function NodeCardView({
  id,
  typename,
  title,
  subHeader,
  imageUrl,
  url,
  shareUrl,
  onClick,
  vit,
  vits,
  videoId,
  minimal,
  saved,
  onSaveToggle,
  disableBorder,
  showPreview,
  infoOnly,
  mediaOnly,
  color = 'primary',
  fontSize = '100%',
  ...props
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      boxShadow: '0 0 0 0',
      borderRadius: '0px 0px 0px 0px',
      color:
        color === 'primary'
          ? 'var(--unnamed-color-137d3f)'
          : 'rgb(223, 36, 46)',
    },
    media: {
      zIndex: 2,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    content: {
      borderRadius: '0px 0px 0px 0px',
      marginTop: typename === 'VitLine' ? '4px' : '0px',
      border:
        typename === 'VitLine' && !disableBorder
          ? '1px solid var(--unnamed-color-137d3f)'
          : undefined,
    },
    header: {},
  }))

  const classes = useStyles()
  const [hover, setHover] = React.useState(false)
  const taggedTagged = React.useMemo(() => {
    return generateTaggedTitle(title)
  }, [title])

  return (
    <div
      style={{
        position: 'relative',
      }}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      {!infoOnly && typename === 'VitLine' && (
        <div
          style={{
            backgroundColor: 'white',
            color: 'white',
            position: 'absolute',
            top: '0px',
            right: '0px',
            width: '15%',
            height: (15 * 16) / 9 + '%',
            zIndex: 1,
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%)',
          }}
        >
          <div
            style={{
              backgroundColor: 'var(--unnamed-color-137d3f)',
              color: 'white',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
              clipPath: 'polygon(5% 0%, 100% 0%, 100% 95%)',
            }}
          >
            <span
              style={{
                margin: 'auto',
                display: 'block',
                transform: 'rotate(45deg)',
                transformOrigin: '55% 45%',
                textAlign: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              <span
                style={{
                  fontSize: fontSize,
                }}
              >
                {vits ? vits.length : 0}
              </span>
            </span>
          </div>
        </div>
      )}
      {!infoOnly && typename === 'Vit' && (
        <div
          style={{
            backgroundColor: 'white',
            color: 'white',
            position: 'absolute',
            top: '-5px',
            right: '-5px',
            width: '95px',
            height: '95px',
            paddingRight: '5px',
            paddingTop: '5px',
            zIndex: 1,
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%)',
          }}
        >
          <div
            style={{
              backgroundColor: 'var(--unnamed-color-137d3f)',
              color: 'white',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
              clipPath: 'polygon(0% 0%, 20% 0%, 100% 80%, 100% 100%)',
            }}
          ></div>
        </div>
      )}

      <Card color={color} className={classes.root} {...props}>
        {!infoOnly && (
          <>
            <CardMediaVitPreview
              id={id}
              imageUrl={imageUrl}
              vits={vits}
              vit={vit}
              videoId={videoId}
              color={color}
              minimal={minimal}
              showPreview={showPreview}
              url={url}
              onClick={onClick}
            />
            {typename === 'VitLine' && (
              <div style={{ height: '10px', width: '100%' }}></div>
            )}
          </>
        )}

        {!mediaOnly && (
          <div style={{ pointerEvents: 'auto' }}>
            <CardActionArea
              component={onClick ? undefined : RouterLink}
              onClick={onClick}
              to={url}
            >
              <div className={classes.content}>
                <CardHeader
                  className={classes.header}
                  title={taggedTagged}
                  subheader={subHeader}
                  subheaderTypographyProps={{ noWrap: !hover }}
                  titleTypographyProps={{ noWrap: !hover }}
                  action={
                    minimal || !hover ? undefined : (
                      <>
                        {onSaveToggle && (
                          <IconButton
                            aria-label="add to favorites"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              onSaveToggle()
                            }}
                          >
                            <FavoriteIcon
                              color={saved ? 'secondary' : undefined}
                            />
                          </IconButton>
                        )}

                        <span>
                          <Tooltip title="Share" aria-label="add">
                            <IconButton
                              aria-controls="share-menu"
                              aria-haspopup="true"
                              aria-label="share"
                              onClick={handleClick}
                            >
                              <ShareIcon />
                            </IconButton>
                          </Tooltip>
                          <StyledMenu
                            id="share-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            <StyledMenuItem
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()

                                navigator.clipboard.writeText(shareUrl)
                                setAnchorEl(null)
                              }}
                            >
                              <ListItemIcon>
                                <LinkIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary="Copy link" />
                            </StyledMenuItem>
                          </StyledMenu>
                        </span>
                      </>
                    )
                  }
                />
              </div>
            </CardActionArea>
          </div>
        )}
      </Card>
    </div>
  )
}

function MediaView({
  id,
  vits,
  vit,
  videoId,
  imageUrl,
  color,
  minimal,
  showPreview,
  url,
  onClick,
}) {
  if (vits) {
    return (
      <VitlinePreview
        id={id}
        color={color}
        vits={vits}
        showPreview={!minimal}
        url={url}
      ></VitlinePreview>
    )
  } else if (vit) {
    return (
      <VitPreview
        color={color}
        vit={vit}
        showPreview={!minimal || showPreview}
        url={url}
        onClick={onClick}
      ></VitPreview>
    )
  } else if (videoId) {
    return (
      <VideoPreview color={color} videoId={videoId} url={url}></VideoPreview>
    )
  } else {
    return <VitPreview color={color} image={imageUrl} url={url}></VitPreview>
  }
}

function generateTaggedTitle(title) {
  if (!title) {
    return undefined
  }

  const hashTagRegex = /(#\w+)/

  let parts = title.split(hashTagRegex) // re is a matching regular expression
  let newParts = []

  for (let i = 0; i < parts.length; i++) {
    if (parts[i].match(hashTagRegex)) {
      newParts.push(
        <Link
          className={'hash-tag-link'}
          key={'link' + i}
          to={`/search/${encodeURIComponent(parts[i])}`}
        >
          {parts[i]}
        </Link>
      )
    } else {
      newParts.push(parts[i])
    }
  }

  return <span>{newParts}</span>
}

const CardMediaVitPreview = React.memo(MediaView)
