import AspectRatio from 'r-aspect-ratio'
import React from 'react'

export function ThumbnailView({ image, showClip }) {
  return (
    <div
      className={
        showClip ? 'node-thumbnail-img-clipped' : 'node-thumbnail-img-noclip'
      }
      style={{
        backgroundColor: '#DDDDDD',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <AspectRatio ratio={9 / 16} width="100%" className="node-thumbnail">
        <img
          className={'node-thumbnail-img '}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          src={image}
        />
      </AspectRatio>
    </div>
  )
}
