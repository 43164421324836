import React, { CSSProperties } from 'react'

import { XYCoord, useDragLayer } from 'react-dnd'
import {
  FractalGraphViewProps,
  FractalGraphContext,
  GraphLayoutEnum,
  GraphLayout,
  GraphFrame,
} from './types'
import { useWindowSize } from './hooks'
import { useSpring, useSpringRef } from 'react-spring'
import AspectRatio from 'r-aspect-ratio'

const extraRows = 2

type MapNode = GraphLayoutEnum | null
interface Dictionary<T> {
  [key: string]: T
}

function binarySearch<T>(array: Array<T>, pred: (value: T) => boolean) {
  let lo = -1,
    hi = array.length
  while (1 + lo < hi) {
    const mi = lo + ((hi - lo) >> 1)
    if (pred(array[mi])) {
      hi = mi
    } else {
      lo = mi
    }
  }
  return hi
}

/*
type GraphState = {
  value: FractalGraphZoomState;
  currentZoomContext: GraphZoomContext | undefined;
  nextZoomContext?: GraphZoomContext | undefined;
  currentMargins: GraphMargins | undefined;
  nextMargins?: GraphMargins | undefined;
};

type GraphAction = {
  type: "rendered" | "changeZoom" | "changePath";
  nextZoomContext?: GraphZoomContext | undefined;
  nextMargins?: GraphMargins | undefined;
};

function graphReducer(state: GraphState, action: GraphAction): GraphState {
  const { type, nextZoomContext, nextMargins } = action;

  switch (type) {
    case "changePath":
      return {
        ...state,
        currentZoomContext: state.currentZoomContext
          ? {
              graphFrame: state.currentZoomContext.graphFrame,
              path: nextZoomContext?.path ?? [],
            }
          : undefined,
      };
    case "rendered":
      switch (state.value) {
        case "prepare":
          return {
            ...state,
            value: "zooming",
            currentZoomContext: state.nextZoomContext,
            currentMargins: state.nextMargins,
          };
        case "zooming":
          return {
            ...state,
            value: state.currentZoomContext ? "zoomed" : "root",
          };
        default:
          return state;
      }
    case "changeZoom":
      switch (state.value) {
        case "zooming":
          return {
            ...state,
            currentZoomContext: nextZoomContext,
            currentMargins: nextMargins,
          };
        default:
          return {
            ...state,
            value: "prepare",
            nextZoomContext: nextZoomContext,
            nextMargins: nextMargins,
          };
      }
  }
}*/

// Requirements:
// Fractal paramters
// Zooming in on elements
// Parent element is a child
// Scrolling zoomed in nodes works
// Lazy loading children
// Removing non-visible items from DOM

export function FractalGraphView({
  data,
  factory,
  keyPath,
  layout,
  size,
  loadMore,
  zoomContext,
  margins,
  columns,
  aspectRatio,
  onScroll,
  onZoomContextChange,
}: FractalGraphViewProps): JSX.Element {
  /*
  const [state, dispatch] = React.useReducer(graphReducer, {
    value:
      zoomContext?.path && zoomContext?.path.length > 0 ? "zoomed" : "root",
    currentZoomContext: zoomContext,
    currentMargins: margins,
  });

  React.useEffect(() => {
    dispatch({ type: "rendered" });
  });*/

  React.useEffect(() => {
    /*
    if (
      state.currentZoomContext?.graphFrame.row ===
        zoomContext?.graphFrame.row &&
      state.currentZoomContext?.graphFrame.col ===
        zoomContext?.graphFrame.col &&
      state.currentZoomContext?.graphFrame.numRows ===
        zoomContext?.graphFrame.numRows &&
      state.currentZoomContext?.graphFrame.numCols ===
        zoomContext?.graphFrame.numCols
    ) {
      dispatch({
        type: "changePath",
        nextZoomContext: zoomContext,
      });
    } else {
      dispatch({
        type: "changeZoom",
        nextZoomContext: zoomContext,
        nextMargins: margins,
      });
    }*/
    /*
    dispatch({
      type: "changeZoom",
      nextZoomContext: zoomContext,
      nextMargins: margins,

    });
    */
  }, [zoomContext, margins])

  const { windowWidth, windowHeight } = useWindowSize()
  const scrollRef = React.useRef<HTMLDivElement>(null)
  const [scrollRowOffset, setScrollRowOffset] = React.useState(0)

  const [isScrolling, setIsScrolling] = React.useState(false)
  const [isAnimating, setIsAnimating] = React.useState(false)
  const [isNeedsMore, setNeedsMore] = React.useState(false)

  React.useEffect(() => {
    if (onScroll) {
      onScroll(scrollRowOffset)
    }
  }, [scrollRowOffset, onScroll])

  console.log('render ', isScrolling, isAnimating, isNeedsMore)
  const dataRef = React.useRef(data)

  if (!isScrolling && !isAnimating) {
    console.log('REPLACING DATA')
    dataRef.current = data
  }

  const [map, dataToFrameMap] = React.useMemo(() => {
    console.log('generating data')
    const data = dataRef.current

    const dataToFrameMap: Dictionary<GraphFrame> = {}
    const map = Array<Array<MapNode>>()
    map.push(Array(columns).fill(null))
    let lastRow = 0

    data.forEach((data) => {
      const nodeLayout = layout(data)
      const nodeSize = size(data)
      const { row, col } = placeNode(map, nodeLayout, lastRow)

      lastRow = row
      const graphFrame = {
        row: row,
        col: col,
        numRows: nodeSize.numRows,
        numCols: nodeSize.numCols,
      }

      dataToFrameMap[keyPath(data)] = graphFrame
    })

    return [map, dataToFrameMap]
  }, [dataRef.current, layout, factory, columns])

  React.useEffect(() => {
    const data = dataRef.current
    if (zoomContext && zoomContext.path.length === 1) {
      /*
      const nextPredicate = (dataEntry: unknown) => {
        const graphFrame = dataToFrameMap[keyPath(dataEntry)];
        return (
          graphFrame.row > zoomContext.graphFrame.row ||
          (graphFrame.row === zoomContext.graphFrame.row &&
            graphFrame.col > zoomContext.graphFrame.col)
        );
      };

      const prevPredicate = (dataEntry: unknown) => {
        const graphFrame = dataToFrameMap[keyPath(dataEntry)];
        return (
          graphFrame.row < zoomContext.graphFrame.row ||
          (graphFrame.row === zoomContext.graphFrame.row &&
            graphFrame.col < zoomContext.graphFrame.col)
        );
      };*/

      const onKeyDownEvent = (e: KeyboardEvent) => {
        //const nextIndex = binarySearch(data, nextPredicate);

        const currentIndex = data.findIndex((dataEntry) => {
          return keyPath(dataEntry) === zoomContext.path[0]
        })
        const nextIndex = Math.min(currentIndex + 1, data.length)
        const prevIndex = Math.max(currentIndex - 1, 0)

        const nextData = data[nextIndex]
        const prevData = data[prevIndex]

        //const prevIndex = binarySearch(data, prevPredicate);

        switch (e.keyCode) {
          case 37:
            console.log('Left key is pressed.')
            onZoomContextChange({
              graphFrame: dataToFrameMap[keyPath(prevData)],
              path: prevData.path,
            })
            setScrollRowOffset(dataToFrameMap[keyPath(prevData)].row)
            break
          case 38:
            console.log('Up key is pressed.')
            break
          case 39:
            console.log('Right key is pressed.')
            onZoomContextChange({
              graphFrame: dataToFrameMap[keyPath(nextData)],
              path: nextData.path,
            })
            setScrollRowOffset(dataToFrameMap[keyPath(prevData)].row)
            break
          case 40:
            console.log('Down key is pressed.')
            break
        }
      }
      window.addEventListener('keydown', onKeyDownEvent)

      return () => {
        window.removeEventListener('keydown', onKeyDownEvent)
      }
    }
  })

  const defaultMarginsRoot = { left: 0, right: 0, top: 0, bottom: 0 }
  const extraDefaultMargins = defaultMarginsRoot
  /*  const defaultMargins1Level = { left: 50, right: 50, top: 50, bottom: 50 };
  const defaultMargins2Levels = {
    left: 150,
    right: 150,
    top: 150,
    bottom: 150,
  };

  const extraDefaultMargins = state.currentZoomContext
    ? state.currentZoomContext.path.length === 1
      ? defaultMargins1Level
      : defaultMargins2Levels
    : defaultMarginsRoot;*/

  const currentMargins = margins
    ? {
        left: extraDefaultMargins.left + margins.left,
        right: extraDefaultMargins.right + margins.right,
        top: extraDefaultMargins.top + margins.top,
        bottom: extraDefaultMargins.bottom + margins.bottom,
      }
    : extraDefaultMargins

  /*
  const nextMargins = state.nextMargins
    ? {
        left: extraDefaultMargins.left + state.nextMargins.left,
        right: extraDefaultMargins.right + state.nextMargins.right,
        top: extraDefaultMargins.top + state.nextMargins.top,
        bottom: extraDefaultMargins.bottom + state.nextMargins.bottom,
      }
    : extraDefaultMargins;
    */

  const windowAspectRatio = windowWidth / (windowHeight * aspectRatio)
  const rows = columns / windowAspectRatio

  const visibleWidth = currentMargins
    ? windowWidth - (currentMargins.left + currentMargins.right)
    : windowWidth
  //const nextVisibleWidth = nextMargins
  //  ? windowWidth - (nextMargins.left + nextMargins.right)
  //  : windowWidth;
  const visibleHeight = currentMargins
    ? windowHeight - (currentMargins.top + currentMargins.bottom)
    : windowHeight

  const horizontalMarginPercentage = currentMargins
    ? (currentMargins.left + currentMargins.right) / windowWidth
    : 0
  const verticalMarginPercentage = currentMargins
    ? (currentMargins.top + currentMargins.bottom) / windowHeight
    : 0

  const visibleAspectRatio = visibleWidth / (visibleHeight * aspectRatio)

  const zoomFrame = zoomContext?.graphFrame

  const colSize = windowWidth / columns
  const rowSize = colSize / aspectRatio

  const marginLeftRowOffset = currentMargins ? currentMargins.left / colSize : 0
  const marginTopRowOffset = currentMargins ? currentMargins.top / rowSize : 0

  const actualColSize = visibleWidth / columns
  //const nextColSize = nextVisibleWidth / columns;

  const actualRowSize = actualColSize / aspectRatio
  //const nextRowSize = nextColSize / aspectRatio;

  const windowRowStart = scrollRowOffset
  const windowRows = windowHeight / actualRowSize
  //const windowRowsNext = windowHeight / nextRowSize;

  //const nextScrollOffset = scrollRowOffset * nextRowSize;
  const currentScrollOffset = scrollRowOffset * actualRowSize

  //const scrollDiff = nextScrollOffset - currentScrollOffset;
  //const scrollDiffRows = scrollDiff / rowSize;

  const center = zoomFrame ? true : false

  //const theScrollRow = scrollRef.current
  //  ? scrollRef.current.scrollTop / actualRowSize
  //  : 0;

  const nodeRow = zoomFrame ? zoomFrame.row : scrollRowOffset
  const nodeCol = zoomFrame ? zoomFrame.col : 0
  const nodeCols = zoomFrame ? zoomFrame.numCols : columns
  const nodeRows = zoomFrame ? zoomFrame.numRows : nodeCols / visibleAspectRatio
  const nodeAspectRatio = nodeCols / nodeRows

  let zoomRow: number,
    zoomCol: number,
    zoomScale: number,
    visibleRows: number,
    visibleCols: number

  if (!zoomFrame || visibleAspectRatio <= nodeAspectRatio) {
    visibleRows = zoomFrame ? nodeCols / visibleAspectRatio : nodeRows
    visibleCols = nodeCols
    visibleCols +=
      visibleCols *
      (horizontalMarginPercentage / (1 - horizontalMarginPercentage))

    // node is width constrained
    zoomRow = nodeRow // - margin top
    if (center) {
      zoomRow -= (visibleRows - nodeRows) / 2
    }
    zoomCol = nodeCol
    zoomScale = columns / visibleCols
  } else {
    visibleRows = nodeRows
    visibleCols = zoomFrame ? nodeRows * visibleAspectRatio : nodeCols
    visibleRows +=
      visibleRows * (verticalMarginPercentage / (1 - verticalMarginPercentage))

    zoomCol = nodeCol
    if (center) {
      zoomCol -= (visibleCols - nodeCols) / 2
    }
    zoomRow = nodeRow
    zoomScale = rows / visibleRows
  }

  //if (scrollRef.current) {
  //  scrollRef.current.scrollTop = nextScrollOffset;
  // }

  //const currentRowOffset =
  //  scrollRef.current && zoomFrame
  //   ? scrollRef.current.scrollTop / rowSize
  //   : scrollDiffRows;

  React.useEffect(() => {
    if (isNeedsMore) {
      if (loadMore) {
        loadMore()
      }
    }
  }, [isNeedsMore])

  React.useEffect(() => {
    setNeedsMore(false)
  }, [map.length])

  React.useEffect(() => {
    if (scrollRowOffset > map.length - windowRows) {
      console.log('set needs more')
      setNeedsMore(true)
    }
  }, [scrollRowOffset, map.length])

  React.useEffect(() => {
    if (isAnimating) {
      console.log('animating scroll')
      setIsScrolling(true)
    } else {
      const timeout = setTimeout(() => {
        console.log('animating scroll ended')
        setIsScrolling(false)
      }, 100)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isAnimating])

  const springRef = useSpringRef()

  const springProps = useSpring({
    ref: springRef,
    to: {
      currentRowOffset: 0 ?? 0,
      zoomConfig: [
        currentMargins.left,
        currentMargins.top,
        currentMargins.right,
        currentMargins.bottom,
        zoomRow,
        zoomCol,
        visibleRows,
        visibleCols,
      ],
    },
  })

  const startIndexPredicate = (value: number) => (dataEntry: unknown) => {
    const graphFrame = dataToFrameMap[keyPath(dataEntry)]
    return graphFrame.row + graphFrame.numRows >= value
  }

  const endIndexPredicate = (value: number) => (dataEntry: unknown) => {
    const graphFrame = dataToFrameMap[keyPath(dataEntry)]
    return graphFrame.row > value
  }

  /* console.log(
    "spring zoom ",
    springProps.zoomConfig.get()[4],
    " scroll ",
    zoomRow
  ); */

  const [startIndex, setStartIndex] = React.useState(0)
  const [endIndex, setEndIndex] = React.useState(0)

  React.useEffect(() => {
    const startIndex = binarySearch(
      dataRef.current,
      startIndexPredicate(windowRowStart - extraRows)
    )
    const endIndex = binarySearch(
      dataRef.current,
      endIndexPredicate(windowRowStart + windowRows + extraRows)
    )

    if (isAnimating) {
      setStartIndex((prevStartIndex) => Math.min(prevStartIndex, startIndex))
      setEndIndex((prevEndIndex) => Math.max(prevEndIndex, endIndex))
    } else {
      setStartIndex(startIndex)
      setEndIndex(endIndex)
    }
  }, [dataRef.current, windowRowStart, windowRows, isAnimating])

  const nodes = React.useMemo(() => {
    return dataRef.current.slice(startIndex, endIndex).map((data) => {
      const nodeLayout = layout(data)
      const graphFrame = dataToFrameMap[keyPath(data)]
      const nodeSize = size(data)

      return factory(data, graphFrame, nodeLayout, nodeSize)
    })
  }, [dataRef.current, keyPath, factory, dataToFrameMap, startIndex, endIndex])

  // We don't want to switch the nodes during zooming, so the list being used in ref.
  const currentNodesRef = React.useRef(nodes)
  currentNodesRef.current = nodes // Maybe use this as optimization, not sure if its neccasary

  const fullHeight =
    map.length * actualRowSize +
    (currentMargins ? currentMargins.top + currentMargins.bottom : 0)

  const dimensionsForLayout = React.useCallback(
    (layout: GraphLayout) => {
      //const scale = layout.map[0].length / layout.childSize.numCols;

      let extraScale = 1
      if (layout.childSize.numRows === 1) {
        extraScale = 2
      }

      const colWidth = windowWidth / columns
      const rowHeight = colWidth / aspectRatio
      return {
        width: layout.map[0].length * colWidth * extraScale, // * scale,
        height: layout.map.length * rowHeight * extraScale, // * scale,
      }
    },
    [columns, aspectRatio, windowWidth]
  )

  /*
  const [springProps, api] = useSpring(() => {
    return {
      currentRowOffset: 0 ?? 0,
      zoomConfig: [
        currentMargins.left,
        currentMargins.top,
        currentMargins.right,
        currentMargins.bottom,
        zoomRow,
        zoomCol,
        visibleRows,
        visibleCols,
      ],
    };
  });*/

  React.useEffect(() => {
    if (isAnimating) {
      const timeout = setTimeout(() => {
        setIsAnimating(false)
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isAnimating])

  const animatedRef = React.useRef(false)

  React.useEffect(() => {
    if (!animatedRef.current) {
      animatedRef.current = true
    } else {
      setIsAnimating(true)
    }

    console.log('fractral isAnimating start')

    /* console.log("Animating", [
      currentMargins.left,
      currentMargins.top,
      currentMargins.right,
      currentMargins.bottom,
      zoomRow,
      zoomCol,
      visibleRows,
      visibleCols,
    ]);*/

    springRef.start({
      currentRowOffset: 0 ?? 0,
      zoomConfig: [
        currentMargins.left,
        currentMargins.top,
        currentMargins.right,
        currentMargins.bottom,
        zoomRow,
        zoomCol,
        visibleRows,
        visibleCols,
      ],
      onStart: () => {
        console.log('fractral isAnimating true')
        setIsAnimating(true)
      },
      onRest: () => {
        console.log('fractral isAnimating false')
        setIsAnimating(false)
      },
    })
  }, [
    currentMargins.left,
    currentMargins.top,
    currentMargins.right,
    currentMargins.bottom,
    zoomRow,
    zoomCol,
    visibleRows,
    visibleCols,
  ])

  React.useEffect(() => {
    if (!zoomFrame) {
      const onWheel = (e: WheelEvent) => {
        setScrollRowOffset((prevOffset) => {
          const newScrollOffset = Math.max(
            0,
            Math.min(
              map.length - windowRows / 2.0,
              prevOffset + e.deltaY / actualRowSize
            )
          )
          console.log(newScrollOffset)
          return newScrollOffset
        })
      }
      if (scrollRef.current) {
        scrollRef.current.addEventListener('wheel', onWheel, true)
        return () => {
          if (scrollRef.current) {
            scrollRef.current.removeEventListener('wheel', onWheel, true)
          }
        }
      }
    }
  }, [zoomFrame, actualRowSize, map.length])

  const fractalGraphContext = React.useMemo(() => {
    return {
      dimensionsForLayout,
      factory,
      zoomContext: zoomContext,
      isScrolling: isScrolling || isAnimating,

      windowWidth: windowWidth,
      windowHeight: windowHeight,
      aspectRatio: aspectRatio,
      columns: columns,
      currentRowOffset: 0,
      scrollRowOffset: 0,
      numDataEntries: data.length,

      springPropsRowOffset: springProps.currentRowOffset,
      springPropsZoomConfig: springProps.zoomConfig,
    }
  }, [
    data.length,
    dimensionsForLayout,
    zoomContext,
    isScrolling,
    isAnimating,
    windowWidth,
    windowHeight,
    aspectRatio,
    columns,
    springProps.currentRowOffset,
    springProps.zoomConfig,
  ])

  return (
    <FractalGraphContext.Provider value={fractalGraphContext}>
      <div
        ref={scrollRef}
        className="disable-scrollbar"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflow: 'hidden hidden',
        }}
      >
        <CustomDragLayer />
        <div
          id={'fractal-graph-root'}
          style={{
            position: 'fixed',
            top: 0,
            width: '100%',
            height: fullHeight,
          }}
        >
          {nodes}
        </div>

        <div
          id={'fractal-graph-root2'}
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            height: fullHeight,
            pointerEvents: 'none',
          }}
        >
          {/*
          <div
            style={{
              position: "absolute",
              width: "100%",
              top:
                map.length * actualRowSize +
                (currentMargins ? currentMargins.top : 0),
              opacity: state.value === "root" ? 1.0 : 0.0,
              pointerEvents: "auto",
            }}
          >
            {footer}
          </div>
          */}
        </div>
      </div>
    </FractalGraphContext.Provider>
  )
}

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    }
  }

  let { x, y } = currentOffset

  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform,
  }
}

function ThumbnailView({
  image,
  showClip,
}: {
  image: string
  showClip: boolean
}) {
  return (
    <div
      className={
        showClip ? 'node-thumbnail-img-clipped' : 'node-thumbnail-img-noclip'
      }
      style={{
        backgroundColor: '#DDDDDD',
        //width: 'calc((3/18) * 100vw)',
        //height: 'calc((9/16) * (3/18) * 100vw)',
        width: '143px',
        height: '80px',
      }}
    >
      <AspectRatio ratio={9 / 16} width="100%" className="node-thumbnail">
        <img
          className={'node-thumbnail-img '}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          src={image}
        />
      </AspectRatio>
    </div>
  )
}

export const CustomDragLayer = () => {
  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }))

  function renderItem() {
    switch (itemType) {
      case 'vit':
        return <ThumbnailView image={item.image} showClip={true} />
    }
  }

  if (!isDragging) {
    return null
  }
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        {renderItem()}
      </div>
    </div>
  )
}

function placeNode(
  map: MapNode[][],
  insertLayout: GraphLayout,
  nextRow: number
): { row: number; col: number } {
  let searchRow = nextRow
  const gridColumns = map[0].length

  function mappingAt(row: number, col: number): GraphLayoutEnum {
    if (row < 0 || col < 0 || col >= gridColumns) {
      return GraphLayoutEnum.RESERVED
    }

    if (row >= map.length) {
      return GraphLayoutEnum.EMPTY
    }

    return map[row][col] ?? GraphLayoutEnum.EMPTY
  }

  function doesLayoutFit(mapRow: number, mapCol: number): boolean {
    for (let row = 0; row < insertLayout.map.length; row++) {
      for (let col = 0; col < insertLayout.map[row].length; col++) {
        const layoutPlacement = insertLayout.map[row][col]
        if (mapRow + row > map.length - 1) {
          return true
        }

        const mapPlacement = mappingAt(row + mapRow, col + mapCol)
        if (
          layoutPlacement === GraphLayoutEnum.NODE ||
          layoutPlacement === GraphLayoutEnum.RESERVED ||
          layoutPlacement === GraphLayoutEnum.INFO
        ) {
          if (mapPlacement !== GraphLayoutEnum.EMPTY) {
            return false
          }
        }

        if (layoutPlacement === GraphLayoutEnum.CHILD) {
          if (
            mapPlacement === GraphLayoutEnum.NODE ||
            mapPlacement === GraphLayoutEnum.RESERVED ||
            mapPlacement === GraphLayoutEnum.INFO
          ) {
            return false
          }
        }
      }
    }

    return true
  }

  function fillLayoutOnMap(mapRow: number, mapCol: number): void {
    const gridColumns = map[0].length
    for (let row = 0; row < insertLayout.map.length; row++) {
      for (let col = 0; col < insertLayout.map[row].length; col++) {
        const layoutPlacement = insertLayout.map[row][col]
        const currentMapRow = mapRow + row
        const currentMapCol = mapCol + col

        if (currentMapRow >= map.length) {
          map.push(Array(gridColumns).fill(null))
        }

        map[currentMapRow][currentMapCol] = layoutPlacement
      }
    }
  }

  /* eslint-disable no-constant-condition */
  while (true) {
    for (let col = 0; col < map[nextRow].length; col++) {
      if (doesLayoutFit(searchRow, col)) {
        fillLayoutOnMap(searchRow, col)
        return { row: searchRow, col: col }
      }
    }
    searchRow++
  }
}
