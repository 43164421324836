import React, { useEffect } from 'react'
import './vits-app.css'

import { useHistory } from 'react-router'
import EditIcon from '@material-ui/icons/Edit'
import Tooltip from '@material-ui/core/Tooltip'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { FractalGraphView } from './fractal-graph-view'
//import { DraggableList } from './draggable-list'
import { useDrag, ConnectDragSource } from 'react-dnd'
import { useDrop } from 'react-dnd'
import { DragPreviewImage } from 'react-dnd'
import {
  splinksiLayout,
  scaledGraphFrame,
  interpolateChildFrames,
  transformForFrame,
} from './utils'
import {
  FractalGraphContext,
  GraphFrame,
  GraphLayout,
  GraphMargins,
  GraphSize,
  GraphZoomConfig,
  GraphZoomContext,
} from './types'
import {
  useSpring,
  useTransition,
  animated,
  Spring,
  SpringValue,
  to,
} from 'react-spring'
import { useGraphNode, usePrevious, useLogIfChanged } from './hooks'
import { FractalGraphPortal } from './fractral-graph-portal'
import AspectRatio from 'r-aspect-ratio'
import ShareIcon from '@material-ui/icons/Share'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { LeftArrowIcon } from './icons/LeftArrowIcon'
import { RightArrowIcon } from './icons/RightArrowIcon'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { IconButton } from '@material-ui/core'
import { MemoLightPlayer } from '../components/views/player/LightPlayer'
import { PlayerObserverContext } from '../components/views/player/PlayerObserverContext'
import { Link } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import NewVitBuilder from '../components/views/builder/NewVitBuilder'

import Menu, { MenuProps } from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LinkIcon from '@material-ui/icons/Link'
import YouTube from 'react-youtube'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

const numChildren = 30
const beginningChildren = 7
const endChildren = 6
const numPages = 3

const marginWidth = 540
const maxRightMargin = 540
const maxBottomMargin = 230

const minTopMargin = 30
const minRightMargin = 30
const minBottomMargin = 30
const numInitialElements = 20

const footerMargin = 50

//const config = "h_1.0,ar_16:9,c_pad,b_auto";
//const config = "h_1.0,ar_16:9,c_scale,e_blur:1000,o_70";
//const config = "ar_16:9,c_fill";
//w_300,h_300,c_pad,b_auto,e_gradient_fade:symmetric_pad/dog.jpg

const config = (id: string) => {
  const blurConfig = `u_${id},h_1.0,ar_16:9,c_scale,e_blur:1000,o_70`
  const autoBg = 'h_1.0,ar_16:9,c_pad,b_auto'
  const autoBgFade = 'h_1.0,ar_16:9,c_pad,b_auto,e_gradient_fade:symmetric_pad'
  return `https://res.cloudinary.com/de7ts8nlf/image/upload/${autoBg}/${id}.jpg`
}
const randomImages = [
  config('zdeooku4uzrgtrckd54x'),
  config('l5ss2vuwmeltibfzhoy0'),
  config('zkms80pcfp7lrgpaxxoa'),
  config('fwe4jkwkcytxzdllom48'),
  config('gg7kep6oeaewv1r5orw5'),
  config('gd7bnnjjo96aynsumxsa'),
  config('kckawsknlhe6fjkljsaw'),
  config('yz07ecenmjmcp7kao3f8'),
  config('svveq4jbxzznryrbzzba'),
  config('rtufewfsplrl0xhaikqy'),
  config('lyvdue3aiszueycijfuh'),
  config('l5m89yrmbsi4tldgrhon'),
  config('juufoq2r6agz6tlyt9me'),
  /*

    config +
    "/v1613950490/yz07ecenmjmcp7kao3f8.jpg",
  "https://res.cloudinary.com/de7ts8nlf/image/upload/" +
    config +
    "/v1613950471/svveq4jbxzznryrbzzba.jpg",
  "https://res.cloudinary.com/de7ts8nlf/image/upload/" +
    config +
    "/v1613950475/rtufewfsplrl0xhaikqy.jpg",
  "https://res.cloudinary.com/de7ts8nlf/image/upload/" +
    config +
    "/v1613950456/lyvdue3aiszueycijfuh.jpg",
  "https://res.cloudinary.com/de7ts8nlf/image/upload/" +
    config +
    "/v1613525787/l5m89yrmbsi4tldgrhon.jpg",
  "https://res.cloudinary.com/de7ts8nlf/image/upload/" +
    config +
    "/v1609041331/juufoq2r6agz6tlyt9me.jpg",*/
]

const randomCaptions = [
  'How do you measure climate change and its effects #climatechange #science #action',
  'earth seen from Mars',
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eu mattis urna. Vivamus feugiat viverra bibendum. Suspendisse potenti. Aliquam erat volutpat. Ut vitae mattis arcu.',
]

function posMod(val: number, n: number): number {
  return ((val % n) + n) % n
}

type PlayerAPI = {
  seekIndex: (index: number, offset: number) => void
  pause: () => void
}

type DataType = {
  key: string
  vitId: string | undefined
  vitlineId: string | undefined
  path: string[]
  title: string
  date: string
  user: string
  image: string
  size: 'small' | 'large'
  children: DataType[][]
  playerSequence: any[]
  dataObj: unknown
  saved: boolean
  shareUrl: string
}

type API = {
  attach: (id: string) => void
  save: () => void
  unsave: () => void
  delete: () => void
  edit: () => void
}

export const FractalNode = React.memo(TopLevelNode)

function TopLevelNode({
  data,
  api,
  path,
  graphFrames,
  graphFrameIndex,
  layout,
  graphSize,
  onZoomOut,
  onZoomChange,
  zIndex,
  children,

  forceHover = false,
  childFactory = undefined,
  existingKeySet = new Set(),
}: {
  data: DataType | undefined
  api: API | undefined
  path: string[]
  graphFrames: GraphFrame | GraphFrame[]
  graphFrameIndex: number
  layout: GraphLayout
  graphSize: GraphSize
  onZoomOut: () => void
  onZoomChange: (zoomContextStack: GraphZoomContext[]) => void
  zIndex?: number
  isParentSelected?: boolean
  children?: (props: {
    isSelected: boolean
    nodeProps: React.CSSProperties
    onCardZoom: () => void
  }) => React.ReactNode
  forceHover?: boolean
  childFactory?: (
    partialData: DataType,
    rendererFn: (loadedData: { data: DataType }) => JSX.Element
  ) => JSX.Element
  existingKeySet?: Set<String>
}) {
  const [
    containerProps,
    containerSpringProps,
    nodeProps,
    infoProps,
    allChildFrames,
    isSelected,
    isSomeChildSelected,
    isParentSelected,
    isChildSelected,
    isSomeParentSelected,
    levelDelta,
    isAnimating,
    currentZoomContext,
  ] = useGraphNode(path, graphFrames, graphFrameIndex, layout, graphSize)

  const history = useHistory()

  const selectedZoomKey = currentZoomContext
    ? currentZoomContext.path[currentZoomContext.path.length - 1]
    : ''
  const isAttachSelected = currentZoomContext
    ? selectedZoomKey.startsWith('attach_vit')
    : false
  const isAttachNode = path[path.length - 1].startsWith('attach_vit')

  const playerRef = React.useRef<PlayerAPI | undefined>(undefined)
  const containerRef = React.useRef<HTMLDivElement>(null)

  const {
    windowWidth,
    windowHeight,
    aspectRatio,
    columns,
    currentRowOffset,
    isScrolling,

    springPropsRowOffset,
    springPropsZoomConfig,
    numDataEntries = 0,

    factory,
  } = React.useContext(FractalGraphContext)

  const [pageIndex, setPageIndex] = React.useState(0)

  useEffect(() => {
    if (data?.vitId) {
      setPageIndex(2)
    }
  }, [data])

  const isSmallLayout = !!data?.vitId

  const scrollDelta = React.useRef(0)
  //const currentlyAnimatingCycle = React.useRef(false);

  const sourceVideo = data?.vitId && data?.playerSequence[0].source.videoId
  const showSource = data?.vitId && pageIndex === 0 ? true : false

  const [isPlaying, setPlaying] = React.useState(false)
  const [isFinished, setFinished] = React.useState(false)

  const [progress, setProgress] = React.useState(0)
  const [hoverChildIndex, setHoverChildIndex] = React.useState<
    undefined | number
  >(undefined)
  const [isHover, setHover] = React.useState(false)

  const childFrames = React.useMemo(() => {
    if (pageIndex === 2) {
      return allChildFrames.slice(0, -1)
    }
    return allChildFrames
  }, [allChildFrames, pageIndex])

  const maxChildren = childFrames.length

  const filteredChildren = React.useMemo(() => {
    if (data) {
      return [
        data.children[0],
        data.children[1].filter((dataEntry) => {
          if (dataEntry.vitId) {
            return !existingKeySet.has(dataEntry.vitId)
          } else if (dataEntry.vitlineId) {
            return !existingKeySet.has(dataEntry.vitlineId)
          }
        }),
        data.children[2],
      ]
    }
    return [[], [], []]
  }, [data, existingKeySet])

  const defaultItems: DataType[] = filteredChildren[pageIndex] ?? []
  const defaultTimelineItems: DataType[] =
    filteredChildren && filteredChildren.length >= 2 ? filteredChildren[0] : []

  const childExistingKeySet = React.useMemo(() => {
    const newKeySet = new Set(existingKeySet)
    defaultItems.forEach((item) => {
      const id = item.vitlineId || item.vitId
      if (id && !newKeySet.has(id)) {
        newKeySet.add(id)
      }
    })
    return newKeySet
  }, [existingKeySet, defaultItems])

  const [cycleIndex, setCycleIndex] = React.useState(
    Math.floor((maxChildren - 1) / 2)
  )
  const [seekIndex, setSeekIndex] = React.useState<number | undefined>(
    undefined
  )

  const prevPage = usePrevious(pageIndex)
  const prevItemsLength = usePrevious(defaultItems.length)

  React.useEffect(() => {
    //if (defaultItems.length > 0) {
    if (pageIndex === 2) {
      if (defaultItems.length > maxChildren) {
        setCycleIndex(defaultItems.length - maxChildren)
      }
    } else if (
      prevPage !== pageIndex ||
      prevItemsLength !== defaultItems.length
    ) {
      setCycleIndex(
        -Math.max(0, Math.floor((maxChildren - defaultItems.length) / 2))
      )
    }
    //}
  }, [defaultItems, pageIndex])

  /*
  const defaultItems = React.useMemo(() => {
    const defaultItems = Array<ItemNode>(numChildren)
    for (let index = 0; index < numChildren; index++) {
      const item = index + pageIndex * numChildren
      const newPath = [...path, `${item}`]
      const pathKey = newPath.join('/')

      defaultItems[index] = {
        path: newPath,
        key: pathKey,
        data: `${item}`,
      }
    }
    return defaultItems
  }, [pageIndex])*/

  /* const [cycleIndex, setCycleIndex] = React.useState(
    -Math.max(0, Math.round((maxChildren - defaultItems.length) / 2))
  )*/

  React.useEffect(() => {
    if (currentZoomContext && isChildSelected && !isAttachSelected) {
      const onKeyDownEvent = (e: KeyboardEvent) => {
        //const nextIndex = binarySearch(data, nextPredicate);

        const currentChildPath =
          currentZoomContext.path[currentZoomContext.path.length - 1]
        const currentIndex = parseInt(currentChildPath)

        const nextIndex = Math.min(currentIndex + 1, defaultItems.length - 1)
        const prevIndex = Math.max(currentIndex - 1, 0)

        //const prevIndex = binarySearch(data, prevPredicate);

        switch (e.keyCode) {
          case 37:
            console.log('Left key is pressed.')

            if (cycleIndex > 0) {
              onZoomChange([
                zoomContext,
                {
                  graphFrame: childFrames[currentIndex - cycleIndex],
                  path: [...path, `${prevIndex}`],
                },
              ])

              //isAnimatingScrollRef.current = true;
              setCycleIndex((cycleIndex) => cycleIndex - 1)
            } else {
              onZoomChange([
                zoomContext,
                {
                  graphFrame: childFrames[prevIndex - cycleIndex],
                  path: [...path, `${prevIndex}`],
                },
              ])
            }

            break
          case 38:
            console.log('Up key is pressed.')
            break
          case 39:
            console.log('Right key is pressed.')

            if (cycleIndex < defaultItems.length - maxChildren) {
              onZoomChange([
                zoomContext,
                {
                  graphFrame: childFrames[currentIndex - cycleIndex],
                  path: [...path, `${nextIndex}`],
                },
              ])

              //isAnimatingScrollRef.current = true;
              setCycleIndex((cycleIndex) => cycleIndex + 1)
            } else {
              onZoomChange([
                zoomContext,
                {
                  graphFrame: childFrames[nextIndex - cycleIndex],
                  path: [...path, `${nextIndex}`],
                },
              ])
            }

            break
          case 40:
            console.log('Down key is pressed.')
            break
        }
      }

      window.addEventListener('keydown', onKeyDownEvent)

      return () => {
        window.removeEventListener('keydown', onKeyDownEvent)
      }
    }
  })

  React.useEffect(() => {
    if (isSelected && isHover) {
      const onWheel = (e: WheelEvent) => {
        scrollDelta.current += e.deltaY
        console.log('scrollDelta', scrollDelta.current)

        if (Math.abs(scrollDelta.current) > 200) {
          setCycleIndex((prevIndex) => {
            console.log(
              'setting cycle index from ',
              prevIndex,
              ' to ',
              prevIndex + Math.round(scrollDelta.current / 200),
              ' between 0 and ',
              Math.abs(defaultItems.length - maxChildren)
            )

            const deltaAvailable = defaultItems.length - maxChildren
            return deltaAvailable > 0
              ? Math.max(
                  0,
                  Math.min(
                    deltaAvailable,
                    prevIndex + Math.round(scrollDelta.current / 200)
                  )
                )
              : Math.min(
                  0,
                  Math.max(
                    deltaAvailable,
                    prevIndex + Math.round(scrollDelta.current / 200)
                  )
                )
          })
          scrollDelta.current = 0
        }
      }

      window.addEventListener('wheel', onWheel, true)
      return () => {
        window.removeEventListener('wheel', onWheel, true)
      }
    }
  }, [isSelected, isHover, defaultItems])

  const prevProgress = usePrevious(progress) ?? progress
  const progressChanged = Math.floor(progress) !== Math.floor(prevProgress)

  React.useEffect(() => {
    if (progressChanged) {
      if (progress > cycleIndex + maxChildren - 1) {
        setCycleIndex(
          Math.min(defaultItems.length - maxChildren, cycleIndex + maxChildren)
        )
      } else if (progress < cycleIndex) {
        setCycleIndex(Math.max(0, cycleIndex - maxChildren))
      }
    }
  }, [progress, cycleIndex, progressChanged])

  const currentGraphFrame = React.useMemo(() => {
    return Array.isArray(graphFrames)
      ? interpolateChildFrames(graphFrames, graphFrameIndex)
      : graphFrames
  }, [graphFrames, graphFrameIndex])

  const zoomContext = React.useMemo(() => {
    return { path, graphFrame: currentGraphFrame }
  }, [path, currentGraphFrame])

  const childLayout = React.useMemo(() => {
    return splinksiLayout(5, 1)
  }, [])

  const childSize = React.useMemo(() => {
    return { numRows: 5, numCols: 5 }
  }, [])

  const itemList = React.useMemo(() => {
    const list: Array<DataType> = []

    for (let index = 0; index < maxChildren; index++) {
      if (index + cycleIndex < 0) {
        continue
      }
      if (index + cycleIndex >= defaultItems.length) {
        break
      }
      list.push(defaultItems[index + cycleIndex])
    }

    return list
  }, [defaultItems, cycleIndex, maxChildren])

  const itemsRef = React.useRef<DataType[]>([])
  if (!isAnimating && !isScrolling) {
    if (
      isSomeChildSelected ||
      isSelected ||
      (isHover && (isSelected || (path.length === 1 && !currentZoomContext)))
    ) {
      itemsRef.current = itemList
    } else {
      itemsRef.current = []
    }
  }

  const transitions = useTransition(itemsRef.current, {
    key: (item: DataType) => {
      if (item === undefined) {
        debugger
        console.log('NULL ITEM', itemsRef.current)
      }
      return item.key
    },
    from: { opacity: '0' },
    enter: { opacity: '1' },
    leave: { opacity: '0' },
  })

  const onMouseEnter = React.useCallback(() => {
    setHover(true)
  }, [])

  const onMouseLeave = React.useCallback(() => {
    setHover(false)
  }, [])

  //isSelected || path.length === 1 || levelDelta === 0
  //isSelected || path.length === 1 || levelDelta === 0 || !isSmallLayout  ? 1 : 0,
  //!isSmallLayout || isSelected || levelDelta === 0
  //const [topLevelProps];

  // TODO FIX
  /*
    const [, setScrollSpringProps] = useSpring(() => ({
    scrollLeft: 0,
  })) as any;

  
  if (timelineScrollRef.current) {
    const scrollContentWidth = timelineScrollRef.current.scrollWidth;
    const widthPerElement = scrollContentWidth / defaultItems.length;

    setScrollSpringProps({
      scrollLeft: widthPerElement * cycleIndex,
      onFrame: ({ scrollLeft }: { scrollLeft: number }) => {
        if (timelineScrollRef.current) {
          timelineScrollRef.current.scrollLeft = scrollLeft;
        }
      },
    });
  }*/

  const onZoomChangeCallback = React.useCallback(
    (zoomContextStack) => {
      return onZoomChange([zoomContext, ...zoomContextStack])
    },
    [onZoomChange, zoomContext]
  )

  React.useEffect(() => {
    if (!isSelected && isPlaying) {
      if (playerRef.current) {
        playerRef.current.pause()
      }
    }
  }, [isSelected, isPlaying])

  /*const outerZoomClass =
    levelDelta === 0
      ? isSelected
        ? 'zoomed'
        : 'zoom-out'
      : levelDelta > 0
      ? 'zoom-in'
      : 'zoom-out'*/
  const outerZoomClass =
    currentZoomContext &&
    currentZoomContext.path &&
    currentZoomContext.path.length > 0 &&
    (numDataEntries > 1 || currentZoomContext.path.length > 1)
      ? 'zoom-out'
      : 'zoomed'

  const innerZoomClass = isSelected
    ? 'zoomed'
    : levelDelta <= 0
    ? 'zoom-change'
    : 'zoom-in'

  const infoBoxZoomClass =
    isParentSelected && isSmallLayout && path.length > 1 && isHover
      ? 'zoomed'
      : innerZoomClass

  const timelineLeftIconClass =
    cycleIndex <= 0 ? 'inactive-button' : 'active-button'

  const timelineRightIconClass =
    cycleIndex >= defaultItems.length - maxChildren
      ? 'inactive-button'
      : 'active-button'

  const timelinePageSize = Math.round(maxChildren / 2)

  const isSameScale =
    currentZoomContext && currentZoomContext.referenceFrame
      ? currentZoomContext.referenceFrame.numRows === currentGraphFrame.numRows
      : false

  const showInfoBox =
    isSelected ||
    (levelDelta === 0 && isSameScale) ||
    (path.length === 1 && !isSmallLayout)
  //  (isSelected || path.length === 1 || levelDelta === 0) &&
  //  (!isSmallLayout || isSelected || levelDelta === 0);
  const showTopLevelInfoBox =
    (isParentSelected || (levelDelta === 0 && !isSameScale)) &&
    isSmallLayout &&
    path.length === 1
  const showPageControl = isSelected
  const showTimeline = isPlaying && isSelected
  const showPlayer = isSelected
  const showSubHeader = (isSelected || isHover) && !isSmallLayout
  const showIcons = isSelected || isHover
  const showTopLevelIcons = isHover
  const showPlayButton =
    (!isPlaying || isFinished || !isSelected) &&
    (isSelected || (isHover && path.length === 1))
  const showCaptionOverlay =
    (isHover || forceHover) &&
    (isSmallLayout || (path.length > 1 && levelDelta > 0)) &&
    path.length > 1 &&
    !isSelected &&
    levelDelta > 0
  const showCaptionOverlayIcons = isHover
  const showHoverIndicator = isHover && !isSelected && !isSomeChildSelected

  /*
  const randomImage = React.useMemo(() => {
    return randomImages[parseInt(path[path.length - 1]) % randomImages.length]
  }, [path])

  const randomTitle = React.useMemo(() => {
    return randomCaptions[
      parseInt(path[path.length - 1]) % randomCaptions.length
    ]
  }, [path])

  const date = '15 March 2020'
  const user = 'nico'*/

  const pages = React.useMemo(
    () =>
      data && data.children
        ? [`${data.vitId ? 'SOURCE' : 'VITS'}`, `RELATED`, `ADD`]
        : ['', '', ''],
    [data, isSmallLayout]
  )

  const pageSizes = React.useMemo(
    () =>
      data && filteredChildren
        ? [
            filteredChildren[0].length,
            filteredChildren[1].length,
            filteredChildren[2].length,
          ]
        : [0, 0, 0],
    [filteredChildren, isSmallLayout]
  )

  const onPageChange = React.useCallback((index) => setPageIndex(index), [])

  const onChildPress = React.useCallback((index) => setProgress(index), [])
  const onChildHover = React.useCallback((index) => {
    setHoverChildIndex(index)
  }, [])
  const onExitHover = React.useCallback(() => setHoverChildIndex(undefined), [])

  const onCardZoom = React.useCallback(() => {
    onZoomChange([zoomContext])
  }, [zoomContext])

  const onPlay = React.useCallback(() => {
    if (!isSelected) {
      onCardZoom()
    }

    console.log('ON PLAY', sourceVideo, data)

    setPlaying(true)
    setFinished(false)
  }, [isSelected, onCardZoom])

  const cardRef = React.useRef(false)
  if (!cardRef.current && !isScrolling && data) {
    cardRef.current = true
  }

  const playerStateChanged = React.useCallback((id, index, progress) => {
    if (index !== undefined) {
      setProgress(progress)
    } else {
      setFinished(true)
      setSeekIndex(undefined)
    }
  }, [])

  const [collected, drag, preview] = useDrag(
    () => ({
      type: 'vit',
      item: { data: data?.playerSequence, image: data?.image },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    [data]
  )

  React.useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  const saveCallback = React.useCallback(() => {
    if (api && api.save) {
      api.save()
    }
  }, [api?.save])

  const unsaveCallback = React.useCallback(() => {
    if (api && api.unsave) {
      api.unsave()
    }
  }, [api?.save])

  const onEditCallback = React.useCallback(() => {
    if (data?.vitId) {
      history.push(`/edit/vit:${data.vitId}`)
    } else if (data?.vitlineId) {
      history.push(`/edit/vitline:${data.vitlineId}`)
    }
  }, [history])

  const onDeleteCallback = React.useCallback(() => {
    if (api && api.delete) {
      api.delete()
    }
  }, [api?.delete])

  /*
  const [{ isDragging }, drag] = useDrag<DataType>(() => ({
    type: "vit",
    item: data,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        console.log(`You dropped vitId into ${dropResult.name}!`);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId()
    })
  }));*/

  return (
    <div ref={containerRef}>
      <animated.div
        className={outerZoomClass}
        style={{
          ...containerProps,
          ...containerSpringProps,
          /* backgroundColor:
            (canDrop && isDropping) || showHoverIndicator
              ? 'var(--drop-target-bg)'
              : undefined,*/
          transform: to(
            [
              containerSpringProps.graphFrameIndex,
              springPropsZoomConfig,
              springPropsRowOffset,
            ],
            (graphFrameIndex, zoomConfig, currentRowOffset) => {
              if (path.length === 1 && path[0] === '6') {
                //console.log(graphFrameIndex, zoomConfig, currentRowOffset);
              }
              return transformForFrame(
                path.length === 1 && path[0] === '6',
                Array.isArray(graphFrames) ? graphFrames : [graphFrames],
                graphFrameIndex as number,
                layout,
                graphSize,
                zoomConfig as GraphZoomConfig,
                windowWidth ?? 0,
                windowHeight ?? 0,
                aspectRatio ?? 0,
                columns ?? 0,
                currentRowOffset as number
              )
            }
          ),
        }}
        onClick={(e) => {
          if (outerZoomClass === 'zoomed') {
            e.stopPropagation()
          } else if (outerZoomClass === 'zoom-out') {
            onZoomOut()
            e.stopPropagation()
          }
          /*
          if (outerZoomClass === 'zoom-out') {
            onZoomOut()
            e.stopPropagation()
          } else if (outerZoomClass === 'zoom-in') {
            onZoomChange([zoomContext])
            e.stopPropagation()
          }*/
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children ? (
          children({ isSelected, nodeProps, onCardZoom })
        ) : data && cardRef.current ? (
          <PlayerObserverContext.Provider value={playerStateChanged as any}>
            <CardMemo
              id={data.key}
              dragRef={drag}
              isDragging={collected.isDragging}
              isDropping={false}
              isSmallLayout={isSmallLayout}
              image={data.image}
              title={data.title}
              date={data.date}
              user={data.user}
              saved={data.saved}
              onSave={saveCallback}
              onUnsave={unsaveCallback}
              onEdit={api?.edit !== undefined ? onEditCallback : undefined}
              onDelete={
                api?.delete !== undefined ? onDeleteCallback : undefined
              }
              shareUrl={data.shareUrl}
              playerSequence={data.playerSequence}
              seekIndex={seekIndex}
              setSeekIndex={setSeekIndex}
              isPlaying={isPlaying}
              isFinished={isFinished}
              showPlayer={showPlayer}
              showSource={showSource}
              sourceVideo={sourceVideo}
              onPlay={onPlay}
              infoProps={infoProps}
              nodeProps={nodeProps}
              totalChildren={defaultItems.length}
              timelineTotalChildren={defaultTimelineItems.length}
              maxChildren={maxChildren}
              cycleIndex={cycleIndex}
              setCycleIndex={setCycleIndex}
              onZoom={onCardZoom}
              innerZoomClass={innerZoomClass}
              infoBoxZoomClass={infoBoxZoomClass}
              timelineLeftIconClass={timelineLeftIconClass}
              timelineRightIconClass={timelineRightIconClass}
              timelinePageSize={timelinePageSize}
              showInfoBox={showInfoBox}
              showHoverIndicator={false}
              showSubHeader={showSubHeader}
              showIcons={showIcons}
              showTopLevelInfoBox={showTopLevelInfoBox}
              showTopLevelIcons={showTopLevelIcons}
              showPlayButton={showPlayButton}
              showCaptionOverlay={showCaptionOverlay}
              showCaptionOverlayIcons={showCaptionOverlayIcons}
              showPageControl={showPageControl}
              showTimeline={showTimeline && !showSource}
              onTimelineHover={onChildHover}
              onTimelinePress={onChildPress}
              onTimelineHoverExit={onExitHover}
              timelineProgress={progress}
              pages={pages}
              pageIndex={pageIndex}
              pageSizes={pageSizes}
              onPageChange={onPageChange}
              playerRef={playerRef}
            ></CardMemo>
          </PlayerObserverContext.Provider>
        ) : data ? (
          <>
            <div className={'node'} style={nodeProps}>
              <ThumbnailView image={data.image} showClip={!!data.vitId} />
            </div>
            <div className={'info'} style={infoProps}>
              {showTopLevelInfoBox ? (
                <div className={'info-overlay-toplevel'}>
                  <div className="info-overlay-toplevel-text">
                    <div className={'max-lines-sm'}>{data.title}</div>
                  </div>{' '}
                </div>
              ) : (
                <div className="info-box">
                  <div className="info-box-left">
                    <div className="info-box-title info-box-title-text">
                      <div className="max-lines-sm">{data.title}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div
            className={
              'node-placeholder ' +
              (isSmallLayout
                ? 'node-thumbnail-img-clipped'
                : 'node-thumbnail-img-noclip')
            }
            style={nodeProps}
          ></div>
        )}
        <FractalGraphPortal>
          {transitions((style, item) => {
            const newPath = item.path
            const pathKey = item.key

            const itemIndex = defaultItems.indexOf(item)
            const totalEntries = defaultItems.length

            if (childFactory) {
              return childFactory(item, (api: any) => {
                const data = api.data
                return (
                  <animated.div
                    style={{ ...style, zIndex: zIndex ?? isHover ? 1 : 0 }}
                  >
                    <FractalNode
                      api={api}
                      data={data}
                      key={pathKey}
                      path={newPath}
                      layout={childLayout}
                      graphSize={childSize}
                      graphFrames={childFrames}
                      graphFrameIndex={itemIndex - cycleIndex}
                      onZoomOut={onZoomOut}
                      onZoomChange={onZoomChangeCallback}
                      zIndex={zIndex ?? isHover ? 1 : 0}
                      forceHover={
                        (itemIndex === hoverChildIndex ||
                          (isPlaying && itemIndex === Math.floor(progress))) &&
                        pageIndex === 0
                      }
                      childFactory={childFactory}
                      existingKeySet={childExistingKeySet}
                    ></FractalNode>
                  </animated.div>
                )
              })
            }
          })}
          {data && pageIndex === 2 && (isSelected || isSomeChildSelected) && (
            <FractalNode
              data={undefined}
              api={undefined}
              key={data.key + '/attach_vit_' + filteredChildren[2].length}
              path={[...data.path, 'attach_vit_' + filteredChildren[2].length]}
              layout={childLayout}
              graphSize={childSize}
              graphFrames={allChildFrames}
              graphFrameIndex={Math.min(
                allChildFrames.length - 1,
                filteredChildren[2].length - cycleIndex
              )}
              onZoomOut={onZoomOut}
              onZoomChange={onZoomChangeCallback}
              zIndex={zIndex ?? isHover ? 1 : 0}
              forceHover={false}
            >
              {({ isSelected, nodeProps, onCardZoom }) => {
                return (
                  <AttachVitCard
                    nodeProps={nodeProps}
                    isSelected={isSelected}
                    onCardZoom={onCardZoom}
                    api={api}
                    data={data}
                  ></AttachVitCard>
                )
              }}
            </FractalNode>
          )}
        </FractalGraphPortal>
      </animated.div>
    </div>
  )
}

function AttachVitCard({
  nodeProps,
  api,
  isSelected,
  data,
  onCardZoom,
}: {
  nodeProps: React.CSSProperties
  api: API | undefined
  isSelected: boolean
  data: DataType
  onCardZoom: () => void
}) {
  const [{ isDropping, canDrop }, drop] = useDrop(
    () => ({
      accept: 'vit',
      collect: (monitor) => ({
        isDropping: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
      canDrop: (item: any) => {
        const newVitAttachId = item.data[0].vitId
        const existingAttaches = data.children[data.children.length - 1]
        return (
          newVitAttachId !== data.vitId &&
          !existingAttaches.find((existingAttach) => {
            return existingAttach.vitId === newVitAttachId
          })
        )
      },
      drop(item: any, monitor) {
        if (api?.attach) {
          api.attach(item.data[0].vitId)
        }
      },
    }),
    []
  )

  const backgroundColor = isDropping
    ? canDrop
      ? 'var(--drop-target-bg)'
      : 'var(--drop-target-bad-bg)'
    : undefined

  if (isSelected) {
    return (
      <div
        ref={drop}
        className="zoomed"
        style={{
          position: 'absolute',
          top: '2%',
          left: '2%',
          height: '96%',
          width: '96%',
          backgroundColor: backgroundColor,
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <NewVitBuilder
          attachParentVitId={data.vitId}
          attachParentVitlineId={data.vitlineId}
          onSuccess={(vit: any) => {
            // TODO maybe zoom in on new node?
            //setFocusAttach(vit.vitId)
          }}
          scrollingEnabled
        ></NewVitBuilder>
      </div>
    )
  }
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: backgroundColor,
      }}
    >
      <div
        ref={drop}
        className={'node zoom-change'}
        style={nodeProps}
        onClick={(e) => {
          e.stopPropagation()
          onCardZoom()
        }}
      >
        <div className="node-attach node-thumbnail-img-clipped">
          <AddIcon fontSize="inherit"></AddIcon>
        </div>
      </div>
    </div>
  )
}

const CardMemo = React.memo(Card)

function Card({
  id,
  dragRef,
  isDragging,
  isDropping,
  isSmallLayout,
  image,
  title,
  date,
  user,
  saved,
  onSave,
  onUnsave,
  onEdit,
  onDelete,
  shareUrl,
  playerSequence,
  seekIndex,
  setSeekIndex,
  infoProps,
  nodeProps,
  isPlaying,
  isFinished,
  showPlayer,
  showSource,
  sourceVideo,
  onPlay,
  onZoom,
  totalChildren,
  timelineTotalChildren,
  maxChildren,
  cycleIndex,
  setCycleIndex,
  innerZoomClass,
  infoBoxZoomClass,
  timelineLeftIconClass,
  timelineRightIconClass,
  timelinePageSize,
  showInfoBox,
  showHoverIndicator,
  showSubHeader,
  showIcons,
  showTopLevelInfoBox,
  showTopLevelIcons,
  showPlayButton,
  showCaptionOverlay,
  showCaptionOverlayIcons,
  showPageControl,
  pages,
  pageIndex,
  pageSizes,
  onPageChange,
  showTimeline,
  onTimelineHover,
  onTimelinePress,
  onTimelineHoverExit,
  timelineProgress,
  playerRef,
}: {
  id: string
  dragRef: ConnectDragSource
  isDragging: boolean
  isDropping: boolean
  isSmallLayout: boolean
  image: string
  title: string
  date: string
  user: string
  saved: boolean
  onSave: () => void
  onUnsave: () => void
  onEdit?: () => void
  onDelete?: () => void
  shareUrl: string
  playerSequence: any[]
  seekIndex: number | undefined
  setSeekIndex: React.Dispatch<React.SetStateAction<number | undefined>>
  infoProps: React.CSSProperties
  nodeProps: React.CSSProperties
  isPlaying: boolean
  isFinished: boolean
  showPlayer: boolean
  showSource: boolean
  sourceVideo: string
  onPlay: () => void
  onZoom: () => void
  totalChildren: number
  timelineTotalChildren: number
  maxChildren: number
  cycleIndex: number
  setCycleIndex: React.Dispatch<React.SetStateAction<number>>
  innerZoomClass: string
  infoBoxZoomClass: string
  timelineLeftIconClass: string
  timelineRightIconClass: string
  timelinePageSize: number
  showInfoBox: boolean
  showHoverIndicator: boolean
  showSubHeader: boolean
  showIcons: boolean
  showTopLevelInfoBox: boolean
  showTopLevelIcons: boolean
  showPlayButton: boolean
  showCaptionOverlay: boolean
  showCaptionOverlayIcons: boolean
  showPageControl: boolean
  pages: string[]
  pageIndex: number
  pageSizes: number[]
  onPageChange: (index: number) => void
  showTimeline: boolean
  onTimelineHover: (index: number) => void
  onTimelinePress: (index: number) => void
  onTimelineHoverExit: () => void
  timelineProgress: number
  playerRef: React.MutableRefObject<PlayerAPI | undefined>
}) {
  const infoBoxProps = useSpring({
    transform: showTimeline ? 'translate(0, 25%)' : 'translate(0, 0%)',
    opacity: showInfoBox ? 1 : 0,
    backgroundColor: showHoverIndicator ? '#e7f0eb' : '#ffffff00',
  })

  const infoBoxTopLevelProps = useSpring({
    opacity: showTopLevelInfoBox ? 1 : 0,
    /*display:
      isParentSelected && isSmallLayout && path.length === 1
        ? undefined
        : "none",*/
  })

  const segmentedControlProps = useSpring({
    opacity: showPageControl ? 1 : 0,
    //pointerEvents: isSelected ? undefined : "none",
  })

  const timelineProps = useSpring({
    opacity: showTimeline ? 1 : 0,
    transform: showTimeline ? 'translate(0, 0%)' : 'translate(0, -100%)',
  })

  const timelineScrollRef = React.useRef<HTMLDivElement | null>(null)

  const timelineScrollProps = useSpring({
    scrollLeft: timelineScrollRef.current
      ? (cycleIndex / maxChildren) * timelineScrollRef.current.offsetWidth
      : 0,
  })

  const onTimelinePressCallback = React.useCallback(
    (index, progress) => {
      setSeekIndex(index)
      if (playerRef.current) {
        const vitLength =
          playerSequence[index].end - playerSequence[index].start
        const offset = vitLength * progress
        playerRef.current.seekIndex(index, offset)
        if (!isPlaying || isFinished) {
          onPlay()
        }
      }
    },
    [onPlay, playerSequence]
  )

  const [
    currentTimeMinutes,
    currentTimeSeconds,
    totalTimeMinutes,
    totalTimeSeconds,
  ] = React.useMemo(() => {
    let totalTime = 0
    let currentTime = 0
    for (let index = 0; index < playerSequence.length; index++) {
      const vit = playerSequence[index]
      const vitTime = vit.end - vit.start

      if (index + 1 < timelineProgress) {
        currentTime += vitTime
      } else if (index < timelineProgress) {
        currentTime += vitTime * (timelineProgress - index)
      }

      totalTime += vitTime
    }
    return [
      Math.floor(currentTime / 60).toFixed(0),
      (currentTime % 60).toFixed(0),
      Math.floor(totalTime / 60).toFixed(0),
      (totalTime % 60).toFixed(0),
    ]
  }, [playerSequence, timelineProgress])

  const currentTime = `${currentTimeMinutes}:${String(
    currentTimeSeconds
  ).padStart(2, '0')}`
  const totalTime = `${totalTimeMinutes}:${String(totalTimeSeconds).padStart(
    2,
    '0'
  )}`

  const nativeDate = new Date(date)

  const [isNodeHover, setNodeHover] = React.useState(false)
  const [isInfoHover, setInfoHover] = React.useState(false)

  const backgroundProps = useSpring({
    opacity:
      !showPageControl && !showCaptionOverlay && (isNodeHover || isInfoHover)
        ? 1
        : 0,
  })

  const nodeOnMouseEnter = React.useCallback(() => {
    setNodeHover(true)
  }, [])

  const nodeOnMouseLeave = React.useCallback(() => {
    setNodeHover(false)
  }, [])

  const infoOnMouseEnter = React.useCallback(() => {
    setInfoHover(true)
  }, [])

  const infoOnMouseLeave = React.useCallback(() => {
    setInfoHover(false)
  }, [])

  const onLoadAPI = React.useCallback((api: PlayerAPI) => {
    playerRef.current = api
  }, [])

  return (
    <>
      <animated.div
        className={isSmallLayout ? 'card-background-small' : 'card-background'}
        style={backgroundProps}
      ></animated.div>
      <animated.div style={infoProps} className={'info'}>
        <animated.div
          className={'info-timeline ' + innerZoomClass}
          style={{
            ...timelineProps,
            display: showTimeline ? undefined : 'none',
          }}
          onClick={(e) => {
            if (
              innerZoomClass === 'zoom-change' ||
              innerZoomClass === 'zoom-in'
            ) {
              onZoom()
              e.stopPropagation()
            } else if (innerZoomClass === 'zoomed') {
              e.stopPropagation()
            }
          }}
        >
          <div
            className={timelineLeftIconClass + ' info-timeline-left'}
            onClick={() => {
              setCycleIndex((prevIndex) =>
                Math.max(
                  0,
                  Math.min(
                    totalChildren - maxChildren,
                    prevIndex - timelinePageSize
                  )
                )
              )
            }}
          >
            <LeftArrowIcon></LeftArrowIcon>
          </div>
          <div
            className={'info-timeline-right ' + timelineRightIconClass}
            onClick={() => {
              setCycleIndex((prevIndex) =>
                Math.max(
                  0,
                  Math.min(
                    totalChildren - maxChildren,
                    prevIndex + timelinePageSize
                  )
                )
              )
            }}
          >
            <RightArrowIcon></RightArrowIcon>
          </div>
          {/*
      <div className="info-timeline-right-fullscreen">
        <div
          className="active-button"
          onClick={(e) => {
            e.stopPropagation();
            if (isFullZoom) {
              onZoomChange([zoomContext]);
            } else {
              onZoomChange([fullZoomContext]);
            }
          }}
        >
          {isFullZoom ? (
            <FullscreenExitIcon></FullscreenExitIcon>
          ) : (
            <FullscreenIcon></FullscreenIcon>
          )}
        </div>
      </div>
      
      */}

          <animated.div
            ref={timelineScrollRef}
            className="info-timeline-scroll"
            scrollLeft={timelineScrollProps.scrollLeft}
          >
            <MemoTimeLine
              numItems={isSmallLayout ? 1 : timelineTotalChildren}
              progress={timelineProgress}
              maxChildrenPerPage={maxChildren}
              onChildPress={onTimelinePressCallback}
              onChildHover={onTimelineHover}
              onExitHover={onTimelineHoverExit}
            />
          </animated.div>
        </animated.div>

        <animated.div
          style={{
            ...infoBoxProps,
            display: showInfoBox ? undefined : undefined,
          }}
          className={'info-box ' + infoBoxZoomClass}
          onMouseEnter={infoOnMouseEnter}
          onMouseLeave={infoOnMouseLeave}
          onClick={(e) => {
            if (
              infoBoxZoomClass === 'zoom-change' ||
              innerZoomClass === 'zoom-in'
            ) {
              onZoom()
              e.stopPropagation()
            } else if (infoBoxZoomClass === 'zoomed') {
              e.stopPropagation()
            }
          }}
        >
          <div className="info-box-left">
            <div className="info-box-title info-box-title-text">
              <div className="max-lines-sm">{title}</div>
            </div>
            <div
              className="info-box-subheader"
              style={{
                opacity: showSubHeader ? '1.0' : '0.0',
              }}
            >
              {nativeDate.toDateString()} • by{' '}
              <span className="info-box-user">
                <Link to={'/user/' + user}>{user}</Link>
              </span>
            </div>
          </div>
          <div className="info-box-right">
            <div className="info-box-time">
              {isPlaying ? `${currentTime} / ${totalTime}` : `${totalTime}`}
            </div>

            <div
              className="info-box-icons"
              style={{
                opacity: showIcons ? '1.0' : '0.0',
              }}
            >
              <CardActionsMemo
                shareUrl={shareUrl}
                paddingClass="info-box-icons-padding"
                saved={saved}
                onSave={onSave}
                onUnsave={onUnsave}
                onEdit={onEdit}
                onDelete={onDelete}
              ></CardActionsMemo>
            </div>
          </div>
        </animated.div>

        <animated.div
          className={'info-overlay-toplevel ' + infoBoxZoomClass}
          style={{
            ...infoBoxTopLevelProps,
            display: showTopLevelInfoBox ? undefined : 'none',
          }}
          onMouseEnter={infoOnMouseEnter}
          onMouseLeave={infoOnMouseLeave}
          onClick={(e) => {
            if (
              infoBoxZoomClass === 'zoom-change' ||
              innerZoomClass === 'zoom-in'
            ) {
              onZoom()
              e.stopPropagation()
            } else if (infoBoxZoomClass === 'zoomed') {
              e.stopPropagation()
            }
          }}
        >
          <div className="info-overlay-toplevel-time">
            {isPlaying ? `${currentTime} / ${totalTime}` : `${totalTime}`}
          </div>
          <div
            className="info-overlay-toplevel-icons"
            style={{
              opacity: showTopLevelIcons ? 1 : 0,
            }}
          >
            <CardActionsMemo
              shareUrl={shareUrl}
              paddingClass="info-box-overlay-padding"
              saved={saved}
              onSave={onSave}
              onUnsave={onUnsave}
              onEdit={onEdit}
              onDelete={onDelete}
            ></CardActionsMemo>
          </div>

          <div className="info-overlay-toplevel-text">
            <div className={'max-lines-sm'}>{title}</div>
          </div>

          <div
            className="info-overlay-toplevel-subheader"
            style={{
              opacity: showTopLevelIcons && !isSmallLayout ? '1.0' : '0.0',
            }}
          >
            {nativeDate.toDateString()} • added by{' '}
            <span className="info-box-user-top-level">{user}</span>
          </div>
        </animated.div>
        <animated.div
          style={{
            ...segmentedControlProps,
            pointerEvents: showPageControl ? undefined : 'none',
          }}
        >
          <div
            className={'info-control ' + innerZoomClass}
            onClick={(e) => {
              if (
                innerZoomClass === 'zoom-change' ||
                innerZoomClass === 'zoom-in'
              ) {
                onZoom()
                e.stopPropagation()
              } else if (innerZoomClass === 'zoomed') {
                e.stopPropagation()
              }
            }}
          >
            <MemoSegmentedControl
              isSmallLayout={isSmallLayout}
              options={pages}
              optionSizes={pageSizes}
              selectedOption={pageIndex}
              onOptionSelected={onPageChange}
            ></MemoSegmentedControl>
          </div>
        </animated.div>
      </animated.div>
      <div
        ref={dragRef}
        style={{
          ...nodeProps,
        }}
        className={'node ' + innerZoomClass}
        onMouseEnter={nodeOnMouseEnter}
        onMouseLeave={nodeOnMouseLeave}
        onClick={(e) => {
          if (
            innerZoomClass === 'zoom-change' ||
            innerZoomClass === 'zoom-in'
          ) {
            onZoom()
            e.stopPropagation()
          } else if (innerZoomClass === 'zoomed') {
            e.stopPropagation()
          }
        }}
      >
        <ThumbnailView
          image={image}
          showClip={isSmallLayout && (!isPlaying || isFinished || !showPlayer)}
        />

        {((isPlaying && !isFinished) || showSource) && (
          <div
            style={{
              pointerEvents: showPlayer || showSource ? undefined : 'none',
              opacity: showPlayer || showSource ? '1.0' : '0.0',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            {showSource && (
              <YouTube
                opts={{
                  playerVars: {
                    autoplay: 0,
                    start: playerSequence[0].start,
                  },
                }}
                videoId={sourceVideo}
                onReady={(event) => {
                  console.log('READY PLAYER', sourceVideo, playerSequence)
                  event.target.cueVideoById({
                    videoId: sourceVideo,
                    startSeconds: playerSequence[0].start,
                  })
                  // event.target.playVideo()
                }}
                onStateChange={(event) => {
                  /* if (
                    event.target.getPlayerState() ===
                    window.YT.PlayerState.PLAYING
                  ) {
                    //   setPlaying(true)
                  }*/
                }}
              />
            )}
            {!showSource && (
              <MemoLightPlayer
                id={id}
                sequence={playerSequence}
                color={'primary'}
                url={''}
                disableTimeline
                superLightMode
                onLoadAPI={onLoadAPI}
              ></MemoLightPlayer>
            )}
          </div>
        )}

        {!showSource && (
          <div
            className="node-overlay"
            style={{
              opacity: showPlayButton ? 1.0 : 0.0,
              pointerEvents: 'none',
            }}
          >
            <CircleButton
              showPlayButton={showPlayButton}
              onPress={(e) => {
                onPlay()
                e.stopPropagation()
              }}
            />
          </div>
        )}
      </div>

      <div
        style={{
          opacity: showCaptionOverlay ? 1 : 0,
          pointerEvents: showCaptionOverlay ? undefined : 'none',
        }}
      >
        <div
          className={
            isSmallLayout
              ? 'info-overlay-small ' + innerZoomClass
              : 'info-overlay ' + innerZoomClass
          }
          onClick={(e) => {
            if (
              innerZoomClass === 'zoom-change' ||
              innerZoomClass === 'zoom-in'
            ) {
              onZoom()
              e.stopPropagation()
            } else if (innerZoomClass === 'zoomed') {
              e.stopPropagation()
            }
          }}
        >
          <div
            className={
              isSmallLayout
                ? 'info-overlay-small-background'
                : 'info-overlay-background'
            }
          ></div>
          <div
            className={
              isSmallLayout ? 'info-overlay-small-icons' : 'info-overlay-icons'
            }
            style={{ opacity: showCaptionOverlayIcons ? 1.0 : 0.0 }}
          >
            <CardActionsMemo
              shareUrl={shareUrl}
              paddingClass="info-box-overlay-padding"
              saved={saved}
              onSave={onSave}
              onUnsave={onUnsave}
              onEdit={onEdit}
              onDelete={onDelete}
            ></CardActionsMemo>
          </div>

          <div className="info-overlay-text">
            <div className={'max-lines-lg'}>{title}</div>
          </div>
        </div>
      </div>
    </>
  )
}

const CardActionsMemo = React.memo(CardActions)

function CardActions({
  paddingClass,
  saved,
  onSave,
  onUnsave,
  onEdit,
  onDelete,
  shareUrl,
}: {
  paddingClass: string
  saved: boolean | undefined
  onSave: () => void
  onUnsave: () => void
  onEdit?: () => void
  onDelete?: () => void
  shareUrl: string
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorElEdit, setAnchorElEdit] = React.useState(null)

  const handleClick = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleEditClick = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorElEdit(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEditClose = () => {
    setAnchorElEdit(null)
  }

  return (
    <>
      {onDelete && (
        <>
          <EditIcon fontSize="inherit" onClick={handleEditClick}></EditIcon>
          <span className={paddingClass}></span>
          <StyledMenu
            anchorEl={anchorElEdit}
            open={Boolean(anchorElEdit)}
            onClose={handleEditClose}
          >
            {onEdit && (
              <StyledMenuItem
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  onEdit && onEdit()
                  setAnchorElEdit(null)
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary="Edit"
                  primaryTypographyProps={{ color: 'secondary' }}
                />
              </StyledMenuItem>
            )}
            {onDelete && (
              <StyledMenuItem
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  onDelete && onDelete()
                  setAnchorElEdit(null)
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary="Remove"
                  primaryTypographyProps={{ color: 'secondary' }}
                />
              </StyledMenuItem>
            )}
          </StyledMenu>
        </>
      )}
      <ShareIcon fontSize="inherit" onClick={handleClick}></ShareIcon>
      <span className={paddingClass}></span>
      <FavoriteIcon
        fontSize="inherit"
        color={saved ? 'primary' : 'inherit'}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (saved) {
            onUnsave()
          } else {
            onSave()
          }
        }}
      ></FavoriteIcon>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            navigator.clipboard.writeText(shareUrl)
            setAnchorEl(null)
          }}
        >
          <ListItemIcon>
            <LinkIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Copy link" />
        </StyledMenuItem>
      </StyledMenu>
    </>
  )
}

function ThumbnailView({
  image,
  showClip,
}: {
  image: string
  showClip: boolean
}) {
  return (
    <div
      className={
        showClip ? 'node-thumbnail-img-clipped' : 'node-thumbnail-img-noclip'
      }
      style={{
        backgroundColor: '#DDDDDD',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <AspectRatio ratio={9 / 16} width="100%" className="node-thumbnail">
        <img
          className={'node-thumbnail-img '}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          src={image}
        />
      </AspectRatio>
    </div>
  )
}

function CircleButton({
  showPlayButton,
  onPress,
}: {
  showPlayButton: boolean
  onPress: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}) {
  const [isHover, setHover] = React.useState(false)
  const selfRef = React.useRef<HTMLDivElement | null>(null)

  return (
    <div
      ref={selfRef}
      className={
        'node-overlay-icon ' + (isHover ? 'node-overlay-icon-hover' : '')
      }
      style={{
        pointerEvents: showPlayButton ? 'auto' : 'none',
      }}
      onMouseMove={(e) => {
        if (selfRef.current) {
          const boundingBox = selfRef.current.getBoundingClientRect()
          const dx = boundingBox.width / 2.0 - (e.clientX - boundingBox.x)
          const dy = boundingBox.height / 2.0 - (e.clientY - boundingBox.y)
          const distance = Math.sqrt(dx * dx + dy * dy)
          const radius = boundingBox.width / 2

          if (distance <= radius) {
            setHover(true)
          } else {
            setHover(false)
          }
        }
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      onClick={(e) => {
        if (isHover) {
          onPress(e)
        }
      }}
    >
      <PlayArrowIcon fontSize="inherit"></PlayArrowIcon>
    </div>
  )
}

const MemoSegmentedControl = React.memo(SegmentedControl)

function SegmentedControl({
  isSmallLayout,
  options,
  selectedOption,
  onOptionSelected,
  optionSizes,
}: {
  isSmallLayout: boolean
  options: string[]
  selectedOption: number
  onOptionSelected: (index: number) => void
  optionSizes: number[]
}) {
  const className = isSmallLayout
    ? 'segmented-control-option-small'
    : 'segmented-control-option'
  return (
    <div className="segmented-control-container">
      {options.map((option, index) => {
        const additionalClass =
          selectedOption === index ? 'segmented-control-selected' : ''
        return (
          <div
            key={option}
            className={className + ' ' + additionalClass}
            onClick={() => {
              onOptionSelected(index)
            }}
          >
            {option}
            {(optionSizes[index] > 0 || index !== 0) && (
              <span className="segmented-control-size">
                ({optionSizes[index]})
              </span>
            )}
          </div>
        )
      })}
    </div>
  )
}

const MemoTimeLine = React.memo(TimeLine)

function TimeLine({
  numItems,
  progress,
  maxChildrenPerPage,
  onChildHover,
  onChildPress,
  onExitHover,
}: {
  numItems: number
  progress: number
  maxChildrenPerPage: number
  onChildHover: (index: number) => void
  onChildPress: (index: number, progress: number) => void
  onExitHover: () => void
}) {
  return (
    <div
      className="timeline-grid"
      style={{
        width: Math.max(100, (numItems / maxChildrenPerPage) * 100) + '%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
      }}
      onMouseLeave={() => {
        onExitHover()
      }}
    >
      <div
        className="timeline-dot"
        style={{
          left: (progress / Math.min(maxChildrenPerPage, numItems)) * 100 + '%',
        }}
      >
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50" />
        </svg>
      </div>
      {Array.from({ length: numItems }, (x, index) => {
        return (
          <span
            className="timeline-node"
            key={index}
            onMouseEnter={() => {
              onChildHover(index)
            }}
            onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
              const rect = (e.target as HTMLSpanElement).getBoundingClientRect()
              const progress = (e.clientX - rect.x) / rect.width
              onChildPress(index, progress)
              e.stopPropagation()
            }}
            style={{
              display: 'flex',
              width: 100 / numItems + '%',
            }}
          >
            <span
              style={{
                display: 'flex',
                flexGrow: 1,
              }}
            ></span>
          </span>
        )
      })}
    </div>
  )
}
