import React from 'react'

import GraphNode from './GraphNode'
import VitsAPI from '../../../api/VitsAPI'
import NodeCardView from './NodeCardView'
import { Link } from 'react-router-dom'
import { PlayerObserverContext } from '../../player/PlayerObserverContext'

export default function VitLineNode({ vitlineId, ...props }) {
  if (vitlineId === 'draft') {
    return <DraftVitLineNode {...props}></DraftVitLineNode>
  } else {
    return (
      <ExistingVitLineNode
        vitlineId={vitlineId}
        {...props}
      ></ExistingVitLineNode>
    )
  }
}

function DraftVitLineNode({ urlPrefix, graphConfig, getChildren }) {
  const [draft] = VitsAPI.useVitlineDraft()
  let renderer = null
  let infoRenderer = null

  if (draft) {
    renderer = (
      <VitLineNodeRenderer
        vitline={draft}
        urlPrefix={urlPrefix}
        graphConfig={graphConfig}
        mediaOnly
      ></VitLineNodeRenderer>
    )

    infoRenderer = (
      <VitLineNodeRenderer
        vitline={draft}
        urlPrefix={urlPrefix}
        graphConfig={graphConfig}
        infoOnly
      ></VitLineNodeRenderer>
    )
  }

  return (
    <GraphNode
      graphConfig={graphConfig}
      childData={draft ? getChildren(draft) : undefined}
      renderer={renderer}
      infoRenderer={infoRenderer}
      urlPrefix={urlPrefix}
    ></GraphNode>
  )
}

function ExistingVitLineNode({
  vitlineId,
  urlPrefix,
  graphConfig,
  getChildren,
  showPlaybackContextualChildren,
}) {
  const vitline = VitsAPI.useVitLineData(vitlineId)
  const vits = vitline ? VitsAPI.vitsFromVitLine(vitline) : undefined

  const [playingIndex, setPlayingIndex] = React.useState(undefined)
  const [playingVit, getPlayingVit] = VitsAPI.useLazyVitData(
    vits && playingIndex !== undefined ? vits[playingIndex].vitId : undefined
  )

  React.useEffect(() => {
    if (playingIndex !== undefined) {
      getPlayingVit()
    }
  }, [playingIndex])

  let renderer = React.useMemo(() => {
    if (vitline) {
      return (
        <VitLineNodeRenderer
          vitline={vitline}
          urlPrefix={urlPrefix}
          graphConfig={graphConfig}
          mediaOnly
        ></VitLineNodeRenderer>
      )
    }
  }, [vitline, urlPrefix, graphConfig])

  let infoRenderer = null

  if (vitline) {
    infoRenderer = (
      <VitLineNodeRenderer
        vitline={vitline}
        urlPrefix={urlPrefix}
        graphConfig={graphConfig}
        infoOnly
      ></VitLineNodeRenderer>
    )
  }

  const playerStateChanged = React.useCallback((id, index) => {
    if (id === vitlineId && showPlaybackContextualChildren) {
      setPlayingIndex(index)
    }
  }, [])

  return (
    <PlayerObserverContext.Provider value={playerStateChanged}>
      <GraphNode
        graphConfig={graphConfig}
        childData={
          vitline
            ? getChildren(playingVit !== undefined ? playingVit : vitline)
            : undefined
        }
        renderer={renderer}
        infoRenderer={infoRenderer}
        urlPrefix={urlPrefix}
      ></GraphNode>
    </PlayerObserverContext.Provider>
  )
}

function VitLineNodeRenderer({ vitline, urlPrefix, graphConfig, ...props }) {
  const firstVit = VitsAPI.vitsFromVitLine(vitline)[0]
  const [userId] = VitsAPI.useUserId()
  const [saveVitline, unsaveVitline] = VitsAPI.useVitlineSaveMutation(
    vitline.vitlineId
  )

  let url = urlPrefix + graphConfig.path.join('/')
  let imageUrl =
    'https://img.youtube.com/vi/' + firstVit.source.videoId + '/0.jpg'
  if (firstVit && firstVit.thumbnail) {
    imageUrl = firstVit.thumbnail.thumbnailUrl
  }

  const nativeDate = new Date(vitline.published.formatted)
  const subheader = (
    <span>
      VitLine published {nativeDate.toDateString()} by{' '}
      <Link to={'/user/' + vitline.author.userId}>{vitline.author.userId}</Link>
    </span>
  )

  const saved = vitline.saves.find((save) => {
    return save.User.userId === userId
  })

  const renderer = (
    <NodeCardView
      id={vitline.vitlineId}
      typename={vitline.__typename}
      title={vitline.title}
      subHeader={subheader}
      imageUrl={imageUrl}
      url={url}
      shareUrl={`https://vits.app/view/vitline:${vitline.vitlineId}`}
      elevation={3}
      vits={VitsAPI.vitsFromVitLine(vitline)}
      saved={saved}
      onSaveToggle={() => {
        if (saved) {
          unsaveVitline()
        } else {
          saveVitline()
        }
      }}
      {...props}
    ></NodeCardView>
  )

  return renderer
}
