import React from 'react'

import VitsAPI from '../../api/VitsAPI'
import VitBuilder from './VitBuilder'
import NewVitBuilder from './NewVitBuilder'

export default function VitBuilderLoader({
  parentVitlineId,
  index,
  vitId,
  ...props
}) {
  if (parentVitlineId === 'draft') {
    return <DraftVitLoader index={index} {...props}></DraftVitLoader>
  } else if (vitId) {
    return <ExistingVitLoader vitId={vitId} {...props}></ExistingVitLoader>
  } else {
    return (
      <VitLineVitIndexLoader
        parentVitlineId={parentVitlineId}
        index={index}
        {...props}
      ></VitLineVitIndexLoader>
    )
  }
}

function DraftVitLoader({ index, ...props }) {
  const [draft, draftVits] = VitsAPI.useVitlineDraft()

  if (draft) {
    if (!draftVits[index]) {
      return (
        <NewVitBuilder
          parentVitlineId={'draft'}
          index={index}
          {...props}
        ></NewVitBuilder>
      )
    }
    return (
      <VitBuilder
        vit={draftVits[index]}
        parentVitlineId={draft.vitlineId}
        index={index}
        {...props}
      ></VitBuilder>
    )
  }
  return null
}

function VitLineVitIndexLoader({ parentVitlineId, index, ...props }) {
  const data = VitsAPI.useVitAtVitLineIndexData(parentVitlineId, index)

  if (data || data === null) {
    if (!data) {
      return (
        <NewVitBuilder
          parentVitlineId={parentVitlineId}
          index={index}
          {...props}
        ></NewVitBuilder>
      )
    }

    return (
      <VitBuilder
        vit={data}
        parentVitlineId={parentVitlineId}
        index={index}
        {...props}
      ></VitBuilder>
    )
  }
  return null
}

function ExistingVitLoader({ vitId, ...props }) {
  const data = VitsAPI.useVitData(vitId)

  if (data) {
    return <VitBuilder vit={data} {...props}></VitBuilder>
  }
  return null
}
