import React from 'react'
import YouTube from 'react-youtube'
import { Link } from 'react-router-dom'

import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

export default function VideoPreview({ videoId, color = 'primary', url }) {
  const [isPlaying, setPlaying] = React.useState(false)
  const [isPlayActivated, setPlayActivated] = React.useState(false)
  const [isVideoHover, setVideoHover] = React.useState(false)

  const useStyles = makeStyles(() => ({
    largeIcon: {
      fontSize: '5em',
    },
  }))
  const classes = useStyles()

  const image = 'https://img.youtube.com/vi/' + videoId + '/0.jpg'

  return (
    <div
      style={{ position: 'relative' }}
      onMouseOver={() => setVideoHover(true)}
      onMouseOut={() => setVideoHover(false)}
    >
      <div
        onClick={() => {}}
        className={'youtubeThumbnail'}
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></div>

      <Link to={url}>
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,

            opacity: isVideoHover && !isPlayActivated ? '1.0' : '0.0',
            transition: 'opacity 0.2s ease-in-out',

            zIndex: isVideoHover && !isPlayActivated ? 1 : -1,

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton
            color={color}
            onMouseDown={(event) => event.stopPropagation()}
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              setPlayActivated(true)
            }}
          >
            <PlayArrowIcon
              className={classes.largeIcon}
              fontSize="large"
            ></PlayArrowIcon>
          </IconButton>
        </div>
      </Link>

      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,

          zIndex: !isPlaying && isPlayActivated ? 1 : -1,

          opacity: !isPlaying && isPlayActivated ? '1.0' : '0.0',
          transition: 'opacity 0.2s ease-in-out',

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color={color} />
      </div>

      {isPlayActivated && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: isPlaying ? 1 : -1,
          }}
        >
          <YouTube
            videoId={videoId}
            onReady={(event) => {
              event.target.playVideo()
            }}
            onStateChange={(event) => {
              if (
                event.target.getPlayerState() === window.YT.PlayerState.PLAYING
              ) {
                setPlaying(true)
              }
            }}
          />
        </div>
      )}
    </div>
  )
}
