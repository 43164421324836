import React from 'react'

//import GraphView from '../graph/GraphView'

import { FractalGraphView } from '../../../fractal/fractal-graph-view'
import { splinksiLayout } from '../../../fractal/utils'
import { FractalNode } from '../../../fractal/fractal-node'
import VitsAPI from '../../api/VitsAPI'
import IconButton from '@material-ui/core/IconButton'
import { Tooltip } from '@material-ui/core'

export function scaledGraphFrame(graphFrame, scale) {
  const extraRows = graphFrame.numRows * scale - graphFrame.numRows
  const extraCols = graphFrame.numCols * scale - graphFrame.numCols

  return {
    row: graphFrame.row - extraRows / 2.0,
    col: graphFrame.col - extraCols / 2.0,
    numRows: graphFrame.numRows + extraRows,
    numCols: graphFrame.numCols + extraCols,
  }
}

function thumbnailForData(data) {
  let imageUrl = ''

  if (data.vitIndexes && data.vitIndexes.length > 0) {
    const firstVit = VitsAPI.vitsFromVitLine(data)[0]
    imageUrl =
      'https://img.youtube.com/vi/' + firstVit.source.videoId + '/0.jpg'
    if (firstVit && firstVit.thumbnail) {
      imageUrl = firstVit.thumbnail.thumbnailUrl
    }
  } else if (data.source) {
    imageUrl = 'https://img.youtube.com/vi/' + data.source.videoId + '/0.jpg'
    if (data && data.thumbnail) {
      imageUrl = data.thumbnail.thumbnailUrl
    }
  } else {
    imageUrl =
      'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
  }

  return imageUrl
}

export default function GraphFeed({
  // path,
  dataEntries,
  //nodeFactory,
  margins,
  loadMore,
  onScroll,
  zoomScale1 = 1.15,
  zoomScale2 = 1.5,
}) {
  /*
  return (
    <GraphView
      dataEntries={dataEntries}
      nodeFactory={nodeFactory}
      path={path}
      margins={margins}
      gridColumns={9}
      aspectRatio={16.0 / 9.0}
      loadMore={loadMore}
    ></GraphView>
  )*/

  const isSingleView = dataEntries.length === 1

  const [zoomContextStack, setZoomContextStack] = React.useState(
    isSingleView
      ? [
          {
            graphFrame: dataEntries[0].vitId
              ? { row: 0, col: 0, numRows: 5, numCols: 5 }
              : { row: 0, col: 0, numRows: 10, numCols: 10 },
            path: [dataEntries[0].vitId ?? dataEntries[0].vitlineId],
          },
        ]
      : []
  )

  const onZoomOutCallback = React.useCallback(() => {
    setZoomContextStack((prevStack) => prevStack.slice(0, prevStack.length - 1))
  }, [])

  const onZoomChangeCallback = React.useCallback((zoomContextStack) => {
    setZoomContextStack(zoomContextStack)
  }, [])

  const loadedData = React.useMemo(() => {
    return dataEntries.map((dataEntry) => {
      const key = dataEntry.vitId || dataEntry.vitlineId
      return {
        vitlineId: dataEntry.vitlineId,
        vitId: dataEntry.vitId,
        key: key,
        path: [key],
        title: dataEntry.title,
        date: dataEntry.published
          ? dataEntry.published.formatted
          : new Date().toDateString(),
        user: dataEntry.author ? dataEntry.author.userId : '',
        image: thumbnailForData(dataEntry),
        size: dataEntry.__typename === 'VitLine' ? 'large' : 'small',
        playerSequence:
          dataEntry.__typename === 'VitLine'
            ? VitsAPI.vitsFromVitLine(dataEntry)
            : [dataEntry],
        saved: false,
        children: [
          dataEntry.__typename === 'VitLine'
            ? dataEntry.vitIndexes.map((vitIndex) => {
                const vit = vitIndex.Vit
                const childKey = vit.vitId
                return {
                  key: key + '/' + childKey,
                  vitId: vit.vitId,
                  path: [key, childKey],
                  title: vit.title,
                  date: vit.published
                    ? vit.published.formatted
                    : new Date().toDateString(),
                  user: '',
                  image: thumbnailForData(vit),
                  size: 'large',
                  children: [],
                  playerSequence: [vit],
                  saved: false,
                }
              })
            : [],
          [],
          [],
        ],
      }
    })
  }, [dataEntries])

  console.log(loadedData)

  const childFactoryCallback = React.useCallback((partialData, rendererFn) => {
    if (partialData.vitId) {
      return (
        <VitQueryMemo
          key={partialData.key}
          vitId={partialData.vitId}
          data={partialData}
        >
          {rendererFn}
        </VitQueryMemo>
      )
    } else if (partialData.vitlineId) {
      return (
        <VitLineQueryMemo
          key={partialData.key}
          vitlineId={partialData.vitlineId}
          data={partialData}
        >
          {rendererFn}
        </VitLineQueryMemo>
      )
    }
  }, [])

  const factoryCallback = React.useCallback(
    (data, graphFrame, layout, graphSize) => {
      if (data.vitId) {
        return (
          <VitQueryMemo
            key={data.key}
            vitId={data.vitId}
            data={data}
            fullyLoaded
          >
            {(loadedData) => {
              return (
                <FractalNode
                  data={loadedData.data}
                  key={data.key}
                  path={data.path}
                  graphFrames={graphFrame}
                  graphFrameIndex={0}
                  layout={layout}
                  graphSize={graphSize}
                  onZoomOut={onZoomOutCallback}
                  onZoomChange={onZoomChangeCallback}
                  childFactory={childFactoryCallback}
                  api={loadedData}
                ></FractalNode>
              )
            }}
          </VitQueryMemo>
        )
      } else if (data.vitlineId) {
        return (
          <VitLineQueryMemo
            key={data.key}
            vitlineId={data.vitlineId}
            data={data}
            fullyLoaded
          >
            {(loadedData) => {
              return (
                <FractalNode
                  data={loadedData.data}
                  key={data.key}
                  path={data.path}
                  graphFrames={graphFrame}
                  graphFrameIndex={0}
                  layout={layout}
                  graphSize={graphSize}
                  onZoomOut={onZoomOutCallback}
                  onZoomChange={onZoomChangeCallback}
                  childFactory={childFactoryCallback}
                  api={loadedData}
                ></FractalNode>
              )
            }}
          </VitLineQueryMemo>
        )
      }

      return null
    },
    []
  )

  const layout = splinksiLayout(5, 2)
  const smallLayout = splinksiLayout(5, 1)

  const layoutCallback = React.useCallback(
    (data) => (data.size === 'large' ? layout : smallLayout),
    []
  )
  const keyPathCallback = React.useCallback((data) => data.key, [])
  const sizeCallback = React.useCallback(
    (data) =>
      data.size === 'large'
        ? { numRows: 10, numCols: 10 }
        : { numRows: 5, numCols: 5 },
    []
  )

  const onZoomContextChangeCallback = React.useCallback(
    (zoomContext) => setZoomContextStack(zoomContext ? [zoomContext] : []),
    []
  )

  const currentZoomContext = React.useMemo(() => {
    if (zoomContextStack.length === 0) {
      return undefined
    }

    const lastZoomContext = zoomContextStack[zoomContextStack.length - 1]
    if (lastZoomContext.path.length === 1) {
      return {
        path: lastZoomContext.path,
        //graphFrame: scaledGraphFrame(lastZoomContext.graphFrame, 1.15),
        graphFrame: scaledGraphFrame(lastZoomContext.graphFrame, zoomScale1),
        referenceFrame: lastZoomContext.graphFrame,
      }
    } else {
      return {
        path: lastZoomContext.path,
        //graphFrame: scaledGraphFrame(lastZoomContext.graphFrame, 1.4),
        graphFrame: scaledGraphFrame(lastZoomContext.graphFrame, zoomScale2),
        referenceFrame: lastZoomContext.graphFrame,
      }
    }
  }, [zoomContextStack])

  const loadMoreCallback = React.useCallback(() => {
    if (loadMore) {
      loadMore()
    }
  }, [loadMore])

  const fractalContainerZoomClass =
    (isSingleView && zoomContextStack.length > 1) ||
    (!isSingleView && !zoomContextStack.length !== 0)
      ? 'zoom-out'
      : ''

  return (
    <div
      className={'fractal-container ' + fractalContainerZoomClass}
      onClick={() => {
        if (fractalContainerZoomClass === 'zoom-out') {
          onZoomOutCallback()
        }
      }}
    >
      <FractalGraphView
        data={loadedData}
        factory={factoryCallback}
        layout={layoutCallback}
        keyPath={keyPathCallback}
        size={sizeCallback}
        onZoomContextChange={onZoomContextChangeCallback}
        loadMore={loadMoreCallback}
        zoomContext={currentZoomContext}
        columns={18}
        aspectRatio={16 / 9}
        margins={margins}
        onScroll={onScroll}
      ></FractalGraphView>

      <div
        style={{
          position: 'absolute',
          left: 8,
          top: 60,
          zIndex: 10,
          height: 20,
        }}
      >
        {zoomContextStack.length > 0 && dataEntries.length > 1 && (
          <Tooltip title="Back to top" placement="right">
            <IconButton
              color="primary"
              onClick={(e) => {
                e.stopPropagation()
                setZoomContextStack([])
              }}
            >
              <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 144 144"
                width="50px"
                height="50px"
              >
                <polyline
                  className="st0"
                  points="24.7,64.2 63.3,25.6 101.9,64.2 "
                />
                <polyline
                  className="st0"
                  points="117.9,108.4 63.4,108.4 63.4,33.9 "
                />
              </svg>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

function toDataType(data, tree) {
  return (child, index) => {
    if (child.vitlineId) {
      return {
        vitlineId: child.vitlineId,
        key: data.key + '/' + tree + '/' + index,
        path: [...data.path, `${index}`],
      }
    } else {
      return {
        vitId: child.vitId,
        key: data.key + '/' + tree + '/' + index,
        path: [...data.path, `${index}`],
      }
    }
  }
}

function filterMatcher(data) {
  return (child) => {
    return (
      (!child.vitId || data.path.indexOf(child.vitId) === -1) &&
      (!child.vitlineId || data.path.indexOf(child.vitlineId) === -1)
    )
  }
}

function flatten(arr) {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    )
  }, [])
}

function unique(arr) {
  const vitMap = {}
  const vitlineMap = {}

  return arr.filter((data) => {
    if (data.vitId) {
      if (vitMap[data.vitId]) {
        return false
      }
      vitMap[data.vitId] = true
    } else if (data.vitlineId) {
      if (vitlineMap[data.vitlineId]) {
        return false
      }
      vitlineMap[data.vitlineId] = true
    }
    return true
  })
}

const VitQueryMemo = React.memo(VitQuery)
function VitQuery({ vitId, data, children }) {
  const vit = VitsAPI.useVitData(vitId)
  const [saveVit, unsaveVit] = VitsAPI.useVitSaveMutation(vitId)
  const [hideVit] = VitsAPI.useVitHideMutation(vitId)

  const [vitAttach] = VitsAPI.useVitAttachMutation(vitId)
  const [userId] = VitsAPI.useUserId()

  const vitPlayerSequenceRef = React.useRef()

  const editVit = React.useCallback(() => {}, [vitId])

  if (vit) {
    if (vitPlayerSequenceRef.current) {
      const oldVit = vitPlayerSequenceRef.current[0]
      if (
        oldVit.start !== vit.start ||
        oldVit.end !== vit.end ||
        oldVit.videoId !== vit.videoId
      ) {
        vitPlayerSequenceRef.current = [vit]
      }
    } else {
      vitPlayerSequenceRef.current = [vit]
    }
  }

  const newData = React.useMemo(() => {
    if (vit) {
      const relatedVitlines = vit.vitlines.filter(filterMatcher(data))
      const sourceVits = vit.source.vits.filter(filterMatcher(data))
      const sortedAttaches = [...vit.attaches]
      sortedAttaches.sort((a, b) =>
        a.published.formatted.localeCompare(b.published.formatted)
      )

      const allChildren = unique([...relatedVitlines, ...sourceVits])
      const saved = vit.saves.find((save) => {
        return save.User.userId === userId
      })

      return {
        key: data.key,
        vitId: vit.vitId,
        path: data.path,
        title: vit.title,
        date: vit.published.formatted,
        user: '',
        image: thumbnailForData(vit),
        size: 'large',
        children: [
          [],
          allChildren.map(toDataType(data, 'related')),
          sortedAttaches
            .map((attach) => attach.vit)
            .map(toDataType(data, 'attaches')),
        ],
        playerSequence: vitPlayerSequenceRef.current,
        dataObj: vit,
        saved: saved,
        shareUrl: `https://vits.app/view/vit:${vit.vitId}`,
      }
    }

    return undefined
  }, [vit, userId])

  const authorizedUser = userId === 'nico' || userId === 'mirza' // || newData.vit.author.userId === userId

  return children({
    data: newData,
    save: saveVit,
    unsave: unsaveVit,
    attach: vitAttach,
    delete: authorizedUser ? hideVit : undefined,
    edit: authorizedUser ? editVit : undefined,
  })
}

const VitLineQueryMemo = React.memo(VitLineQuery)
function VitLineQuery({ vitlineId, data, children, fullyLoaded }) {
  const vitline = VitsAPI.useVitLineData(vitlineId)
  const vitlineSequenceRef = React.useRef()
  const [saveVitline, unsaveVitline] = VitsAPI.useVitlineSaveMutation(vitlineId)
  const [vitlineAttach] = VitsAPI.useVitLineAttachMutation(vitlineId)
  const [hideVitline] = VitsAPI.useVitLineHideMutation(vitlineId)
  const editVitline = React.useCallback(() => {}, [vitlineId])
  const [userId] = VitsAPI.useUserId()

  if (vitline) {
    const vitlineSequence = VitsAPI.vitsFromVitLine(vitline)
    if (vitlineSequenceRef.current) {
      if (vitlineSequenceRef.current.length !== vitlineSequence.length) {
        vitlineSequenceRef.current = vitlineSequence
      } else {
        for (let i = 0; i < vitlineSequence.length; i++) {
          const oldVit = vitlineSequenceRef.current[i]
          const newVit = vitlineSequence[i]
          if (
            oldVit.start !== newVit.start ||
            oldVit.end !== newVit.end ||
            oldVit.videoId !== newVit.videoId
          ) {
            vitlineSequenceRef.current = vitlineSequence
            break
          }
        }
      }
    } else {
      vitlineSequenceRef.current = vitlineSequence
    }
  }

  const newData = React.useMemo(() => {
    if (vitline) {
      const vits = VitsAPI.vitsFromVitLine(vitline)
      const relatedVitlines = unique(
        flatten(
          vits.map((vit) => {
            return vit.vitlines
          })
        ).filter(filterMatcher(data))
      )
      const sortedAttaches = [...vitline.attaches]
      sortedAttaches.sort((a, b) =>
        a.published.formatted.localeCompare(b.published.formatted)
      )
      const saved = vitline.saves.find((save) => {
        return save.User.userId === userId
      })

      return {
        key: data.key,
        vitlineId: vitline.vitlineId,
        path: data.path,
        title: vitline.title,
        date: vitline.published.formatted,
        user: vitline.author ? vitline.author.userId : '',
        image: thumbnailForData(vitline),
        size: 'large',
        playerSequence: vitlineSequenceRef.current,
        dataObj: vitline,
        children: [
          vits.map(toDataType(data, 'vitline')),
          relatedVitlines.map(toDataType(data, 'related')),
          sortedAttaches
            .map((attach) => attach.vit)
            .map(toDataType(data, 'attaches')),
        ],
        saved: saved,
        shareUrl: `https://vits.app/view/vitline:${vitline.vitlineId}`,
      }
    }
    return undefined
  }, [vitline, userId])

  const authorizedUser = userId === 'nico' || userId === 'mirza' // || (newData && userId && newData.user === userId)

  return children({
    data: newData ?? (fullyLoaded ? data : undefined),
    save: saveVitline,
    unsave: unsaveVitline,
    attach: vitlineAttach,
    delete: authorizedUser ? hideVitline : undefined,
    edit: authorizedUser ? editVitline : undefined,
  })
}
