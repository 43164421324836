import React from 'react'

export function RightArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 13.271 22.617"
    >
      <g
        id="Polygon_78"
        data-name="Polygon 78"
        transform="translate(13.271) rotate(90)"
        fill="#197e40"
      >
        <path
          d="M 20.45119667053223 12.27163696289062 L 2.165882110595703 12.27163696289062 L 11.30853462219238 1.541860103607178 L 20.45119667053223 12.27163696289062 Z"
          stroke="none"
        />
        <path
          d="M 11.30853366851807 3.083711624145508 L 4.331741333007812 11.27163696289062 L 18.28532028198242 11.27163696289062 L 11.30853366851807 3.083711624145508 M 11.30853462219238 -3.814697265625e-06 L 22.6170654296875 13.27163696289062 L -5.7220458984375e-06 13.27163696289062 L 11.30853462219238 -3.814697265625e-06 Z"
          stroke="none"
          fill="#197e40"
        />
      </g>
    </svg>
  )
}
