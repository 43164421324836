import { InMemoryCache, makeVar } from '@apollo/client'

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        draftVitline: {
          read() {
            return draftVitline()
          },
        },
      },
    },
  },
})

/**
 * Set initial values when we create cache variables.
 */
export const vitlineInitialValue = {
  __typename: 'VitLine',
  vitlineId: 'draft',
  title: '',
  vitIndexes: [],
  author: { userId: '' },
}

export const draftVitline = makeVar(vitlineInitialValue)
