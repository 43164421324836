import React from 'react'

import { GraphMapping } from './GraphMapping'

let assert = require('assert')

export const GraphContext = React.createContext({})

export const pathEquals = (path1, path2) => {
  if (path1 === path2) {
    return true
  }
  if (!path1 || !path2) {
    return false
  }

  if (path1.length !== path2.length) {
    return false
  }

  for (let i = 0; i < path1.length; i++) {
    if (path1[i].localeCompare(path2[i]) !== 0) {
      return false
    }
  }

  return true
}

export const frameForNode = (root, layout, subPath, childLayoutConfig) => {
  if (!subPath || subPath.length === 0) {
    let [startNodePoint, endNodePoint] = nodePointForConfig(layout)
    return [
      startNodePoint[0],
      startNodePoint[1],
      endNodePoint[0] - startNodePoint[0] + 1,
      endNodePoint[1] - startNodePoint[1] + 1,
    ]
  }
  let childrenToSearch = root.props.children
  const childPoints = childPointsForConfig(layout)
  for (let childIndex = 0; childIndex < childrenToSearch.length; childIndex++) {
    const childNode = childrenToSearch[childIndex]
    if (childNode.props.id === subPath[0]) {
      //found child
      const [
        childFrameRow,
        childFrameCol,
        childFrameRows,
        childFrameCols,
      ] = frameForNode(
        childNode,
        childLayoutConfig,
        subPath.slice(1),
        childLayoutConfig
      )

      const row =
        childPoints[childIndex][0] + childFrameRow / childLayoutConfig.length
      const col =
        childPoints[childIndex][1] + childFrameCol / childLayoutConfig[0].length
      const rows = childFrameRows / childLayoutConfig.length
      const cols = childFrameCols / childLayoutConfig[0].length

      return [row, col, rows, cols]
    }
  }
}

export const frameForPath = (root, layout, subPath, childLayoutConfig) => {
  if (!subPath || subPath.length === 0) {
    return [0, 0, layout.length, layout[0].length]
  }

  let childrenToSearch = root.props.children

  const childPoints = childPointsForConfig(layout)
  for (let childIndex = 0; childIndex < childrenToSearch.length; childIndex++) {
    const childNode = childrenToSearch[childIndex]
    if (childNode.props.id === subPath[0]) {
      //found child
      const [
        childFrameRow,
        childFrameCol,
        childFrameRows,
        childFrameCols,
      ] = frameForPath(
        childNode,
        childLayoutConfig,
        subPath.slice(1),
        childLayoutConfig
      )

      const row =
        childPoints[childIndex][0] + childFrameRow / childLayoutConfig.length
      const col =
        childPoints[childIndex][1] + childFrameCol / childLayoutConfig[0].length
      const rows = childFrameRows / childLayoutConfig.length
      const cols = childFrameCols / childLayoutConfig[0].length

      return [row, col, rows, cols]
    }
  }
}

export const nodePointForConfig = (
  layoutConfig,
  mappingToSearch = GraphMapping.FILLED
) => {
  let startNodePoint = null
  let endNodePoint = null

  for (let row = 0; row < layoutConfig.length; row++) {
    for (let col = 0; col < layoutConfig[row].length; col++) {
      if (layoutConfig[row][col] === mappingToSearch) {
        if (!startNodePoint) {
          startNodePoint = [row, col]
        }
        endNodePoint = [row, col]
      }
    }
  }
  return [startNodePoint, endNodePoint]
}

export const childPointsForConfig = (layoutConfig, length) => {
  const childPoints = []
  const cornerLessChildPoints = []

  for (let row = 0; row < layoutConfig.length; row++) {
    for (let col = 0; col < layoutConfig[row].length; col++) {
      if (layoutConfig[row][col] === GraphMapping.CHILD) {
        if (
          (row === 0 && (col === 0 || col === layoutConfig[0].length - 1)) ||
          (row === layoutConfig.length - 1 &&
            (col === 0 || col === layoutConfig[0].length - 1))
        ) {
          childPoints.push([row, col])
        } else {
          childPoints.push([row, col])
          cornerLessChildPoints.push([row, col])
        }
      }
    }
  }

  if (length != undefined && length <= cornerLessChildPoints.length) {
    return cornerLessChildPoints
  }

  return childPoints
}

export const mappingAt = (map, row, col) => {
  const gridColumns = map.length > 0 ? map[0].length : 0
  if (row < 0 || col < 0 || col >= gridColumns) {
    return GraphMapping.FILLED
  }

  if (row >= map.length) {
    return GraphMapping.EMPTY
  }

  return map[row][col]
}

export const doesLayoutFit = (map, layout, startRow, startCol) => {
  for (let row = 0; row < layout.length; row++) {
    for (let col = 0; col < layout[row].length; col++) {
      if (layout[row][col] === GraphMapping.FILLED) {
        if (
          mappingAt(map, row + startRow, col + startCol) !== GraphMapping.EMPTY
        ) {
          return false
        }
      }

      if (
        layout[row][col] === GraphMapping.SHARED ||
        layout[row][col] === GraphMapping.CHILD
      ) {
        if (
          mappingAt(map, row + startRow, col + startCol) ===
            GraphMapping.FILLED ||
          mappingAt(map, row + startRow, col + startCol) ===
            GraphMapping.RESERVED
        ) {
          return false
        }
      }
    }
  }

  return true
}

export const fillLayoutOnMap = (map, layout, startRow, startCol) => {
  const gridColumns = map[0].length
  for (let row = 0; row < layout.length; row++) {
    for (let col = 0; col < layout[row].length; col++) {
      const mapRow = startRow + row
      const mapCol = startCol + col

      if (mapRow >= map.length) {
        map.push(Array(gridColumns).fill(GraphMapping.EMPTY))
      }

      if (layout[row][col] !== GraphMapping.EMPTY) {
        if (layout[row][col] === GraphMapping.FILLED) {
          assert(mappingAt(map, mapRow, mapCol) === GraphMapping.EMPTY)
        }
        map[mapRow][mapCol] = layout[row][col]
      }
    }
  }
}
