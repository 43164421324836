import React from 'react'

import VitsAPI from './api/VitsAPI'
import { ThumbnailView } from './ThumbnailView'
import NodeCardView from './views/graph/nodes/NodeCardView'
import AspectRatio from 'r-aspect-ratio'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useDrag } from 'react-dnd'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import EditIcon from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'

import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

function AlertDialog({ open, onConfirmation, onCancel, type }) {
  return (
    <div>
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle>{'Deleting Vitline'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to permantently delete your {type}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="secondary">
            Cancel
          </Button>
          <Button onClick={onConfirmation} color="danger" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default function Collect() {
  const user = VitsAPI.useUserData()

  if (!user) {
    return null
  }

  return <CollectRenderer user={user}></CollectRenderer>

  /*
  const userRenderer = React.useMemo(() => {
    if (user) {
      return <CollectRenderer user={user}></CollectRenderer>
    }
    return null
  }, [user])
  */

  //return userRenderer
}

const aspectRatio = '11 / 16'

function CollectCardRenderer(props) {
  const { vit, vits, title, typename } = props
  const firstVit = vits ? vits[0] : vit

  const image = VitsAPI.thumbnailForData(firstVit)

  const [collected, drag, preview] = useDrag(
    () => ({
      type: 'vit',
      item: { data: vits ?? [vit], image: image },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    [vits, vit, image]
  )

  React.useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  return (
    <div
      ref={drag}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Link to={props.url}>
        <>
          <div
            className={'node'}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '80%',
            }}
          >
            <ThumbnailView image={image} showClip={typename === 'Vit'} />
          </div>
          <div
            className={'info'}
            style={{
              position: 'absolute',
              top: '80%',
              left: 0,
              width: '100%',
              height: '20%',
            }}
          >
            {firstVit ? (
              <div className={'info-box'}>
                <div className="info-box-title info-box-title-text">
                  <div className={'max-lines-sm'}>{title}</div>
                </div>
              </div>
            ) : (
              <div className="info-box">
                <div className="info-box-title info-box-title-text">
                  <div className="max-lines-sm">EMPTY</div>
                </div>
              </div>
            )}
          </div>
        </>
      </Link>
    </div>
  )
}

function VitNode({ vit, type }) {
  const history = useHistory()
  const [showDialog, setShowDialog] = React.useState(false)
  const [, unsaveVit] = VitsAPI.useVitSaveMutation(vit.vitId)
  const deleteVit = VitsAPI.useVitDeleteMutation(vit.vitId)

  const clearMap = {
    fav: unsaveVit,
    published: () => {
      setShowDialog(true)
    },
  }

  const editMap = {
    published: () => {
      history.push('/edit/vit:' + vit.vitId)
    },
  }

  return (
    <CollectNode
      onClear={clearMap[type] ?? undefined}
      onEdit={editMap[type] ?? undefined}
    >
      <AlertDialog
        type={'vit'}
        open={showDialog}
        onConfirmation={() => {
          setShowDialog(false)
          deleteVit()
        }}
        onCancel={() => {
          setShowDialog(false)
        }}
      ></AlertDialog>
      <CollectCardRenderer
        typename={vit.__typename}
        title={vit.title}
        url={'/view/vit:' + vit.vitId}
        vit={vit}
        minimal
      ></CollectCardRenderer>
    </CollectNode>
  )
}

function VitlineNode({ vitline, type }) {
  const [showDialog, setShowDialog] = React.useState(false)

  const vits = VitsAPI.vitsFromVitLine(vitline)
  const [, unsaveVitline] = VitsAPI.useVitlineSaveMutation(vitline.vitlineId)
  const deleteVitline = VitsAPI.useVitLineDeleteMutation(vitline.vitlineId)

  const clearMap = {
    fav: unsaveVitline,
    draft: () => {
      setShowDialog(true)
    },
    published: () => {
      setShowDialog(true)
    },
  }

  const editMap = {
    draft: () => {
      window.postMessage(
        {
          vitlineId: vitline.vitlineId,
        },
        '*'
      )
    },
    published: () => {
      window.postMessage(
        {
          vitlineId: vitline.vitlineId,
        },
        '*'
      )
    },
  }

  return (
    <CollectNode
      onClear={clearMap[type] ?? undefined}
      onEdit={editMap[type] ?? undefined}
    >
      <AlertDialog
        type={'vitline'}
        open={showDialog}
        onConfirmation={() => {
          setShowDialog(false)
          deleteVitline()
        }}
        onCancel={() => {
          setShowDialog(false)
        }}
      ></AlertDialog>
      {vits.length > 0 && (
        <CollectCardRenderer
          typename={vitline.__typename}
          title={vitline.title}
          url={'/view/vitline:' + vitline.vitlineId}
          vits={VitsAPI.vitsFromVitLine(vitline)}
          minimal
        ></CollectCardRenderer>
      )}

      {vits.length == 0 && (
        <CollectCardRenderer
          typename={vitline.__typename}
          imageUrl={'/img/empty.png'}
          minimal
        ></CollectCardRenderer>
      )}
    </CollectNode>
  )
}

function CollectNode({ children, onClear, onEdit }) {
  return (
    <div
      className={'overlay-container'}
      style={{
        position: 'relative',
        width: 'calc(130px)',
        height: `calc(130px * (${aspectRatio}))`,
        margin: '8px',
      }}
    >
      {children}

      {onEdit && (
        <div
          className={'overlay-button'}
          style={{
            position: 'absolute',
            left: '-4px',
            top: '-4px',
            width: '16px',
            height: '16px',
            cursor: 'pointer',
            zIndex: 10,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<EditIcon />}
            onClick={onEdit}
          >
            Edit
          </Button>
        </div>
      )}

      {onClear && (
        <img
          className={'overlay-button'}
          style={{
            position: 'absolute',
            right: '0',
            top: '0',
            width: '16px',
            height: '16px',
            cursor: 'pointer',
            zIndex: 10,
          }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onClear()
          }}
          src="/img/clear_box.png"
        ></img>
      )}
    </div>
  )
}

function CollectRenderer({ user }) {
  return <CustomizedAccordions user={user}></CustomizedAccordions>
}

const Accordion = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',

    border: '0px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {
    display: 'flex',
    minHeight: '0px',
    flexGrow: 1,
    flexDirection: 'column',
  },
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'white',
    borderBottom: '0px',
    marginBottom: -1,
    minHeight: 36,
    '&$expanded': {
      minHeight: 36,
    },
    padding: '0px',
  },
  content: {
    margin: '0 0',
    '&$expanded': {
      margin: '0 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    minHeight: '0px',
    overflow: 'hidden scroll',
    height: '100%',
  },
}))(MuiAccordionDetails)

function CustomizedAccordions({ user }) {
  const [expanded, setExpanded] = React.useState('panel1')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const containerRef = React.useRef()

  /*
  React.useEffect(() => {
    const noScrollWheel = (event) => {
      debugger

      event.preventDefault()
      event.stopPropagation()
    }
    containerRef.current.addEventListener('wheel', noScrollWheel, false)

    return () => {
      containerRef.current.removeEventListener('wheel', noScrollWheel)
    }
  })*/

  return (
    <div
      ref={containerRef}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexGrow: 1,
        minHeight: '0px',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <Accordion
        square
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <div style={{ width: '100%' }}>
            <hr className="heading"></hr>
            <div className="heading-2-character-style">MY FAVOURITES</div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <UserFavsSubFeed userId={user.userId}></UserFavsSubFeed>
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <div style={{ width: '100%' }}>
            <hr className="heading"></hr>
            <div className="heading-2-character-style">MY VITLINE DRAFTS</div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <UserDraftVitlines userId={user.userId}></UserDraftVitlines>
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <div style={{ width: '100%' }}>
            <hr className="heading"></hr>
            <div className="heading-2-character-style">
              MY PUBLISHED VITLINES
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <UserPublishedVitlines userId={user.userId}></UserPublishedVitlines>
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel4d-header">
          <div style={{ width: '100%' }}>
            <hr className="heading"></hr>
            <div className="heading-2-character-style">MY VITS</div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <UserVitsSubFeed userId={user.userId}></UserVitsSubFeed>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

function UserDraftVitlines({ userId }) {
  const [data, fetchMore] = VitsAPI.useUserSubFeed(
    userId,
    'getUserDraftVitlines'
  )

  return (
    <UserSubFeed
      renderFn={(vitline, index) => {
        return (
          <VitlineNode
            key={vitline.vitlineId + '-' + index}
            vitline={vitline}
            type={'draft'}
          ></VitlineNode>
        )
      }}
      data={data}
      fetchMore={fetchMore}
    ></UserSubFeed>
  )
}

function UserPublishedVitlines({ userId }) {
  const [data, fetchMore] = VitsAPI.useUserSubFeed(
    userId,
    'getUserPublishedVitlines'
  )

  return (
    <UserSubFeed
      renderFn={(vitline, index) => {
        return (
          <VitlineNode
            key={vitline.vitlineId + '-' + index}
            vitline={vitline}
            type={'published'}
          ></VitlineNode>
        )
      }}
      data={data}
      fetchMore={fetchMore}
    ></UserSubFeed>
  )
}

function UserFavsSubFeed({ userId }) {
  const [data, fetchMore] = VitsAPI.useUserSubFeed(userId, 'getUserFavs')

  return (
    <UserSubFeed
      renderFn={(data) => {
        if (data.__typename === 'VitLine') {
          const vitline = data

          return (
            <VitlineNode
              key={vitline.vitlineId}
              vitline={vitline}
              type={'fav'}
            ></VitlineNode>
          )
        } else if (data.__typename === 'Vit') {
          const vit = data
          return <VitNode key={vit.vitId} vit={vit} type={'fav'}></VitNode>
        }
      }}
      data={data}
      fetchMore={fetchMore}
    ></UserSubFeed>
  )
}

function UserVitsSubFeed({ userId }) {
  const [data, fetchMore] = VitsAPI.useUserSubFeed(userId, 'getUserVits')

  return (
    <UserSubFeed
      renderFn={(data) => {
        return (
          <VitNode key={data.vitId} vit={data} type={'published'}></VitNode>
        )
      }}
      data={data}
      fetchMore={fetchMore}
    ></UserSubFeed>
  )
}

function UserSubFeed({ renderFn, data, fetchMore }) {
  if (!data) {
    return null
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
        }}
      >
        {data.map((dataEntry, index) => {
          return renderFn(dataEntry, index)
        })}
      </div>
      <Button color="primary" onClick={fetchMore}>
        Load more
      </Button>
    </div>
  )
}
