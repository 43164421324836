import React from 'react'

export default function About() {
  console.log('ABOUT PAGE')
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: 'visible scroll',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
          paddingLeft: '32px',
          paddingRight: '32px',
          color: 'black',
        }}
      >
        <div className="heading-1-character-style">ABOUT US</div>
        <br />
        <div className="heading-2-character-style">What is Vits.app?</div>
        <p className="unnamed-character-style-6" style={{ color: 'black' }}>
          Vits.app is an experimental video aggregating + synthesizing tool that
          allows a user to:
          <ul>
            <li>Sample clips/parts of existing YouTube content</li>
            <li>
              Assemble those clips/parts together in order to:
              <ul>
                <li>
                  Intentionally aggregate a chosen selection of clips/pieces,
                  around one single clip
                </li>
                <li>
                  Use clips to create remixed and sequenced video productions
                  called VITLINES which are analogous to video mashups.
                </li>
              </ul>
            </li>
          </ul>
        </p>
        <div className="heading-2-character-style">What is a Vit?</div>
        <p className="unnamed-character-style-6" style={{ color: 'black' }}>
          Each clip or "sample" that's created from an original YouTube video is
          called a "vit", like a “bit” of data but video, and is a maximum of 90
          seconds long. Vits are embedded from YouTube not hosted on Vits.app.
        </p>
        <div className="heading-2-character-style">Is this legal?</div>
        <p className="unnamed-character-style-6" style={{ color: 'black' }}>
          Each vit that's brought into the platform retains its filiation to the
          original YouTube video from which it was sampled, meaning users can
          easily jump into the full original YouTube video with one click.
          Playback is accomplished by using the publicly available YouTube
          Embedded API, and Youtube provides creators with the ability to
          control whether their videos can be embedded.
          <br />
          <br />
          Vits.app is the ethical solution to creative reuse of the large swath
          of wonderful YouTube content without "stealing" or depriving creators
          of the attribution.
        </p>
        <div className="heading-2-character-style">
          How does Vits.app challenge online misinformation & disinformation?
        </div>
        <p className="unnamed-character-style-6" style={{ color: 'black' }}>
          One of the Vits App's intended purposes is to help address online
          mis/disinformation by making it possible to surround each piece of
          info/media that appears in the app, including content that would be
          qualified as mis/disinformation, with other, diverse enough pieces of
          info/media, that can act as meaningful context.
          <br />
          The resulting *polyphony* (of complementary, contrasting, divergent
          voices and content) should help curb most users' tendency to believe
          or take isolated pieces of info at face value, and maybe reduce their
          vulnerability to being misled by it, while also increasing their
          exposure to voices and media content that are new and/or different
          from what they habitually consume. This could help undermine and open
          up thought silos/echo chambers.
        </p>

        <div className="heading-2-character-style">
          "How can we counter disinformation?" Challenge
        </div>
        <p className="unnamed-character-style-6" style={{ color: 'black' }}>
          Vits App's initial prototype version was created with funding received
          from the organizers & partners of the{' '}
          <a
            href="https://socialsciences.uottawa.ca/how-counter-disinformation"
            target="_blank"
          >
            How can we counter disinformation?" Challenge
          </a>{' '}
          that took place in June-December 2020.
        </p>
        <div className="heading-2-character-style">
          Vits App team & Contact info
        </div>
        <p className="unnamed-character-style-6" style={{ color: 'black' }}>
          <div>Nicolas Rutherford (Project creator and lead)</div>
          <div>Matthew Zucca (Assistant lead)</div>
          <div>Mirza Pasalic (Engineering lead)</div>
          <div>
            Stéfanie Vermeersch (UX/UI designer for version 1.0 of the Vits
            prototype)
          </div>
          <br />
          <div>
            Email:{' '}
            <a href="mailto:vits.mediasphere@gmail.com">
              vits.mediasphere@gmail.com
            </a>{' '}
            Twitter:{' '}
            <a target="_blank" href="https://twitter.com/Vits_app">
              @Vits_app
            </a>
          </div>
        </p>

        <div className="heading-2-character-style">
          The Challenge Organizers & Partners:
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
          }}
        >
          <img
            style={{ display: 'flex', maxWidth: '20%', objectFit: 'contain' }}
            src="/img/Otta_en_Office_Farbe_en.png"
          ></img>
          <img
            style={{ display: 'flex', maxWidth: '15%', objectFit: 'contain' }}
            src="/img/logoAmb-Canada.jpg"
          ></img>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
          }}
        >
          <img
            style={{ display: 'flex', maxWidth: '15%', objectFit: 'contain' }}
            src="/img/national-film-board-of-canada-nfb-logo-vector.png"
          ></img>

          <img
            style={{ display: 'flex', maxWidth: '15%', objectFit: 'contain' }}
            src="/img/uOttawa.png"
          ></img>
          <img
            style={{ display: 'flex', maxWidth: '15%', objectFit: 'contain' }}
            src="/img/CCUNESCO_logo_RGB_BI-ENG.png"
          ></img>
        </div>
      </div>
    </div>
  )
}
