import React, { useEffect, useState, useRef } from 'react'

import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import request from 'superagent'

const GET_VITS_NO_THUMBS = gql`
  {
    vitsNoThumbs {
      vitId
      start
      end
      source {
        videoId
      }
    }
  }
`

const VIT_THUMB_MUTATION = gql`
  mutation addVitThumbnail(
    $time: Float!
    $videoId: ID!
    $thumbnailUrl: String!
  ) {
    newVitThumbnail(
      time: $time
      videoId: $videoId
      thumbnailUrl: $thumbnailUrl
    ) {
      thumbnailId
    }
  }
`

export default function ThumbnailGenerator() {
  const { loading, error, data } = useQuery(GET_VITS_NO_THUMBS)
  const [vitIndex, setVitIndex] = useState(0)

  if (error) return <p>Error</p>
  if (loading) return <p>Loading</p>

  const handleDone = () => {
    setVitIndex(vitIndex + 1)
  }

  const nodes = []
  data.vitsNoThumbs.forEach((e) => {
    if (e.__typename === 'Vit') {
      nodes.push(
        <VitThumbnailGenerator
          vit={e}
          onDone={handleDone}
        ></VitThumbnailGenerator>
      )
    }
  })

  console.log('Rendering ' + vitIndex + ' of ' + nodes.length)
  return <div>{vitIndex < nodes.length && nodes[vitIndex]}</div>
}

function VitThumbnailGenerator({ vit, onDone }) {
  const [result, setResult] = useState('')
  const [source, setSource] = useState('')

  const [setVitThumb] = useMutation(VIT_THUMB_MUTATION)

  const canvasRef = useRef()
  const videoRef = useRef()
  const imageRef = useRef()

  const putImage = () => {
    var canvas = canvasRef.current
    if (canvas.getContext) {
      //canvas.toDataURL("image/png"

      const url = `https://api.cloudinary.com/v1_1/de7ts8nlf/upload`

      request
        .post(url)
        .field('upload_preset', 'iynoi1wr')
        .field('file', canvas.toDataURL('image/png'))
        .field('multiple', false)
        .on('progress', () => {})
        .end((error, response) => {
          console.log('uploaded! ' + JSON.stringify(response))
          setVitThumb({
            variables: {
              time: vit.start,
              videoId: vit.source.videoId,
              thumbnailUrl: response.body.url,
            },
          })
          onDone()
        })
    }
  }

  useEffect(() => {
    console.log('Fetching ' + vit.source.videoId)
    fetch(
      'https://at8u8s124e.execute-api.eu-west-1.amazonaws.com/prod?video_id=' +
        vit.source.videoId
    )
      .then((res) => res.text())
      .then(
        (result) => {
          setResult(result)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log('error ' + error)
        }
      )
  }, [vit])

  useEffect(() => {
    try {
      if (result) {
        const decodeQueryString = (queryString) => {
          var key, keyValPair, keyValPairs, r, val, _i, _len
          r = {}
          keyValPairs = queryString.split('&')
          for (_i = 0, _len = keyValPairs.length; _i < _len; _i++) {
            keyValPair = keyValPairs[_i]
            key = decodeURIComponent(keyValPair.split('=')[0])
            val = decodeURIComponent(keyValPair.split('=')[1] || '')
            if (key == 'url') {
              var urlParams = new URLSearchParams(val)
              var lsig = urlParams.get('lsig')
              r.lsig = lsig
            }
            r[key] = val
          }
          return r
        }

        const decodeStreamMap = (player_response) => {
          var quality, sources, stream, type, _i, _len, _ref
          sources = {}
          console.log(player_response)
          _ref = player_response.streamingData.formats
          for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            stream = _ref[_i]
            console.log(stream.url)
            type = stream.mimeType.split(';')[0]
            quality = stream.quality
            stream.original_url = stream.url
            //stream.url = "" + stream.url + "&lsig=" + stream.lsig;

            sources['' + type + ' ' + quality] = stream
          }
          return sources
        }

        var video = decodeQueryString(result)
        var playerResponse = JSON.parse(video.player_response)
        if (playerResponse.playabilityStatus.status === 'UNPLAYABLE') {
          onDone()
          return
        }
        video.sources = decodeStreamMap(playerResponse)
        video.getSource = (type, quality) => {
          var exact, key, lowest, source, _ref
          lowest = null
          exact = null
          _ref = video.sources
          console.log(_ref)
          for (key in _ref) {
            source = _ref[key]
            if (source.mimeType.includes(type)) {
              if (source.quality.match(quality)) {
                exact = source
              } else {
                lowest = source
              }
            }
          }

          return exact || lowest
        }

        console.log(video)

        var t = video.getSource('video/webm', 'hd720')
        console.log('WebM: ' + t)
        var n = video.getSource('video/mp4', 'hd720')
        console.log('MP4: ' + n.url)
        if (!n.url) {
          onDone()
        }
        setSource(
          n.url +
            '#' +
            (Math.ceil(vit.start) + 1) +
            ',' +
            (Math.ceil(vit.start) + 2)
        )
      }
    } catch (e) {
      onDone()
    }
  }, [result])

  //$("<video id='vid' controls='controls'/>").attr("src", n.url + "#t=10,20").appendTo("#ytvideo");
  return (
    <div>
      <p>
        id: {vit.vitId} start: {vit.start}
      </p>
      <div id="ytvideo"></div>
      {source && (
        <video
          crossOrigin="anonymous"
          ref={videoRef}
          id="vid"
          controls="controls"
          src={source}
          onError={() => {
            onDone()
          }}
          onLoadedMetadata={() => {
            let ratio =
              videoRef.current.videoWidth / videoRef.current.videoHeight
            let myWidth = videoRef.current.videoWidth - 100
            let myHeight = parseInt(myWidth / ratio, 10)
            canvasRef.current.width = myWidth
            canvasRef.current.height = myHeight
            videoRef.current.currentTime = vit.start
          }}
          onLoadedData={() => {
            setTimeout(() => {
              let ratio =
                videoRef.current.videoWidth / videoRef.current.videoHeight
              let myWidth = videoRef.current.videoWidth - 100
              let myHeight = parseInt(myWidth / ratio, 10)
              var context = canvasRef.current.getContext('2d')
              context.fillRect(0, 0, myWidth, myHeight)
              context.drawImage(videoRef.current, 0, 0, myWidth, myHeight)

              putImage()
            }, 1000)
          }}
        ></video>
      )}
      <canvas ref={canvasRef} id="canvas" width="600" height="300"></canvas>
      <img ref={imageRef} id="canvas-img"></img>
    </div>
  )
}
