import React from 'react'

//import { Link } from 'react-router-dom'
import { GraphContext, pathEquals } from '../GraphHelpers'
import GraphNode from './GraphNode'
import VitsAPI from '../../../api/VitsAPI'
//import YouTube from 'react-youtube'
import NodeCardView from './NodeCardView'

export default function YouTubeVideoNode({
  videoId,
  urlPrefix,
  graphConfig,
  getChildren,
}) {
  const mapContext = React.useContext(GraphContext)
  const youtubeVideo = VitsAPI.useYouTubeVideoData(videoId)

  const isSelected = pathEquals(graphConfig.path, mapContext.currentPath)

  const renderer = React.useMemo(() => {
    let url = urlPrefix + graphConfig.path.join('/')
    if (youtubeVideo) {
      let image =
        'https://img.youtube.com/vi/' + youtubeVideo.videoId + '/0.jpg'
      return (
        <div>
          <NodeCardView
            typename={youtubeVideo.__typename}
            color={'secondary'}
            title={'YouTube: Some title goes here'}
            imageUrl={image}
            url={url}
            elevation={3}
            videoId={youtubeVideo.videoId}
            mediaOnly
          ></NodeCardView>
          <div
            className="heading-character-style"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              opacity: isSelected ? '0.0' : '1.0',
              transition: 'opacity 0.2s ease-in-out',
              pointerEvents: 'none',
              backgroundColor: 'rgb(0, 0, 0, 0.5)',
              fontSize: '100px',
              color: 'white',

              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            SOURCE
          </div>
        </div>
      )
    }
    return null
  }, [youtubeVideo, isSelected])

  const infoRenderer = React.useMemo(() => {
    let url = urlPrefix + graphConfig.path.join('/')
    if (youtubeVideo) {
      let image =
        'https://img.youtube.com/vi/' + youtubeVideo.videoId + '/0.jpg'
      return (
        <NodeCardView
          typename={youtubeVideo.__typename}
          color={'secondary'}
          title={'YouTube: Some title goes here'}
          imageUrl={image}
          url={url}
          shareUrl={`https://vits.app/view/video:${videoId}`}
          elevation={3}
          videoId={youtubeVideo.videoId}
          infoOnly
        ></NodeCardView>
      )
    }
    return null
  }, [youtubeVideo])

  return (
    <GraphNode
      graphConfig={graphConfig}
      childData={youtubeVideo ? getChildren(youtubeVideo) : undefined}
      renderer={renderer}
      infoRenderer={infoRenderer}
      urlPrefix={urlPrefix}
    ></GraphNode>
  )
}

/*
function YouTubeVideoNodeRenderer({ youtubeVideo, urlPrefix, graphConfig }) {
  const mapContext = React.useContext(GraphContext)
  const isSelected = pathEquals(mapContext.currentPath, graphConfig.path)

  let url = urlPrefix + graphConfig.path.join('/')
  let image = '/loader.gif'
  if (youtubeVideo.videoId) {
    image = 'https://img.youtube.com/vi/' + youtubeVideo.videoId + '/0.jpg'
  }

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
      }}
    >
      <div className="vitTitle">
        <Link to={url}>Source</Link>
      </div>
      <Link style={{ width: '100%', height: '100%' }} to={url}>
        <div
          onClick={() => {}}
          className={'youtubeThumbnail'}
          style={{
            backgroundImage: `url(${image})`,
          }}
        ></div>
        {isSelected && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
            }}
          >
            <YouTube videoId={youtubeVideo.videoId} />
          </div>
        )}
      </Link>
    </div>
  )
}*/
