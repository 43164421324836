import React, { useEffect } from 'react'

import VitsAPI from '../../api/VitsAPI'
import { useHistory } from 'react-router'
import VitPreview from '../VitPreview'
import { useAuth0 } from '@auth0/auth0-react'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import { useDrop } from 'react-dnd'
import { VitSpringTimeline } from './VitSpringTimeline'

const USE_TAGS = false

export default function VitlineBuilder() {
  const history = useHistory()
  const [vitlineId, setVitlineId] = React.useState(null)

  useEffect(() => {
    const handler = (event) => {
      const data = event.data
      if (data.vitlineId) {
        setVitlineId(data.vitlineId)
      }
    }

    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  }, [])

  useEffect(() => {
    if (history) {
      const pathParts = history.location.pathname.split('/')
      if (pathParts.length >= 3) {
        if (pathParts[1] === 'edit') {
          if (pathParts[2] === 'vitline:draft') {
            setVitlineId(null)
          } else {
            const [type, id] = pathParts[2].split(':')
            if (type === 'vitline') {
              setVitlineId(id)
            }
          }
        }
      }
    }
  }, [history.location.pathname])

  return (
    <VitForm
      vitlineId={vitlineId}
      onCreate={(vitlineId) => {
        setVitlineId(vitlineId)
      }}
      onPublish={() => {
        setVitlineId(null)
        history.push('/')
      }}
    ></VitForm>
  )
}

function VitForm({ vitlineId, onCreate, onPublish }) {
  const [
    draft,
    draftVits,
    setDraftVits,
    clearDraft,
    setDraftTitle,
  ] = VitsAPI.useVitlineDraft()
  const { user, isAuthenticated, loginWithPopup } = useAuth0()
  const userId = isAuthenticated ? user['https://vits.app/username'] : null

  let draftVitsVariables = []
  draftVits.forEach((vit, index) => {
    if (vit) {
      draftVitsVariables.push({ vitId: vit.vitId, index: index })
    }
  })

  const [newVitline, newVitlineId] = VitsAPI.useNewVitLineMutation()

  useEffect(() => {
    if (newVitlineId) {
      // Clear draft once we made our vitline
      clearDraft()
      onCreate(newVitlineId)
    }
  }, [newVitlineId])

  if (vitlineId && vitlineId !== draft.vitlineId) {
    return (
      <VitlineFormLoader
        vitlineId={vitlineId}
        onPublish={onPublish}
      ></VitlineFormLoader>
    )
  }

  return (
    <VitlineForm
      vitline={draft}
      actionText={'SAVE DRAFT'}
      onAction={() => {
        if (!user) {
          loginWithPopup()
          return
        }

        newVitline({
          variables: {
            vitInputs: draftVitsVariables,
            title: draft.title,
            userId: userId,
          },
        })
      }}
      onVitsChange={(newVitArray) => {
        setDraftVits(newVitArray)
      }}
      onTitleChange={(newTitle) => {
        setDraftTitle(newTitle)
      }}
    ></VitlineForm>
  )
}

function validateVitline(vitline) {
  if (!vitline.title || vitline.title.length === 0) {
    return 'Title is required'
  }

  return undefined
}

function VitlineFormLoader({ vitlineId, onPublish }) {
  const vitline = VitsAPI.useVitLineQuery(vitlineId)

  const isPublished =
    vitline && vitline.published && vitline.published.formatted

  const updateVitlineTitle = VitsAPI.useVitLineTitleMutation(vitlineId)
  const updateVitlineVits = VitsAPI.useVitLineVitsMutation(vitlineId)
  const publishVitLine = VitsAPI.useVitLinePublishMutation(vitlineId)
  const unpublishVitline = VitsAPI.useVitLineUnpublishMutation(vitlineId)

  if (vitline) {
    return (
      <VitlineForm
        vitline={vitline}
        disabled={isPublished}
        actionText={isPublished ? 'UNPUBLISH' : 'PUBLISH'}
        actionError={validateVitline(vitline)}
        onAction={async () => {
          await (isPublished ? unpublishVitline : publishVitLine)()
          if (!isPublished) {
            onPublish()
          }
        }}
        onVitsChange={(newVitArray) => {
          const vitInputs = newVitArray
            .map((vit, index) => {
              if (vit) {
                return {
                  vitId: vit.vitId,
                  index: index,
                  vit: vit,
                }
              } else {
                return null
              }
            })
            .filter((input) => !!input)
          updateVitlineVits(vitInputs)
        }}
        onTitleChange={(newTitle) => {
          updateVitlineTitle(newTitle)
        }}
      ></VitlineForm>
    )
  }

  return null
}

function VitlineForm({
  vitline,
  disabled,
  actionText,
  actionError,
  onAction,
  onVitsChange,
  onTitleChange,
}) {
  console.log(vitline)
  const history = useHistory()
  const [title, setTitle] = React.useState(vitline.title)

  React.useEffect(() => {
    if (title !== vitline.title) {
      setTitle(vitline.title)
    }
  }, [vitline.title])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexGrow: '1',
            padding: '16px 32px 16px 32px',
          }}
        >
          <label
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
            }}
            className="heading-2-character-style"
          >
            TITLE
            <input
              disabled={disabled}
              style={{
                minWidth: '200px',
                maxWidth: '800px',
                width: '100%',
                marginLeft: '16px',
              }}
              className="rounded-box code-character-style"
              type="text"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value)
                onTitleChange(e.target.value)
              }}
            ></input>
          </label>
        </div>

        {USE_TAGS && (
          <div
            style={{
              display: 'flex',
              flexGrow: '1',
              padding: '16px 32px 16px 32px',
            }}
          >
            <label
              style={{
                width: '100%',
                minWidth: '200px',
                maxWidth: '800px',

                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
              }}
              className="heading-2-character-style"
            >
              TAGS
              <input
                style={{ width: '100%', marginLeft: '16px' }}
                className="rounded-box code-character-style"
                type="text"
              ></input>
            </label>
          </div>
        )}

        <div
          style={{ margin: '0px 16px 0px 8px' }}
          className="heading-character-style"
        >
          <Button
            color="primary"
            disabled={vitline.vitIndexes.length === 0}
            onClick={() => {
              history.push('/edit/vitline:' + vitline.vitlineId)
            }}
          >
            PREVIEW
          </Button>
        </div>
        <div
          style={{ margin: '0px 32px 0px 8px' }}
          className="heading-character-style"
        >
          {actionError && (
            <Tooltip title={actionError}>
              <span>
                <Button
                  color="primary"
                  disabled={vitline.vitIndexes.length === 0 || actionError}
                  onClick={() => {
                    onAction()
                  }}
                >
                  {actionText}
                </Button>
              </span>
            </Tooltip>
          )}
          {!actionError && (
            <Button
              color="primary"
              disabled={vitline.vitIndexes.length === 0 || actionError}
              onClick={() => {
                onAction()
              }}
            >
              {actionText}
            </Button>
          )}
        </div>
      </div>
      <div
        style={{
          overflowX: 'scroll',
          overflowY: 'hidden',
          maxWidth: 'calc(100vw)',
          display: 'flex',
          flexGrow: 1,
        }}
      >
        <VitSpringTimeline
          disabled={disabled}
          vitline={vitline}
          onChange={(newVitsArray) => {
            console.log('Vits Change ', newVitsArray)
            onVitsChange(newVitsArray)
          }}
          onVitClick={(vit, index) => {
            history.push(
              '/edit/vitline:' + vitline.vitlineId + '/index:' + index
            )
          }}
        ></VitSpringTimeline>
      </div>
    </div>
  )
}

function VitTimeline({ vits, onChange, onVitClick, disabled }) {
  return (
    <div
      style={{
        padding: '8px 16px 8px 16px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        opacity: disabled ? 0.5 : 1.0,
        pointerEvents: disabled ? 'none' : undefined,
      }}
    >
      {vits.map((e, index) => {
        const vit = vits && index < vits.length ? vits[index] : null
        return (
          <VitPlacement
            disabled={disabled}
            key={':' + index}
            index={index}
            vit={vit}
            onClick={() => {
              onVitClick(vit, index)

              //history.push('/builder')
            }}
            onDrop={(data) => {
              const newVits = [...vits]
              newVits[index] = data.vit
              if (data.fromIndex >= 0 && data.fromIndex !== index) {
                newVits[data.fromIndex] = null
              }
              onChange(newVits)
            }}
            onClear={() => {
              const newVits = [...vits]
              newVits[index] = null
              onChange(newVits)
            }}
          ></VitPlacement>
        )
      })}
    </div>
  )
}

function VitInsertPlacement({ onDrop, index, disabled }) {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: 'vit',
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
      canDrop: () => true,
      drop(item, monitor) {
        /* const delta = monitor.getDifferenceFromInitialOffset()

        let left = Math.round(item.left + delta.x)
        let top = Math.round(item.top + delta.y)
        if (snapToGrid) {
          //;[left, top] = doSnapToGrid(left, top)
        }

        moveBox(item.id, left, top)
        return undefined */
        onDrop(item.data)
      },
    }),
    [onDrop]
  )

  return (
    <div
      ref={drop}
      key={'insert' + index}
      style={{
        width: '16px',
        height: '80px',
        backgroundColor: isOver ? 'var(--unnamed-color-94c4a8)' : '',
      }}
      /*
      onDragOver={(e) => {
        if (disabled) return
        e.preventDefault()
        e.dataTransfer.dropEffect = 'move'
        setDropping(true)
      }}
      onDragLeave={() => {
        if (disabled) return
        setDropping(false)
      }}
      onMouseLeave={() => {
        if (disabled) return
        setDropping(false)
      }}
      onDrop={(e) => {
        if (disabled) return
        e.preventDefault()

        const data = JSON.parse(e.dataTransfer.getData('text/plain'))
        if (onDrop) {
          onDrop(data)
        }
      }}*/
    ></div>
  )
}

function VitPlacement({ vit, index, onClick, onDrop, onClear, disabled }) {
  const [isHover, setHover] = React.useState(false)

  const [{ isDropping, canDrop }, drop] = useDrop(
    () => ({
      accept: 'vit',
      collect: (monitor) => ({
        isDropping: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
      canDrop: () => true,
      drop(item, monitor) {
        /* const delta = monitor.getDifferenceFromInitialOffset()

        let left = Math.round(item.left + delta.x)
        let top = Math.round(item.top + delta.y)
        if (snapToGrid) {
          //;[left, top] = doSnapToGrid(left, top)
        }

        moveBox(item.id, left, top)
        return undefined */
        onDrop(item.data)
      },
    }),
    [onDrop]
  )

  return (
    <div
      ref={drop}
      key={'placement:' + index}
      style={{
        width: '143px',
        height: '80px',
        backgroundImage: isDropping
          ? `url(/img/add_vit_box_dropping.png)`
          : `url(/img/add_vit_box.png)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        margin: '0px 8px 0px 0px',
      }}
      /*
      onDragOver={(e) => {
        if (disabled) return

        e.preventDefault()
        e.dataTransfer.dropEffect = 'copy'
        setDropping(true)
      }}
      onDragLeave={() => {
        if (disabled) return

        setDropping(false)
      }}
      onMouseOver={() => {}}
      onMouseLeave={() => {
        if (disabled) return

        setDropping(false)
      }}
      onDrop={(e) => {
        if (disabled) return

        e.preventDefault()

        const data = JSON.parse(e.dataTransfer.getData('text/plain'))
        console.log('Dropped: ' + data)
        if (onDrop) {
          onDrop(data)
        }
      }}
      */
      onClick={() => {
        if (disabled) return

        if (onClick) {
          onClick()
        }
      }}
    >
      {vit && (
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            opacity: '1.0',
          }}
          onMouseEnter={() => {
            if (disabled) return

            setHover(true)
          }}
          onMouseLeave={() => {
            if (disabled) return

            setHover(false)
          }}
        >
          <img
            style={{
              position: 'absolute',
              right: '0',
              top: '0',
              width: '16px',
              height: '16px',
              opacity: isHover ? '1.0' : '0.0',
              transition: 'opacity 0.25s ease-in-out',
              cursor: 'pointer',
              zIndex: 10,
            }}
            onClick={(e) => {
              if (disabled) return

              e.preventDefault()
              e.stopPropagation()
              onClear()
            }}
            src="/img/clear_box.png"
          ></img>
          <div
            style={{
              clipPath:
                'polygon(0% 0%, calc(100% - 31px) 0%, 100% 31px, 100% 100%, 0 100%)',
            }}
          >
            <VitPreview
              vit={vit}
              fromIndex={index}
              onHover={(hover) => {
                if (disabled) return

                setHover(hover)
              }}
            ></VitPreview>
          </div>
          <img
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
            }}
            src="/img/vit_drag_overlay.png"
          ></img>
        </div>
      )}
    </div>
  )
}
