import React from 'react'

import VitPreview from '../VitPreview'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import ReplayIcon from '@material-ui/icons/Replay'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  makeStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles'
import PauseIcon from '@material-ui/icons/Pause'
import MaterialSlider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

export const ViewZIndex = {
  PLAYER: 0,
  LOADER: 2,
  GRADIENT: 3,
  CONTROLS: 4,
}

export default function PlayerOverlay({
  player,
  image,
  sequence,
  disableTimeline,
  color,
  state,
  currentProgress,
  onPlay,
  onPause,
  onSeek,
  onSeekIndex,
}) {
  const isPlaying = state === 'playing'
  const isPaused = state === 'paused'
  const isLoading = state === 'loading'
  const isPlayActivated = state !== 'idle'
  const isEnded = state === 'ended'

  const [isVideoHover, setVideoHover] = React.useState(false)
  const [sectionHover, setSectionHover] = React.useState(undefined)

  const [lastHoverTime, setLastHoverTime] = React.useState()
  React.useEffect(() => {
    if (sectionHover === null) {
      const timeout = setTimeout(() => {
        setSectionHover(undefined)
      }, 3000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [sectionHover, lastHoverTime])

  const scrollRef = React.useRef()

  const totalTime = React.useMemo(() => {
    let totalTime = 0
    sequence.forEach((vit) => {
      totalTime += vit.end - vit.start
    })
    return totalTime
  }, [sequence])

  function scrollIndexIntoView(index) {
    if (scrollRef.current) {
      const width = scrollRef.current.offsetWidth
      const elementWidth = 240

      const numElementsVisible = width / 2.0 / elementWidth
      const numElementsHidden = Math.max(0, index + 1 - numElementsVisible)
      let minScroll = numElementsHidden * elementWidth

      const currentStartIndex = scrollRef.current.scrollLeft / elementWidth
      const currentEndIndex = currentStartIndex + width / elementWidth
      if (index > currentStartIndex && index + 1 < currentEndIndex) {
        return
      }

      if (index === 0) {
        minScroll = 0
      }

      scrollRef.current.scrollLeft = minScroll
    }
  }

  const useStyles = makeStyles(() => ({
    largeIcon: {
      fontSize: '5em',
    },
  }))
  const classes = useStyles()

  const playButtonRenderer = React.useMemo(() => {
    const whiteTheme = createMuiTheme({
      palette: { primary: { main: '#FFFFFF' } },
    })

    return (
      <MuiThemeProvider theme={whiteTheme}>
        <IconButton
          color={'primary'}
          onClick={() => {
            onPlay()
          }}
        >
          <PlayArrowIcon></PlayArrowIcon>
        </IconButton>
      </MuiThemeProvider>
    )
  }, [onPlay])

  const pauseButtonRenderer = React.useMemo(() => {
    const whiteTheme = createMuiTheme({
      palette: { primary: { main: '#FFFFFF' } },
    })

    return (
      <MuiThemeProvider theme={whiteTheme}>
        <IconButton
          color={'primary'}
          onClick={() => {
            onPause()
          }}
        >
          <PauseIcon></PauseIcon>
        </IconButton>
      </MuiThemeProvider>
    )
  })

  const showControls = isPlayActivated && (isVideoHover || isPaused)
  const showTimeline =
    isPlayActivated &&
    !disableTimeline &&
    (isPaused || sectionHover !== undefined)

  const currentSeconds = Math.floor((currentProgress * totalTime) % 60)
  const currentMinutes = Math.floor((currentProgress * totalTime) / 60)
  const totalSeconds = Math.floor(totalTime % 60)
  const totalMinutes = Math.floor(totalTime / 60)

  return (
    <div
      className="overlay-container"
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        pointerEvents: 'auto',
        overflow: 'hidden',
      }}
      onMouseOver={() => {
        setVideoHover(true)
        setLastHoverTime(Date.now())
      }}
      onMouseOut={() => {
        setVideoHover(false)
        setSectionHover(undefined)
      }}
      onMouseMove={() => {
        setLastHoverTime(Date.now())
      }}
    >
      {image}
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,

          zIndex: !isPlayActivated || isEnded ? ViewZIndex.LOADER : -1,

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          pointerEvents: 'none',
        }}
      >
        {isEnded && (
          <span style={{ pointerEvents: 'auto' }}>
            <IconButton
              color={color}
              onClick={() => {
                onPlay()
              }}
            >
              <ReplayIcon
                className={classes.largeIcon}
                fontSize="large"
              ></ReplayIcon>
            </IconButton>
          </span>
        )}

        {!isEnded && (
          <span className="overlay-button" style={{ pointerEvents: 'auto' }}>
            <IconButton
              color={color}
              onClick={() => {
                onPlay()
              }}
            >
              <PlayArrowIcon
                className={classes.largeIcon}
                fontSize="large"
              ></PlayArrowIcon>
            </IconButton>
          </span>
        )}
      </div>

      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,

          zIndex: isLoading ? ViewZIndex.LOADER : -1,

          opacity: isLoading ? '1.0' : '0.0',
          transition: 'opacity 0.2s ease-in-out',

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          pointerEvents: 'none',
        }}
      >
        <CircularProgress color={color} />
      </div>

      {player && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: isPlaying || isPaused ? ViewZIndex.PLAYER : -1,
            pointerEvents:
              sectionHover === undefined || sectionHover === null
                ? undefined
                : 'none',
          }}
        >
          {player}
        </div>
      )}

      {isPlayActivated && (
        <>
          <div
            style={{
              position: 'absolute',

              opacity: showControls ? '1.0' : '0.0',
              transition: 'opacity 0.2s ease-in-out',

              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: ViewZIndex.GRADIENT,
              backgroundImage: showTimeline
                ? 'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0), rgba(0,0,0,0))'
                : 'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0), rgba(0,0,0,0), rgba(0,0,0,0), rgba(0,0,0,0))',
              pointerEvents: 'none',
            }}
          ></div>
          {!disableTimeline && (
            <div
              ref={scrollRef}
              className={'no-scrollbar'}
              style={{
                position: 'absolute',
                width: '100%',
                height: '200px',
                left: 0,
                bottom: 0,
                opacity: showTimeline ? '1.0' : '0.0',
                transition: 'opacity 0.2s ease-in-out',
                zIndex: ViewZIndex.CONTROLS,
                overflow: 'scroll hidden',
                scrollBehavior: 'smooth',

                pointerEvents: showTimeline ? undefined : 'none',

                backgroundColor: showTimeline && isPaused ? 'black' : undefined,
              }}
            >
              <div
                style={{
                  position: 'relative',
                  top: 0,
                  left: 0,
                  width: 240 * sequence.length + 'px',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  alignContent: 'center',
                  padding: '8px 0 8px 0',
                }}
              >
                {sequence.map((vit, index) => {
                  return (
                    <div
                      key={vit.vitId + ':' + index}
                      style={{
                        position: 'relative',
                        width: 'calc(240px)',
                        height: 'calc(240px * (9  / 16))',
                        clipPath:
                          'polygon(0% 0%, calc(100% - 31px) 0%, 100% 31px, 100% 100%, 0 100%)',
                        margin: '8px',
                        border:
                          sectionHover === index
                            ? '2px solid var(--unnamed-color-137d3f)'
                            : undefined,
                        backgroundColor:
                          sectionHover === index
                            ? 'var(--unnamed-color-137d3f)'
                            : undefined,
                        cursor: 'pointer',
                      }}
                      onMouseOver={() => {
                        setSectionHover(index)
                      }}
                      onMouseOut={() =>
                        setSectionHover((prevIndex) => {
                          if (index === prevIndex) {
                            return null
                          }
                          return prevIndex
                        })
                      }
                      onMouseMove={() => {
                        setLastHoverTime(Date.now())
                      }}
                    >
                      <VitPreview
                        showPreview={false}
                        vit={vit}
                        onClick={() => {
                          onSeekIndex(index)
                        }}
                      ></VitPreview>
                      <div
                        style={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          top: 0,
                          left: 0,
                          padding: '16px',

                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'flex-end',

                          pointerEvents: 'none',
                          backgroundImage:
                            'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0))',
                        }}
                      >
                        <Typography variant="body1">{vit.title}</Typography>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '45px',
              left: 0,
              bottom: 0,
              zIndex: ViewZIndex.CONTROLS,
              opacity: showControls ? '1.0' : '0.0',
              transition: 'opacity 0.2s ease-in-out',
            }}
          >
            <div
              style={{
                position: 'relative',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',

                paddingRight: '16px',

                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                alignContent: 'center',
              }}
            >
              {!isPlaying && playButtonRenderer}
              {isPlaying && pauseButtonRenderer}

              <PlayerTimeline
                disableOverlays={!isPlaying || !showTimeline}
                sequence={sequence}
                currentProgress={currentProgress}
                totalTime={totalTime}
                sectionHover={sectionHover}
                onChangeTime={(newTime) => {
                  onSeek(newTime / totalTime)
                }}
                onSectionHover={(index) => {
                  if (index === undefined) {
                    setSectionHover(null)
                  } else {
                    setSectionHover(index)
                    scrollIndexIntoView(index)
                  }
                }}
              ></PlayerTimeline>
              <Typography variant="body2">
                {currentMinutes +
                  ':' +
                  (currentSeconds < 10 ? '0' : '') +
                  currentSeconds +
                  ' / ' +
                  totalMinutes +
                  ':' +
                  totalSeconds}
              </Typography>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const PositionSlider = withStyles({
  root: {
    color: 'black',
    padding: 0,
    display: 'block',
    height: 15,
    boxSizing: 'border-box',
  },
  thumb: {
    height: 13,
    width: 13,
    marginTop: -1,
    marginLeft: -7,
    backgroundColor: 'var(--unnamed-color-137d3f)',
    border: '2px solid currentColor',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 0,
    borderRadius: 4,
  },
  rail: {
    height: 0,
    borderRadius: 4,
  },
})(MaterialSlider)

function PlayerTimeline({
  sequence,
  currentProgress,
  totalTime,
  onChangeTime,
  onChangeCommitted,
  sectionHover,
  onSectionHover,
}) {
  let currentTime = 0
  const sections = []
  const overlays = []

  sequence.forEach((vit, index) => {
    const vitStart = currentTime
    const vitTime = vit.end - vit.start
    overlays.push(
      <div
        key={'overlay-' + index}
        style={{
          position: 'absolute',
          top: 0,
          left: (currentTime / totalTime) * 100 + '%',
          width: ((vit.end - vit.start) / totalTime) * 100 + '%',
          height: '15px',
          opacity: '1.0',
          zIndex: 1,
          cursor: 'pointer',
        }}
        onMouseOver={() => {
          onSectionHover(index)
        }}
        onMouseDown={(e) => {
          const x = e.nativeEvent.offsetX
          const width = e.target.offsetWidth
          const vitTimeProgress = x / width
          onChangeTime(vitStart + vitTimeProgress * vitTime)
        }}
        onMouseUp={() => {
          if (onChangeCommitted) {
            onChangeCommitted()
          }
        }}
      ></div>
    )

    sections.push(
      <div
        key={vit.vitId}
        style={{
          position: 'absolute',
          top: 0,
          left: (currentTime / totalTime) * 100 + '%',
          width: ((vit.end - vit.start) / totalTime) * 100 + '%',
          height: '15px',
          borderLeft: index !== 0 ? '1px solid white' : undefined,
          backgroundColor:
            sectionHover === index ? 'var(--unnamed-color-137d3f)' : undefined,
          backgroundClip: 'content-box',
          zIndex: -1,
        }}
      ></div>
    )
    currentTime += vit.end - vit.start
  })

  return (
    <div
      style={{
        display: 'flex',
        flexGrow: '1',
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: '16px',
      }}
    >
      <div
        style={{
          position: 'relative',
          border: '1px solid white',
          width: '100%',
          height: '15px',
          padding: '1px 7px 0 7px',
        }}
      >
        <PositionSlider
          value={currentProgress * totalTime}
          onChange={(event, newValue) => {
            onChangeTime(newValue)
          }}
          onChangeCommitted={() => {
            onChangeCommitted()
          }}
          step={0.02}
          min={0}
          max={totalTime}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          getAriaValueText=""
          valueLabelFormat={(value) => '' + value + ''}
        ></PositionSlider>
        {sections}
        {overlays}
      </div>
    </div>
  )
}
