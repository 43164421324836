import React from 'react'

import {
  useQuery,
  gql,
  useMutation,
  useReactiveVar,
  useLazyQuery,
} from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import {
  draftVitline,
  vitlineInitialValue,
} from '../views/builder/VitlineCache'

const FEED_ALL = gql`
  query feedAll($offset: Int!, $first: Int!) {
    feedAll(offset: $offset, first: $first) {
      __typename
      ... on Vit {
        vitId
        thumbnail {
          thumbnailUrl
        }
        start
        end
        title
        published {
          formatted
        }
        source {
          videoId
          vits {
            vitId
          }
        }
        vitlines {
          vitlineId
          author {
            userId
          }

          vitIndexes(first: 30) {
            Vit {
              vitId
              thumbnail {
                thumbnailUrl
              }
              start
              end
              title
              published {
                formatted
              }
              source {
                videoId
              }
              vitlines {
                vitlineId
              }
            }
            index
          }
        }
        saves {
          User {
            userId
          }
        }
      }
      ... on VitLine {
        vitlineId
        author {
          userId
        }
        vitIndexes(first: 30) {
          index
          Vit {
            vitId
            thumbnail {
              thumbnailUrl
            }
            start
            end
            title
            published {
              formatted
            }
            source {
              videoId
            }
            vitlines {
              vitlineId
            }
          }
        }
        title
        published {
          formatted
        }
        saves {
          User {
            userId
          }
        }
      }
    }
  }
`
const GET_VITLINE = gql`
  query getVitLine($vitlineId: ID!) {
    VitLine(vitlineId: $vitlineId) {
      vitlineId
      author {
        userId
      }
      title
      published {
        formatted
      }
      saves {
        User {
          userId
        }
      }
      attaches {
        attachmentId
        published {
          formatted
        }
        vit {
          vitId
          thumbnail {
            thumbnailUrl
          }
          start
          end
          title
          published {
            formatted
          }
          source {
            videoId
          }
          vitlines {
            vitlineId
          }
        }
      }
      vitIndexes {
        index
        Vit {
          vitId
          thumbnail {
            thumbnailUrl
          }
          start
          end
          title
          published {
            formatted
          }
          source {
            videoId
          }
          vitlines {
            vitlineId
          }
        }
      }
    }
  }
`

const GET_VITLINE_TITLE = gql`
  query getVitLine($vitlineId: ID!) {
    VitLine(vitlineId: $vitlineId) {
      vitlineId
      title
    }
  }
`

const GET_YOUTUBEVIDEO = gql`
  query getYouTubeVideo($videoId: ID!) {
    YouTubeVideo(videoId: $videoId) {
      videoId
      vits {
        vitId
        source {
          videoId
        }
        vitlines {
          vitlineId
        }
      }
    }
  }
`

const GET_VIT = gql`
  query getVit($vitId: ID!) {
    Vit(vitId: $vitId) {
      vitId
      thumbnail {
        thumbnailUrl
      }
      start
      end
      title
      published {
        formatted
      }
      source {
        videoId
        vits {
          vitId
        }
      }
      attaches {
        attachmentId
        published {
          formatted
        }
        vit {
          vitId
          thumbnail {
            thumbnailUrl
          }
          start
          end
          title
          published {
            formatted
          }
          source {
            videoId
          }
          vitlines {
            vitlineId
          }
        }
      }
      vitlines {
        vitlineId
        title

        vitIndexes {
          index
          Vit {
            vitId
          }
        }
      }
      saves {
        User {
          userId
        }
      }
    }
  }
`

const SEARCH = gql`
  query SearchFeed($offset: Int!, $first: Int!, $searchString: String!) {
    search(offset: $offset, first: $first, searchString: $searchString) {
      __typename
      ... on Vit {
        vitId
        thumbnail {
          thumbnailUrl
        }
        start
        end
        title
        published {
          formatted
        }
        source {
          videoId
          vits {
            vitId
          }
        }
        vitlines {
          vitlineId

          vitIndexes(first: 30) {
            index
            Vit {
              vitId
              thumbnail {
                thumbnailUrl
              }
              start
              end
              title
              published {
                formatted
              }
              source {
                videoId
              }
              vitlines {
                vitlineId
              }
            }
          }
        }
      }
      ... on VitLine {
        vitlineId

        vitIndexes(first: 30) {
          index
          Vit {
            vitId
            thumbnail {
              thumbnailUrl
            }
            start
            end
            title
            published {
              formatted
            }
            source {
              videoId
            }
            vitlines {
              vitlineId
            }
          }
        }
        title
        published {
          formatted
        }
      }
    }
  }
`

const GET_VIT_BY_VITLINE_INDEX = gql`
  query getVitByVitLineIndex($vitlineId: ID!, $index: Int!) {
    queryVitByVitLineIndex(vitlineId: $vitlineId, index: $index) {
      vitId
      thumbnail {
        thumbnailUrl
      }
      start
      end
      title
      source {
        videoId
      }
      vitlines {
        vitlineId
      }
    }
  }
`

const UPDATE_VITLINE_TITLE = gql`
  mutation updateVitlineTitle($vitlineId: ID!, $title: String!) {
    UpdateVitLine(vitlineId: $vitlineId, title: $title) {
      __typename
      vitlineId
      title
    }
  }
`

const UPDATE_VITLINE_VITS = gql`
  mutation updateVitlineVits($vitlineId: ID!, $vitInputs: [VitInput!]!) {
    updateVitlineVits(vitlineId: $vitlineId, vitInputs: $vitInputs) {
      __typename
      vitlineId
      title
      vitIndexes {
        index
        Vit {
          vitId
          thumbnail {
            thumbnailUrl
          }
          start
          end
          title
          source {
            videoId
          }
        }
      }
    }
  }
`

const NEW_VITLINE = gql`
  mutation newVitlineVits(
    $vitInputs: [VitInput!]!
    $title: String!
    $userId: ID!
  ) {
    newVitlineVits(vitInputs: $vitInputs, title: $title, userId: $userId) {
      vitlineId
      vitIndexes {
        Vit {
          vitId
          thumbnail {
            thumbnailUrl
          }
          source {
            videoId
          }
        }
        index
      }
      title
    }
  }
`

const UPDATE_VIT = gql`
  mutation updateVit(
    $vitId: ID!
    $start: Float!
    $end: Float!
    $title: String!
  ) {
    UpdateVit(vitId: $vitId, start: $start, end: $end, title: $title) {
      vitId
      start
      end
      title
      thumbnail {
        thumbnailUrl
      }
      source {
        videoId
      }
    }
  }
`
const NEW_VIT = gql`
  mutation newVit(
    $start: Float!
    $end: Float!
    $title: String!
    $videoId: ID!
    $userId: ID!
  ) {
    newVit(
      videoId: $videoId
      start: $start
      end: $end
      title: $title
      userId: $userId
    ) {
      vitId
      start
      end
      title
      thumbnail {
        thumbnailUrl
      }
      source {
        videoId
      }
    }
  }
`

const NEW_VIT_DRAFT = gql`
  mutation newVitDraft(
    $start: Float!
    $end: Float!
    $title: String!
    $videoId: ID!
    $userId: ID!
  ) {
    newVitDraft(
      videoId: $videoId
      start: $start
      end: $end
      title: $title
      userId: $userId
    ) {
      vitId
      start
      end
      title
      thumbnail {
        thumbnailUrl
      }
      source {
        videoId
      }
    }
  }
`

const NEW_VIT_AT_VITLINE_INDEX = gql`
  mutation newVitAtVitLineIndex(
    $videoId: ID!
    $start: Float!
    $end: Float!
    $title: String!
    $vitlineId: ID!
    $index: Int!
    $userId: ID!
  ) {
    newVitAtVitLineIndex(
      videoId: $videoId
      start: $start
      end: $end
      title: $title
      vitlineId: $vitlineId
      index: $index
      userId: $userId
    ) {
      vitlineId
      vitIndexes {
        index
        Vit {
          vitId
          start
          end
          thumbnail {
            thumbnailUrl
          }
          title
          source {
            videoId
          }
        }
      }
    }
  }
`

const PUBLISH_VITLINE = gql`
  mutation publishVitLine($vitlineId: ID!) {
    publishVitLine(vitlineId: $vitlineId) {
      vitlineId
      published {
        formatted
      }
    }
  }
`

const UNPUBLISH_VITLINE = gql`
  mutation unpublishVitLine($vitlineId: ID!) {
    unpublishVitLine(vitlineId: $vitlineId) {
      vitlineId
      published {
        formatted
      }
    }
  }
`

const PUBLISH_VIT = gql`
  mutation publishVit($vitId: ID!) {
    publishVit(vitId: $vitId) {
      vitId
      published {
        formatted
      }
    }
  }
`

const UNPUBLISH_VIT = gql`
  mutation unpublishVit($vitId: ID!) {
    unpublishVit(vitId: $vitId) {
      vitId
      published {
        formatted
      }
    }
  }
`

const UPDATE_VIT_AT_VITLINE_INDEX = gql`
  mutation updateVitAtVitLineIndex($vitlineId: ID!, $vitId: ID!, $index: Int!) {
    updateVitAtVitLineIndex(
      vitlineId: $vitlineId
      vitId: $vitId
      index: $index
    ) {
      vitlineId
      vitIndexes {
        index
        Vit {
          vitId
          start
          end
          thumbnail {
            thumbnailUrl
          }
          title
          source {
            videoId
          }
        }
      }
    }
  }
`

const QUERY_VITS = gql`
  query queryVits($searchString: String!) {
    searchVits(searchString: $searchString) {
      vitId
      title
      start
      end
      thumbnail {
        thumbnailUrl
      }
      source {
        videoId
      }
    }
  }
`

const QUERY_SOURCE_VITS = gql`
  query queryVits($videoId: ID!) {
    vitsByVideoId(videoId: $videoId) {
      vitId
      title
      start
      end
      thumbnail {
        thumbnailUrl
      }
      source {
        videoId
      }
    }
  }
`

const GET_USER_FEED = gql`
  query userFeed($offset: Int!, $first: Int!, $userId: ID!) {
    userFeed(offset: $offset, first: $first, userId: $userId) {
      __typename
      ... on Vit {
        vitId
        thumbnail {
          thumbnailUrl
        }
        start
        end
        title
        published {
          formatted
        }
        source {
          videoId
          vits {
            vitId
          }
        }
        vitlines {
          vitlineId

          vitIndexes(first: 12) {
            index
            Vit {
              vitId
              thumbnail {
                thumbnailUrl
              }
              start
              end
              title
              published {
                formatted
              }
              source {
                videoId
              }
              vitlines {
                vitlineId
              }
            }
          }
        }
      }
      ... on VitLine {
        vitlineId

        vitIndexes(first: 12) {
          index
          Vit {
            vitId
            thumbnail {
              thumbnailUrl
            }
            start
            end
            title
            published {
              formatted
            }
            source {
              videoId
            }
            vitlines {
              vitlineId
            }
          }
        }
        title
        published {
          formatted
        }
      }
    }
  }
`

const GET_USER_FAVS_FEED = gql`
  query getUserFavsFeed($userId: ID!, $offset: Int!, $first: Int!) {
    getUserFavs(userId: $userId, offset: $offset, first: $first) {
      __typename
      ... on Vit {
        vitId
        title
        start
        end
        thumbnail {
          thumbnailUrl
        }
        source {
          videoId
        }
        created {
          formatted
        }
        published {
          formatted
        }
      }
      ... on VitLine {
        vitlineId
        title

        vitIndexes {
          index
          Vit {
            vitId
            thumbnail {
              thumbnailUrl
            }
            source {
              videoId
            }
          }
        }
        created {
          formatted
        }
        published {
          formatted
        }
      }
    }
  }
`

const GET_USER_VITS_FEED = gql`
  query getUserVitsFeed($userId: ID!, $offset: Int!, $first: Int!) {
    getUserVits(userId: $userId, offset: $offset, first: $first) {
      vitId
      title
      start
      end
      thumbnail {
        thumbnailUrl
      }
      source {
        videoId
      }
      created {
        formatted
      }
      published {
        formatted
      }
    }
  }
`

const GET_USER_DRAFT_VITLINES_FEED = gql`
  query getUserDraftVitlinesFeed($userId: ID!, $offset: Int!, $first: Int!) {
    getUserDraftVitlines(userId: $userId, offset: $offset, first: $first) {
      vitlineId
      title

      vitIndexes {
        index
        Vit {
          vitId
          thumbnail {
            thumbnailUrl
          }
          source {
            videoId
          }
        }
      }
      created {
        formatted
      }
      published {
        formatted
      }
    }
  }
`

const GET_USER_PUBLISHED_VITLINES_FEED = gql`
  query getUserPublishedVitlinesFeed(
    $userId: ID!
    $offset: Int!
    $first: Int!
  ) {
    getUserPublishedVitlines(userId: $userId, offset: $offset, first: $first) {
      vitlineId
      title

      vitIndexes {
        index
        Vit {
          vitId
          thumbnail {
            thumbnailUrl
          }
          source {
            videoId
          }
        }
      }
      created {
        formatted
      }
      published {
        formatted
      }
    }
  }
`

const GET_USER = gql`
  query getUser($userId: ID!) {
    User(userId: $userId) {
      userId
      vits(first: 15) {
        vitId
        title
        start
        end
        thumbnail {
          thumbnailUrl
        }
        source {
          videoId
        }
        created {
          formatted
        }
        published {
          formatted
        }
      }
      vitlines(first: 15) {
        vitlineId
        title

        vitIndexes {
          index
          Vit {
            vitId
            thumbnail {
              thumbnailUrl
            }
            source {
              videoId
            }
          }
        }
        created {
          formatted
        }
        published {
          formatted
        }
      }
      draftVitlines(first: 15) {
        vitlineId
        title

        vitIndexes {
          index
          Vit {
            vitId
            thumbnail {
              thumbnailUrl
            }
            source {
              videoId
            }
          }
        }
        created {
          formatted
        }
        published {
          formatted
        }
      }
      saves(first: 15) {
        __typename
        ... on Vit {
          vitId
          title
          start
          end
          thumbnail {
            thumbnailUrl
          }
          source {
            videoId
          }
          created {
            formatted
          }
          published {
            formatted
          }
        }
        ... on VitLine {
          vitlineId
          title

          vitIndexes {
            index
            Vit {
              vitId
              thumbnail {
                thumbnailUrl
              }
              source {
                videoId
              }
            }
          }
          created {
            formatted
          }
          published {
            formatted
          }
        }
      }
    }
  }
`

const VIT_ATTACH = gql`
  mutation VitAttach($parentVitId: ID!, $userId: ID!, $vitId: ID!) {
    vitAttach(parentVitId: $parentVitId, userId: $userId, vitId: $vitId) {
      vitId
      attaches {
        attachmentId
        vit {
          vitId
          start
          end
          thumbnail {
            thumbnailUrl
          }
          title
          source {
            videoId
          }
        }
      }
    }
  }
`

const VIT_ATTACH_NEW = gql`
  mutation VitAttachNew(
    $parentVitId: ID!
    $userId: ID!
    $videoId: ID!
    $start: Float!
    $end: Float!
    $title: String!
  ) {
    vitAttachNew(
      parentVitId: $parentVitId
      userId: $userId
      videoId: $videoId
      start: $start
      end: $end
      title: $title
    ) {
      vitId
      attaches {
        vit {
          vitId
          start
          end
          thumbnail {
            thumbnailUrl
          }
          title
          source {
            videoId
          }
        }
      }
    }
  }
`

const VITLINE_ATTACH = gql`
  mutation VitlineAttach($parentVitlineId: ID!, $userId: ID!, $vitId: ID!) {
    vitlineAttach(
      parentVitlineId: $parentVitlineId
      userId: $userId
      vitId: $vitId
    ) {
      vitlineId
      attaches {
        vit {
          vitId
          start
          end
          thumbnail {
            thumbnailUrl
          }
          title
          source {
            videoId
          }
        }
      }
    }
  }
`

const VITLINE_ATTACH_NEW = gql`
  mutation VitlineAttachNew(
    $parentVitlineId: ID!
    $userId: ID!
    $videoId: ID!
    $start: Float!
    $end: Float!
    $title: String!
  ) {
    vitlineAttachNew(
      parentVitlineId: $parentVitlineId
      userId: $userId
      videoId: $videoId
      start: $start
      end: $end
      title: $title
    ) {
      vitlineId
      attaches {
        vit {
          vitId
          start
          end
          thumbnail {
            thumbnailUrl
          }
          title
          source {
            videoId
          }
        }
      }
    }
  }
`

const HIDE_VIT = gql`
  mutation HideVit($vitId: ID!, $userId: ID!) {
    hideVit(vitId: $vitId, userId: $userId) {
      vitId
    }
  }
`

const HIDE_VITLINE = gql`
  mutation HideVitLine($vitlineId: ID!, $userId: ID!) {
    hideVitLine(vitlineId: $vitlineId, userId: $userId) {
      vitlineId
    }
  }
`

const SAVE_VIT = gql`
  mutation SaveVit($vitId: ID!, $userId: ID!) {
    saveVit(vitId: $vitId, userId: $userId) {
      vitId
      saves {
        User {
          userId
        }
      }
    }
  }
`

const UNSAVE_VIT = gql`
  mutation UnsaveVit($vitId: ID!, $userId: ID!) {
    unsaveVit(vitId: $vitId, userId: $userId) {
      vitId
      saves {
        User {
          userId
        }
      }
    }
  }
`

const SAVE_VITLINE = gql`
  mutation SaveViLine($vitlineId: ID!, $userId: ID!) {
    saveVitLine(vitlineId: $vitlineId, userId: $userId) {
      vitlineId
      saves {
        User {
          userId
        }
      }
    }
  }
`

const UNSAVE_VITLINE = gql`
  mutation UnsaveVitLine($vitlineId: ID!, $userId: ID!) {
    unsaveVitLine(vitlineId: $vitlineId, userId: $userId) {
      vitlineId
      saves {
        User {
          userId
        }
      }
    }
  }
`

const DELETE_VITLINE = gql`
  mutation DeleteVitLine($vitlineId: ID!, $userId: ID!) {
    deleteVitLine(vitlineId: $vitlineId, userId: $userId) {
      vitlineId
    }
  }
`

const DELETE_VIT = gql`
  mutation DeleteVit($vitId: ID!, $userId: ID!) {
    deleteUnusedVit(vitId: $vitId, userId: $userId) {
      vitId
    }
  }
`

const TAG_CLOUD = gql`
  query TagCloud {
    tagCloud {
      tag
      score
    }
  }
`

const TAG_CLOUD_SEARCH = gql`
  query TagCloudSearch($searchString: String!) {
    tagCloudSearch(searchString: $searchString) {
      tag
      score
    }
  }
`

const API = {
  maxVits: 12,

  useTagCloud: () => {
    const { data } = useQuery(TAG_CLOUD)
    return data ? data.tagCloud : data
  },

  useTagCloudSearch: (searchString) => {
    const { data } = useQuery(TAG_CLOUD_SEARCH, { variables: { searchString } })
    return data ? data.tagCloudSearch : data
  },

  vitsFromVitLine: (vitline) => {
    const vitIndexes = vitline.vitIndexes
    if (vitIndexes && vitIndexes.length) {
      const vits = vitIndexes
        .slice()
        .sort((a, b) => a.index - b.index)
        .map((vitIndex) => vitIndex.Vit)
      return vits
    }
    return []
  },

  uniqueVitsFromVitLine: (vitline) => {
    const vits = {}
    vitline.vitIndexes.forEach((vitIndex) => {
      vits[vitIndex.Vit.vitId] = vitIndex.Vit
    })
    return Object.keys(vits).map((vitId) => vits[vitId])
  },

  thumbnailForData: (data) => {
    let imageUrl = ''

    if (!data) {
      return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
    }

    if (data.vitIndexes && data.vitIndexes.length > 0) {
      const firstVit = API.vitsFromVitLine(data)[0]
      imageUrl =
        'https://img.youtube.com/vi/' + firstVit.source.videoId + '/0.jpg'
      if (firstVit && firstVit.thumbnail) {
        imageUrl = firstVit.thumbnail.thumbnailUrl
      }
    } else if (data.source) {
      imageUrl = 'https://img.youtube.com/vi/' + data.source.videoId + '/0.jpg'
      if (data && data.thumbnail) {
        imageUrl = data.thumbnail.thumbnailUrl
      }
    } else {
      imageUrl =
        'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
    }

    return imageUrl
  },

  useVitlineDraft: () => {
    const [userId] = API.useUserId()
    const draft = useReactiveVar(draftVitline)

    const draftVits = []
    if (draft) {
      draft.vitIndexes.forEach((vitIndex) => {
        draftVits.push(vitIndex.Vit)
      })
    }

    return [
      draft,
      draftVits,
      (vits) => {
        const vitIndexes = []
        vits.forEach((vit, index) => {
          if (vit) {
            vitIndexes.push({
              __typename: '_VitLineVitIndexes',
              Vit: vit,
              index: index,
            })
          }
        })
        const newDraft = { ...draft }
        newDraft.vitIndexes = vitIndexes
        newDraft.author = { userId: userId }
        draftVitline(newDraft)
      },
      () => {
        draftVitline({ ...vitlineInitialValue, author: { userId: userId } })
      },
      (title) => {
        const newDraft = { ...draft }
        newDraft.title = title
        draftVitline(newDraft)
      },
    ]
  },

  useUserId: () => {
    const { user, isAuthenticated, loginWithPopup } = useAuth0()
    return [
      isAuthenticated ? user['https://vits.app/username'] : null,
      loginWithPopup,
    ]
  },

  useUserData: () => {
    const [userId] = API.useUserId()
    const { data } = useQuery(GET_USER, { variables: { userId: userId } })

    return data ? data.User[0] : data
  },

  useUserFeed: (userId) => {
    const limit = 20
    const [offset, setOffset] = React.useState(0)

    const { data, fetchMore } = useQuery(GET_USER_FEED, {
      variables: { offset: 0, first: limit, userId: userId },
    })
    return [
      data ? data.userFeed : data,
      () => {
        fetchMore({
          variables: {
            offset: offset + limit,
            first: limit,
          },
        })
        setOffset(offset + limit)
      },
    ]
  },

  useMainFeedData: () => {
    const limit = 20
    const [offset, setOffset] = React.useState(0)
    const { data, fetchMore } = useQuery(FEED_ALL, {
      variables: {
        offset: 0,
        first: limit,
      },
    })
    return [
      data ? data.feedAll : data,
      () => {
        fetchMore({
          variables: {
            offset: offset + limit,
            first: limit,
          },
        })
        setOffset(offset + limit)
      },
    ]
  },

  useUserSubFeed: (userId, feedType) => {
    let queryName

    if (feedType === 'getUserFavs') {
      queryName = GET_USER_FAVS_FEED
    } else if (feedType === 'getUserVits') {
      queryName = GET_USER_VITS_FEED
    } else if (feedType === 'getUserPublishedVitlines') {
      queryName = GET_USER_PUBLISHED_VITLINES_FEED
    } else if (feedType === 'getUserDraftVitlines') {
      queryName = GET_USER_DRAFT_VITLINES_FEED
    }

    const limit = 21
    const [offset, setOffset] = React.useState(0)
    const { data, fetchMore } = useQuery(queryName, {
      variables: {
        userId: userId,
        offset: 0,
        first: limit,
      },
    })
    return [
      data ? data[feedType] : data,
      () => {
        fetchMore({
          variables: {
            userId: userId,
            offset: offset + limit,
            first: limit,
          },
        })
        setOffset(offset + limit)
      },
    ]
  },

  useSearchData: (searchString) => {
    const limit = 20
    const [offset, setOffset] = React.useState(0)

    const { data, fetchMore } = useQuery(SEARCH, {
      variables: { searchString: searchString, offset: 0, first: limit },
    })
    return [
      data ? data.search : data,
      () => {
        fetchMore({
          variables: {
            offset: offset + limit,
            first: limit,
          },
        })
        setOffset(offset + limit)
      },
    ]
  },

  useLazySearchData: (searchString) => {
    const limit = 20
    const [offset, setOffset] = React.useState(0)

    React.useEffect(() => {
      const timeout = setTimeout(() => {
        getSearchData()
      }, 250)

      return () => {
        clearTimeout(timeout)
      }
    }, [searchString])

    const [getSearchData, { data, fetchMore }] = useLazyQuery(SEARCH, {
      variables: { searchString: searchString, offset: 0, first: limit },
    })

    return [
      data ? data.search : data,
      () => {
        fetchMore({
          variables: {
            offset: offset + limit,
            first: limit,
          },
        })
        setOffset(offset + limit)
      },
    ]
  },

  useSearchVits: (searchString) => {
    const { data, loading } = useQuery(QUERY_VITS, {
      variables: { searchString: searchString },
    })
    return [data ? data.searchVits : data, loading]
  },

  useSearchVideoVits: (videoId) => {
    const { data, loading } = useQuery(QUERY_SOURCE_VITS, {
      variables: { videoId: videoId },
    })
    return [data ? data.vitsByVideoId : data, loading]
  },

  useVitLineData: (vitlineId) => {
    const { data } = useQuery(GET_VITLINE, {
      variables: { vitlineId: vitlineId },
    })
    return data ? data.VitLine[0] : data
  },

  useVitData: (vitId) => {
    const { data } = useQuery(GET_VIT, {
      variables: { vitId: vitId },
    })
    return data ? data.Vit[0] : data
  },

  useLazyVitData: (vitId) => {
    const [getVitData, { data }] = useLazyQuery(GET_VIT, {
      variables: { vitId: vitId },
    })
    return [data ? data.Vit[0] : data, getVitData]
  },

  useYouTubeVideoData: (videoId) => {
    const { data } = useQuery(GET_YOUTUBEVIDEO, {
      variables: { videoId: videoId },
    })
    return data ? data.YouTubeVideo[0] : data
  },

  useVitAtVitLineIndexData: (parentVitlineId, index) => {
    const { data } = useQuery(GET_VIT_BY_VITLINE_INDEX, {
      variables: { vitlineId: parentVitlineId, index: index },
    })
    return data ? data.queryVitByVitLineIndex[0] : data
  },

  useVitPublishMutation: (vitId) => {
    const [userId, loginWithPopup] = API.useUserId()

    const [publishVitMutation] = useMutation(PUBLISH_VIT)
    const [unpublishVitMutation] = useMutation(UNPUBLISH_VIT)

    const publishVit = React.useCallback(() => {
      if (userId) {
        publishVitMutation({
          variables: { vitId: vitId },
          /*
          optimisticResponse: {
            vitAttach: {
              __typename: 'Vit',
              vitId: vitId,
              saves: [{ User: { userId } }],
            },
          },*/
        })
      } else {
        loginWithPopup()
      }
    }, [userId, vitId, loginWithPopup, publishVitMutation])

    const unpublishVit = React.useCallback(() => {
      if (userId) {
        unpublishVitMutation({
          variables: { vitId: vitId },
          /*
          optimisticResponse: {
            vitAttach: {
              __typename: 'Vit',
              vitId: vitId,
              saves: [{ User: { userId } }],
            },
          },*/
        })
      } else {
        loginWithPopup()
      }
    }, [userId, vitId, loginWithPopup, unpublishVitMutation])

    return [publishVit, unpublishVit]
  },

  useVitAttachMutation: (parentVitId) => {
    const [userId, loginWithPopup] = API.useUserId()
    const [attachMutation] = useMutation(VIT_ATTACH)
    const [attachNewMutation] = useMutation(VIT_ATTACH_NEW)

    const vitAttach = React.useCallback(
      (vitId) => {
        if (userId) {
          attachMutation({
            variables: { parentVitId, userId, vitId },
            /*
          optimisticResponse: {
            vitAttach: {
              __typename: 'Vit',
              vitId: vitId,
              saves: [{ User: { userId } }],
            },
          },*/
          })
        } else {
          loginWithPopup()
        }
      },
      [userId, parentVitId, loginWithPopup, attachMutation]
    )

    const newVitAttach = React.useCallback(
      (variables) => {
        if (userId) {
          attachNewMutation({
            variables: { parentVitId, userId, ...variables },
            /*
          optimisticResponse: {
            vitAttach: {
              __typename: 'Vit',
              vitId: vitId,
              saves: [{ User: { userId } }],
            },
          },*/
          })
        } else {
          loginWithPopup()
        }
      },
      [userId, parentVitId, loginWithPopup, attachNewMutation]
    )

    return [vitAttach, newVitAttach]
  },

  useVitLineAttachMutation: (parentVitlineId) => {
    const [userId, loginWithPopup] = API.useUserId()
    const [attachMutation] = useMutation(VITLINE_ATTACH)
    const [attachNewMutation] = useMutation(VITLINE_ATTACH_NEW)

    const vitlineAttach = React.useCallback(
      (vitId) => {
        if (userId) {
          attachMutation({
            variables: { parentVitlineId, userId, vitId },
            /*
          optimisticResponse: {
            vitlineAttach: {
              __typename: 'VitLine',
              vitlineId: parentVitlineId,
              attaches: [{ User: { userId } }],
            },
          },*/
          })
        } else {
          loginWithPopup()
        }
      },
      [userId, parentVitlineId, loginWithPopup]
    )

    const newVitlineAttach = React.useCallback(
      (variables) => {
        if (userId) {
          attachNewMutation({
            variables: { parentVitlineId, userId, ...variables },
            /*
          optimisticResponse: {
            vitAttach: {
              __typename: 'Vit',
              vitId: vitId,
              saves: [{ User: { userId } }],
            },
          },*/
          })
        } else {
          loginWithPopup()
        }
      },
      [userId, parentVitlineId, loginWithPopup]
    )

    return [vitlineAttach, newVitlineAttach]
  },

  useVitHideMutation: (vitId) => {
    const [userId, loginWithPopup] = API.useUserId()
    const [hideMutation] = useMutation(HIDE_VIT)

    const vitHide = React.useCallback(() => {
      if (userId) {
        hideMutation({
          variables: { vitId: vitId, userId: userId },
        })
      } else {
        loginWithPopup()
      }
    }, [userId, vitId, hideMutation])

    return [vitHide]
  },

  useVitLineHideMutation: (vitlineId) => {
    const [userId, loginWithPopup] = API.useUserId()

    const [hideMutation] = useMutation(HIDE_VITLINE)

    const vitlineHide = React.useCallback(() => {
      if (userId) {
        hideMutation({
          variables: { vitlineId: vitlineId, userId: userId },
        })
      } else {
        loginWithPopup()
      }
    }, [userId, vitlineId, hideMutation])

    return [vitlineHide]
  },

  useVitSaveMutation: (vitId) => {
    const [userId, loginWithPopup] = API.useUserId()
    const [saveMutation] = useMutation(SAVE_VIT)
    const [unsaveMutation] = useMutation(UNSAVE_VIT)

    const vitSave = React.useCallback(() => {
      if (userId) {
        saveMutation({
          refetchQueries: [
            {
              query: GET_USER_FAVS_FEED,
              variables: { userId: userId, first: 21, offset: 0 },
            },
          ],
          variables: { vitId: vitId, userId: userId },
          optimisticResponse: {
            saveVit: {
              __typename: 'Vit',
              vitId: vitId,
              saves: [{ User: { userId } }],
            },
          },
        })
      } else {
        loginWithPopup()
      }
    }, [userId, vitId, saveMutation])

    const vitUnsave = React.useCallback(() => {
      if (userId) {
        unsaveMutation({
          refetchQueries: [
            {
              query: GET_USER_FAVS_FEED,
              variables: { userId: userId, first: 21, offset: 0 },
            },
          ],
          variables: { vitId: vitId, userId: userId },
          optimisticResponse: {
            unsaveVit: {
              __typename: 'Vit',
              vitId: vitId,
              saves: [],
            },
          },
        })
      } else {
        loginWithPopup()
      }
    }, [userId, vitId, unsaveMutation])

    return [vitSave, vitUnsave]
  },

  useVitlineSaveMutation: (vitlineId) => {
    const [userId, loginWithPopup] = API.useUserId()
    const [saveMutation] = useMutation(SAVE_VITLINE)
    const [unsaveMutation] = useMutation(UNSAVE_VITLINE)

    const vitlineSave = React.useCallback(() => {
      if (userId) {
        saveMutation({
          refetchQueries: [
            {
              query: GET_USER_FAVS_FEED,
              variables: { userId: userId, first: 21, offset: 0 },
            },
          ],
          variables: { vitlineId: vitlineId, userId: userId },
          optimisticResponse: {
            saveVitLine: {
              __typename: 'VitLine',
              vitlineId: vitlineId,
              saves: [{ User: { userId } }],
            },
          },
        })
      } else {
        loginWithPopup()
      }
    }, [userId, vitlineId, loginWithPopup, saveMutation])

    const vitlineUnsave = React.useCallback(() => {
      if (userId) {
        unsaveMutation({
          refetchQueries: [
            {
              query: GET_USER_FAVS_FEED,
              variables: { userId: userId, first: 21, offset: 0 },
            },
          ],
          variables: { vitlineId: vitlineId, userId: userId },
          optimisticResponse: {
            saveVitLine: {
              __typename: 'VitLine',
              vitlineId: vitlineId,
              saves: [],
            },
          },
        })
      } else {
        loginWithPopup()
      }
    }, [userId, vitlineId, loginWithPopup, unsaveMutation])

    return [vitlineSave, vitlineUnsave]
  },

  useVitLineTitle: (vitlineId) => {
    const { data } = useQuery(GET_VITLINE_TITLE, {
      variables: { vitlineId: vitlineId },
    })

    return data ? data.VitLine[0].title : data
  },

  useVitLineTitleMutation: (vitlineId) => {
    const [mutate] = useMutation(UPDATE_VITLINE_TITLE)
    return (title) => {
      mutate({
        variables: { vitlineId: vitlineId, title: title },
        optimisticResponse: {
          UpdateVitLine: {
            __typename: 'VitLine',
            vitlineId: vitlineId,
            title: title,
          },
        },
      })
    }
  },

  useVitDeleteMutation: (vitId) => {
    const [mutate] = useMutation(DELETE_VIT)
    const [userId] = API.useUserId()
    return () => {
      mutate({
        variables: { vitId: vitId, userId: userId },
        refetchQueries: [
          {
            query: GET_USER_VITS_FEED,
            variables: { userId: userId, first: 21, offset: 0 },
          },
        ],
      })
    }
  },

  useVitLineDeleteMutation: (vitlineId) => {
    const [mutate] = useMutation(DELETE_VITLINE)
    const [userId] = API.useUserId()
    return () => {
      mutate({
        variables: { vitlineId: vitlineId, userId: userId },
        refetchQueries: [
          {
            query: GET_USER_DRAFT_VITLINES_FEED,
            variables: { userId: userId, first: 21, offset: 0 },
          },
          {
            query: GET_USER_PUBLISHED_VITLINES_FEED,
            variables: { userId: userId, first: 21, offset: 0 },
          },
        ],
      })
    }
  },

  useVitLineQuery: (vitlineId) => {
    const { data } = useQuery(GET_VITLINE, {
      variables: { vitlineId: vitlineId },
    })

    return data ? data.VitLine[0] : data
  },

  useVitLineVitsMutation: (vitlineId) => {
    const [mutate] = useMutation(UPDATE_VITLINE_VITS)

    return (vitInputs) => {
      const vitIndexes = vitInputs.map((vitInput) => {
        const vit = vitInput.vit
        return {
          __typename: '_VitLineVitIndex',
          index: vitInput.index,
          Vit: vit,
        }
      })

      vitInputs.forEach((vitInput) => {
        delete vitInput['vit']
      })

      const refetchQueries = Array(API.maxVits)
        .fill(null)
        .map((e, index) => {
          return {
            query: GET_VIT_BY_VITLINE_INDEX,
            variables: { vitlineId: vitlineId, index: index },
          }
        })

      mutate({
        variables: { vitlineId: vitlineId, vitInputs: vitInputs },
        refetchQueries: refetchQueries,
        optimisticResponse: {
          updateVitlineVits: {
            __typename: 'VitLine',
            vitlineId: vitlineId,
            vitIndexes: vitIndexes,
          },
        },
      })
    }
  },

  useNewVitLineMutation: () => {
    const [mutate, { data }] = useMutation(NEW_VITLINE)
    const [userId, loginWithPopup] = API.useUserId()

    return [
      ({ variables }) => {
        if (userId) {
          mutate({
            refetchQueries: [
              {
                query: GET_USER_DRAFT_VITLINES_FEED,
                variables: { userId: userId, first: 21, offset: 0 },
              },
            ],
            variables: { userId: userId, ...variables },
          })
        } else {
          loginWithPopup()
        }
      },
      data ? data.newVitlineVits.vitlineId : undefined,
    ]
  },

  useVitMutation: (vitId) => {
    const [mutate] = useMutation(UPDATE_VIT)
    const [userId, loginWithPopup] = API.useUserId()

    return ({ variables: { start, end, title } }) => {
      if (userId) {
        mutate({
          optimisticResponse: {
            UpdateVit: {
              __typename: 'Vit',
              vitId: vitId,
              start: start,
              end: end,
              title: title,
            },
          },
          variables: {
            vitId: vitId,
            userId: userId,
            start: start,
            end: end,
            title: title,
          },
        })
      } else {
        loginWithPopup()
      }
    }
  },

  useVitLineUnpublishMutation: (vitlineId) => {
    const [userId] = API.useUserId()
    const [mutate] = useMutation(UNPUBLISH_VITLINE)
    return () => {
      return mutate({
        refetchQueries: [
          {
            query: GET_USER_DRAFT_VITLINES_FEED,
            variables: { userId: userId, first: 21, offset: 0 },
          },
          {
            query: GET_USER_PUBLISHED_VITLINES_FEED,
            variables: { userId: userId, first: 21, offset: 0 },
          },
        ],
        variables: { vitlineId: vitlineId },
      })
    }
  },

  useVitLinePublishMutation: (vitlineId) => {
    const [userId] = API.useUserId()
    const [mutate] = useMutation(PUBLISH_VITLINE)
    return () => {
      return mutate({
        refetchQueries: [
          {
            query: GET_USER_DRAFT_VITLINES_FEED,
            variables: { userId: userId, first: 21, offset: 0 },
          },
          {
            query: GET_USER_PUBLISHED_VITLINES_FEED,
            variables: { userId: userId, first: 21, offset: 0 },
          },
          { query: FEED_ALL, variables: { offset: 0, first: 20 } },
        ],
        variables: { vitlineId: vitlineId },
      })
    }
  },

  /*
  useVitUnpublishMutation: (vitId) => {
    const [userId] = API.useUserId()
    const [mutate] = useMutation(UNPUBLISH_VIT)
    return () => {
      return mutate({
        refetchQueries: [
          {
            query: GET_USER_DRAFT_VITLINES_FEED,
            variables: { userId: userId, first: 21, offset: 0 },
          },
          {
            query: GET_USER_PUBLISHED_VITLINES_FEED,
            variables: { userId: userId, first: 21, offset: 0 },
          },
        ],
        variables: { vitlineId: vitlineId },
      })
    }
  },*/

  useVitLinePublishMutation: (vitlineId) => {
    const [userId] = API.useUserId()
    const [mutate] = useMutation(PUBLISH_VITLINE)
    return () => {
      return mutate({
        refetchQueries: [
          {
            query: GET_USER_DRAFT_VITLINES_FEED,
            variables: { userId: userId, first: 21, offset: 0 },
          },
          {
            query: GET_USER_PUBLISHED_VITLINES_FEED,
            variables: { userId: userId, first: 21, offset: 0 },
          },
          { query: FEED_ALL, variables: { offset: 0, first: 20 } },
        ],
        variables: { vitlineId: vitlineId },
      })
    }
  },

  useNewVitMutation: () => {
    const [mutate, { data }] = useMutation(NEW_VIT)
    const [userId, loginWithPopup] = API.useUserId()

    return [
      ({ variables }) => {
        if (userId) {
          return mutate({
            refetchQueries: [
              {
                query: GET_USER_VITS_FEED,
                variables: { userId: userId, first: 21, offset: 0 },
              },
            ],
            variables: { userId: userId, ...variables },
          })
        } else {
          loginWithPopup()
          return null
        }
      },
      data ? data.newVit.vitId : data,
    ]
  },

  useNewDraftVitMutation: () => {
    const [mutate, { data }] = useMutation(NEW_VIT_DRAFT)
    const [userId, loginWithPopup] = API.useUserId()

    return [
      ({ variables }) => {
        if (userId) {
          return mutate({
            refetchQueries: [
              {
                query: GET_USER_VITS_FEED,
                variables: { userId: userId, first: 21, offset: 0 },
              },
            ],
            variables: { userId: userId, ...variables },
          })
        } else {
          loginWithPopup()
          return null
        }
      },
      data ? data.newVitDraft.vitId : data,
    ]
  },

  useNewVitAtVitLineIndexMutation: (vitlineId, index) => {
    const [mutate] = useMutation(NEW_VIT_AT_VITLINE_INDEX)
    const [userId, loginWithPopup] = API.useUserId()

    return ({ variables }) => {
      if (userId) {
        mutate({
          refetchQueries: [
            {
              query: GET_USER_VITS_FEED,
              variables: { userId: userId, first: 21, offset: 0 },
            },
            {
              query: GET_USER_DRAFT_VITLINES_FEED,
              variables: { userId: userId, first: 21, offset: 0 },
            },
          ],
          variables: {
            vitlineId: vitlineId,
            index: index,
            userId: userId,
            ...variables,
          },
        })
      } else {
        loginWithPopup()
      }
    }
  },

  useUpdateVitLineIndexMutation: (vitlineId, index) => {
    const [mutate] = useMutation(UPDATE_VIT_AT_VITLINE_INDEX)
    const [userId, loginWithPopup] = API.useUserId()

    return ({ variables }) => {
      if (userId) {
        mutate({
          refetchQueries: [
            {
              query: GET_USER_VITS_FEED,
              variables: { userId: userId, first: 21, offset: 0 },
            },
            {
              query: GET_USER_DRAFT_VITLINES_FEED,
              variables: { userId: userId, first: 21, offset: 0 },
            },
            {
              query: GET_VIT_BY_VITLINE_INDEX,
              variables: { vitlineId: vitlineId, index: index },
            },
          ],
          variables: {
            vitlineId: vitlineId,
            index: index,
            ...variables,
          },
        })
      } else {
        loginWithPopup()
      }
    }
  },
}

export default API
