import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useHistory } from 'react-router'

export function CreateButton() {
  const { isAuthenticated, loginWithPopup } = useAuth0()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const history = useHistory()

  const handleClick = (event) => {
    if (isAuthenticated) {
      setAnchorEl(event.currentTarget)
    } else {
      loginWithPopup()
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCreateVitLine = () => {
    history.push('/edit/vitline:draft')
  }

  const handleCreateVit = () => {
    history.push('/add')
  }

  return (
    <div
      className={'heading-character-style'}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Tooltip title="CREATE NEW" aria-label="add">
        <Fab
          color="primary"
          size="small"
          className={'fab'}
          onClick={handleClick}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCreateVitLine}>New Vitline</MenuItem>
        <MenuItem onClick={handleCreateVit}>New Vit</MenuItem>
      </Menu>
    </div>
  )
}
