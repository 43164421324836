import { GraphMapping } from '../graph/GraphMapping'

export function generateLayoutPosibilities(nodeSize, childrenCount) {
  if (nodeSize === 1 && childrenCount === 1) {
    return [
      [
        [GraphMapping.SHARED, GraphMapping.CHILD, GraphMapping.SHARED],
        [GraphMapping.SHARED, GraphMapping.FILLED, GraphMapping.SHARED],
        [GraphMapping.SHARED, GraphMapping.RESERVED, GraphMapping.SHARED],
      ],
      [
        [GraphMapping.SHARED, GraphMapping.SHARED, GraphMapping.SHARED],
        [GraphMapping.CHILD, GraphMapping.FILLED, GraphMapping.SHARED],
        [GraphMapping.SHARED, GraphMapping.RESERVED, GraphMapping.SHARED],
      ],
      [
        [GraphMapping.SHARED, GraphMapping.SHARED, GraphMapping.SHARED],
        [GraphMapping.SHARED, GraphMapping.FILLED, GraphMapping.SHARED],
        [GraphMapping.SHARED, GraphMapping.RESERVED, GraphMapping.SHARED],
      ],
      [
        [GraphMapping.SHARED, GraphMapping.SHARED, GraphMapping.SHARED],
        [GraphMapping.SHARED, GraphMapping.FILLED, GraphMapping.CHILD],
        [GraphMapping.SHARED, GraphMapping.RESERVED, GraphMapping.SHARED],
      ],
    ]
  } else if (nodeSize === 1 && childrenCount <= 4) {
    return [
      [
        [GraphMapping.SHARED, GraphMapping.CHILD, GraphMapping.SHARED],
        [GraphMapping.CHILD, GraphMapping.FILLED, GraphMapping.CHILD],
        [GraphMapping.SHARED, GraphMapping.RESERVED, GraphMapping.SHARED],
      ],
    ]
  } else if (nodeSize === 2 && childrenCount <= 8) {
    return [
      [
        [
          GraphMapping.SHARED,
          GraphMapping.CHILD,
          GraphMapping.CHILD,
          GraphMapping.SHARED,
        ],
        [
          GraphMapping.CHILD,
          GraphMapping.FILLED,
          GraphMapping.FILLED,
          GraphMapping.CHILD,
        ],
        [
          GraphMapping.CHILD,
          GraphMapping.FILLED,
          GraphMapping.FILLED,
          GraphMapping.CHILD,
        ],
        [
          GraphMapping.SHARED,
          GraphMapping.RESERVED,
          GraphMapping.RESERVED,
          GraphMapping.SHARED,
        ],
      ],
    ]
  } else if (nodeSize === 3 && childrenCount <= 12) {
    return [
      [
        [
          GraphMapping.SHARED,
          GraphMapping.CHILD,
          GraphMapping.CHILD,
          GraphMapping.CHILD,
          GraphMapping.SHARED,
        ],
        [
          GraphMapping.CHILD,
          GraphMapping.FILLED,
          GraphMapping.FILLED,
          GraphMapping.FILLED,
          GraphMapping.CHILD,
        ],
        [
          GraphMapping.CHILD,
          GraphMapping.FILLED,
          GraphMapping.FILLED,
          GraphMapping.FILLED,
          GraphMapping.CHILD,
        ],
        [
          GraphMapping.CHILD,
          GraphMapping.FILLED,
          GraphMapping.FILLED,
          GraphMapping.FILLED,
          GraphMapping.CHILD,
        ],
        [
          GraphMapping.SHARED,
          GraphMapping.RESERVED,
          GraphMapping.RESERVED,
          GraphMapping.RESERVED,
          GraphMapping.SHARED,
        ],
      ],
    ]
  }

  return [
    Array(nodeSize + 2)
      .fill(null)
      .map((e, row) => {
        return Array(nodeSize + 2)
          .fill(null)
          .map((e, col) => {
            if (
              row === 0 ||
              row === nodeSize + 1 ||
              col === 0 ||
              col === nodeSize + 1
            ) {
              if (row === nodeSize + 1 && col !== 0 && col !== nodeSize + 1) {
                return GraphMapping.RESERVED
              }
              return GraphMapping.CHILD
            } else {
              return GraphMapping.FILLED
            }
          })
      }),
  ]
}
