import React from 'react'

import { Machine, assign, actions } from 'xstate'
import { useMachine } from '@xstate/react'
import YouTube from 'react-youtube'
import VitPreview from '../VitPreview'
import PlayerOverlay from './PlayerOverlay'
import { Link } from 'react-router-dom'
import { PlayerObserverContext } from './PlayerObserverContext'

const { send, cancel } = actions

const playerMachine = Machine(
  {
    id: 'vitline_player',

    initial: 'idle',
    context: {
      playingIndex: 0,
      nextIndex: 1,
      offset: 0,
      startLoadTime: -1,
      endLoadTime: -1,
      loadTimes: [100],
      skip: {},
      interval: 1 / 10,
    },
    states: {
      idle: {
        on: {
          'USER.PLAY': {
            target: 'loading',
          },
        },
      },
      rendering: {
        on: {
          RENDERED: {
            target: 'loading',
          },
        },
      },
      loading: {
        entry: ['clearOldPlayers'],
        on: {
          'PLAYER.PLAYING': {
            target: 'playing',
            actions: ['loadingTimingEndAction'],
            cond: { type: 'isCurrentPlayer' },
          },
        },
      },
      playing: {
        invoke: {
          src: (context) => (cb) => {
            const interval = setInterval(() => {
              cb('TICK')
            }, 1000 * context.interval)

            return () => {
              clearInterval(interval)
            }
          },
        },
        entry: ['triggerPreplayAction', 'triggerNextThumbAction'],
        exit: [cancel('preplayTimer'), cancel('nextThumbTimer')],
        on: {
          PREPLAY: [
            {
              actions: ['triggerNextPlayAction', 'loadingTimingStartAction'],
              cond: { type: 'isNotLastPlayer' },
            },
          ],
          'PLAYER.PLAYING': [
            {
              actions: ['resetNextToStart', 'loadingTimingEndAction'],
              cond: { type: 'isNextPlayer' },
            },
          ],
          NEXTTHUMB: [
            {
              target: 'loading',
              actions: ['nextVideoAction', 'unmuteVideoAction'],
              cond: { type: 'isNotLastPlayer' },
            },
          ],
          'PLAYER.ENDED': [
            {
              target: 'loading',
              actions: ['nextVideoAction', 'unmuteVideoAction'],
              cond: { type: 'isNotLastPlayer' },
            },
            {
              target: 'ended',
              cond: { type: 'isLastPlayer' },
            },
          ],
          TICK: {
            actions: ['updateTimeAction'],
          },
          'USER.PAUSE': [
            {
              target: 'paused',
              actions: ['pauseCurrentPlayer'],
            },
          ],
        },
      },
      paused: {
        on: {
          'PLAYER.PLAYING': {
            target: 'playing',
          },
          'USER.PLAY': {
            target: 'playing',
            actions: ['playCurrentPlayer'],
          },
        },
      },
      ended: {
        entry: ['clearPlayers'],
        on: {
          'USER.PLAY': {
            target: 'loading',
            actions: [
              'loadToBeginning',
              'loadCurrentVideoAction',
              'cueNextVideoAction',
            ],
          },
        },
      },
    },
    on: {
      LOAD_SEQUENCE: {
        target: 'idle',
        actions: [
          assign({
            sequence: (context, event) => {
              return event.sequence
            },
          }),
        ],
      },
      'PLAYER.ERROR': [
        {
          target: 'loading',
          actions: [
            'markSkipVideoAction',
            'nextVideoAction',
            'loadCurrentVideoAction',
          ],
          cond: { type: 'isCurrentPlayer' },
        },
        {
          actions: ['markSkipVideoAction', 'nextPreplayAction'],
          cond: { type: 'isNextPlayer' },
        },
      ],
      'PLAYER.READY': [
        {
          actions: ['loadingTimingStartAction', 'loadCurrentVideoAction'],
          cond: { type: 'isCurrentPlayer' },
        },
        {
          actions: ['cueVideoAction'],
          cond: { type: 'isNextPlayer' },
        },
      ],
      'PLAYER.PAUSED': {
        target: 'paused',
      },
      'USER.SEEK_INDEX': [
        {
          target: 'playing',
          actions: ['seekCurrentVideoAction'],
          cond: { type: 'isSeekWithinCurrentVideo' },
        },
        {
          target: 'loading',
          actions: ['nextVideoAction', 'unmuteVideoAction'],
          cond: { type: 'isSeekWithinNextVideo' },
        },
        {
          target: 'loading',
          actions: [
            'loadToIndexAction',
            'loadCurrentVideoAction',
            'cueNextVideoAction',
          ],
        },
      ],
    },
  },
  {
    guards: {
      isFirstPlayer: (context, event) => 0 === event.index,
      isCurrentPlayer: (context, event) => context.playingIndex === event.index,
      isNextPlayer: (context, event) => context.nextIndex === event.index,
      isLastPlayer: (context) =>
        context.playingIndex === context.sequence.length - 1,
      isNotLastPlayer: (context) =>
        context.playingIndex < context.sequence.length - 1,
      isSeekWithinCurrentVideo: (context, event) =>
        event.index === context.playingIndex,
      isSeekWithinNextVideo: (context, event) =>
        event.index === context.nextIndex,
      shouldSkipIndex: (context, event) => context.skip[event.index] === true,
    },
    delays: {
      NEXT_DELAY: (context) => {
        const vit = context.sequence[context.playingIndex]
        return (vit.end - vit.start) * 1000
      },
    },
    actions: {
      triggerNextThumbAction: send('NEXTTHUMB', {
        id: 'nextThumbTimer',
        // delay determined from custom event.wait property
        delay: (context, event) => {
          const vit = context.sequence[context.playingIndex]
          const currentTime = event.currentTime ?? context.offset + vit.start
          return 1000 * (vit.end - currentTime)
        },
      }),
      cancelNextThumbAction: cancel('nextThumbTimer'),
      triggerPreplayAction: send('PREPLAY', {
        id: 'preplayTimer',
        // delay determined from custom event.wait property
        delay: (context, event) => {
          const vit = context.sequence[context.playingIndex]
          const prefetchTime = context.loadTimes[context.loadTimes.length - 1]
          const currentTime = event.currentTime ?? context.offset + vit.start
          return 1000 * (vit.end - currentTime) - prefetchTime
        },
      }),
      cancelPreplayAction: cancel('preplayTimer'),
      loadingTimingStartAction: assign({
        startLoadTime: () => {
          return Date.now()
        },
      }),
      loadingTimingEndAction: assign({
        startLoadTime: () => {
          return -1
        },
        loadTimes: (context) => {
          if (context.startLoadTime === -1) {
            return context.loadTimes
          }
          return [...context.loadTimes, Date.now() - context.startLoadTime]
        },
      }),
      nextVideoAction: assign({
        playingIndex: (context) => {
          for (
            let index = context.playingIndex + 1;
            index < context.sequence.length;
            index++
          ) {
            if (!context.skip[index]) {
              return index
            }
          }
          return -1
        },

        nextIndex: (context) => {
          let counter = 0
          for (
            let index = context.playingIndex + 1;
            index < context.sequence.length;
            index++
          ) {
            if (!context.skip[index]) {
              counter++
              if (counter === 2) {
                return index
              }
            }
          }
          return -1
        },
      }),

      markSkipVideoAction: assign({
        skip: (context, event) => {
          const newSkip = { ...context.skip }
          newSkip[event.index] = true
          return newSkip
        },
      }),

      nextPreplayAction: assign({
        nextIndex: (context) => {
          for (
            let index = context.nextIndex + 1;
            index < context.sequence.length;
            index++
          ) {
            if (!context.skip[index]) {
              return index
            }
          }
          return -1
        },
      }),

      loadToIndexAction: assign({
        playingIndex: (context, event) => {
          for (
            let index = event.index;
            index < context.sequence.length;
            index++
          ) {
            if (!context.skip[index]) {
              return index
            }
          }
          return -1
        },

        nextIndex: (context, event) => {
          let counter = 0
          for (
            let index = event.index;
            index < context.sequence.length;
            index++
          ) {
            if (!context.skip[index]) {
              counter++
              if (counter === 2) {
                return index
              }
            }
          }
          return -1
        },
      }),
      loadToBeginning: assign({
        playingIndex: 0,
        nextIndex: 1,
      }),
    },
  }
)

export const MemoLightPlayer = React.memo(LightPlayer)

export default function LightPlayer({
  id,
  sequence,
  color,
  url,
  disableTimeline,
  superLightMode = false,
  seekIndex = undefined,
  showDefaultControls = false,
  keepAliveAfterEnd = false,
  onLoadAPI = undefined,
}) {
  const playerMapRef = React.useRef(new Map())
  const playerObserver = React.useContext(PlayerObserverContext)

  const getRef = (index) => {
    return playerMapRef.current[index]
  }

  const setRef = (index, player) => {
    playerMapRef.current[index] = player
  }

  const getPlayerTime = (index) => {
    const vit = sequence[index]

    if (getRef(index)) {
      const currentTime = getRef(index).getCurrentTime()
      if (isNaN(currentTime)) {
        return vit.start
      }
      return Math.max(vit.start, Math.min(vit.end, currentTime))
    }

    return vit.start
  }

  const [state, send] = useMachine(playerMachine, {
    devTools: true,
    context: {
      sequence: sequence,
    },
    actions: {
      clearPlayers: (context) => {
        for (let i = 0; i < context.sequence.length; i++) {
          setRef(i, null)
        }
      },
      clearOldPlayers: (context) => {
        for (let i = 0; i < context.sequence.length; i++) {
          if (i !== context.playingIndex && i !== context.nextIndex) {
            setRef(i, null)
          }
        }
        setRef(context.playing)
      },
      loadCurrentVideoAction: (context) => {
        if (!getRef(context.playingIndex)) {
          return
        }
        const vit = context.sequence[context.playingIndex]
        getRef(context.playingIndex).loadVideoById({
          videoId: vit.source.videoId,
          startSeconds: vit.start,
          endSeconds: vit.end,
        })
      },

      cueNextVideoAction: (context) => {
        if (!getRef(context.nextIndex)) {
          return
        }

        const vit = context.sequence[context.nextIndex]
        getRef(context.nextIndex).cueVideoById({
          videoId: vit.source.videoId,
          startSeconds: vit.start,
          endSeconds: vit.end,
        })
      },

      cueVideoAction: (context, event) => {
        const vit = context.sequence[event.index]
        getRef(event.index).cueVideoById({
          videoId: vit.source.videoId,
          startSeconds: vit.start,
          endSeconds: vit.end,
        })
      },

      triggerNextPlayAction: (context) => {
        if (getRef(context.nextIndex)) {
          getRef(context.nextIndex).mute()
          getRef(context.nextIndex).playVideo()
        }
      },

      resetNextToStart: (context, event) => {
        const nextVit = context.sequence[event.index]
        if (getRef(event.index)) {
          getRef(event.index).seekTo(nextVit.start, true)
        }
      },

      unmuteVideoAction: (context) => {
        if (getRef(context.playingIndex)) {
          getRef(context.playingIndex).unMute()
          getRef(context.playingIndex).playVideo()
        }
      },

      seekCurrentVideoAction: (context, event) => {
        getRef(context.playingIndex).seekTo(
          context.sequence[context.playingIndex].start + event.offset
        )
      },

      updateTimeAction: assign({
        offset: (context) => {
          const currentVit = context.sequence[context.playingIndex]
          if (getRef(context.playingIndex)) {
            return getPlayerTime(context.playingIndex) - currentVit.start
          }
          return 0
        },
      }),

      pauseCurrentPlayer: (context) => {
        getRef(context.playingIndex).pauseVideo()
      },

      playCurrentPlayer: (context) => {
        getRef(context.playingIndex).playVideo()
      },
    },
  })

  const { playingIndex, nextIndex } = state.context

  let totalTime = 0
  let currentTime = 0
  let progressValue = 0

  for (let index = 0; index < sequence.length; index++) {
    const vit = sequence[index]
    const vitTime = vit.end - vit.start
    if (index < playingIndex) {
      currentTime += vitTime
      progressValue++
    } else if (index === playingIndex) {
      if (getRef(playingIndex)) {
        progressValue +=
          (getPlayerTime(playingIndex) - sequence[playingIndex].start) / vitTime
      }
    }
    totalTime += vitTime
  }

  if (getRef(playingIndex)) {
    currentTime += getPlayerTime(playingIndex) - sequence[playingIndex].start
  }

  React.useEffect(() => {
    if (playerObserver) {
      if (state.value === 'playing') {
        playerObserver(id, playingIndex, progressValue)
      } else if (state.value === 'ended') {
        playerObserver(id, undefined, progressValue)
      }
    }
  }, [playingIndex, progressValue, state.value, playerObserver])

  React.useEffect(() => {
    if (sequence) {
      send('LOAD_SEQUENCE', { sequence: sequence })
    }
  }, [sequence])

  const configForProgress = (progress) => {
    let timePlayed = 0

    for (let index = 0; index < sequence.length; index++) {
      const vit = sequence[index]
      const vitTime = vit.end - vit.start

      if ((timePlayed + vitTime) / totalTime > progress) {
        // seek to this index + time
        const offset = (progress - timePlayed / totalTime) * totalTime
        return { index: index, offset: offset }
      }

      timePlayed += vitTime
    }
    return { index: 0, offset: 0 }
  }

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      controls: showDefaultControls ? 1 : 0,
      showinfo: 0,
      modestbranding: 1,
    },
  }

  const currentProgress = state.value === 'ended' ? 1 : currentTime / totalTime

  const isIdle =
    state.value === 'idle' || (keepAliveAfterEnd && state.value === 'ended')
  const youtubeRenderer = isIdle
    ? null
    : sequence.map((vit, index) => {
        if (index === playingIndex || index === nextIndex) {
          return (
            <div
              key={vit.id + '@' + index}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: index === playingIndex ? 1 : -1,
              }}
            >
              <YouTube
                opts={opts}
                onReady={(e) => {
                  setRef(index, e.target)
                  send({
                    type: 'PLAYER.READY',
                    index: index,
                  })
                }}
                onPlay={() => {
                  send({
                    type: 'PLAYER.PLAYING',
                    index: index,
                    currentTime: getPlayerTime(index),
                  })
                }}
                onPause={() => {
                  send({
                    type: 'PLAYER.PAUSED',
                    index: index,
                  })
                }}
                onEnd={() => {
                  send({
                    type: 'PLAYER.ENDED',
                    index: index,
                  })
                }}
                onError={() => {
                  send({
                    type: 'PLAYER.ERROR',
                    index: index,
                  })
                }}
              />
            </div>
          )
        }
        return null
      })

  React.useEffect(() => {
    if (state.value === 'idle' && superLightMode) {
      send('USER.PLAY')
    }
  }, [state.value, superLightMode])

  React.useEffect(() => {
    if (superLightMode && seekIndex !== undefined && seekIndex !== null) {
      send('USER.SEEK_INDEX', { index: seekIndex, offset: 0 })
    }
  }, [superLightMode, seekIndex])

  React.useEffect(() => {
    if (onLoadAPI) {
      const api = {
        seekIndex: (index, offset) => {
          send('USER.SEEK_INDEX', { index: index, offset: offset })
        },
        pause: () => {
          send('USER.PAUSE')
        },
      }
      onLoadAPI(api)

      return () => {
        onLoadAPI(undefined)
      }
    }
  }, [send, onLoadAPI])

  if (superLightMode) {
    return youtubeRenderer
  }

  return (
    <PlayerOverlay
      url={url}
      player={youtubeRenderer}
      image={
        <Link to={url}>
          <VitPreview
            vit={sequence[playingIndex]}
            showPreview={false}
            draggable={false}
          ></VitPreview>
        </Link>
      }
      disableTimeline={disableTimeline}
      color={color}
      sequence={sequence}
      state={state.value}
      playingIndex={playingIndex}
      currentProgress={currentProgress}
      onPlay={() => {
        send('USER.PLAY')
      }}
      onPause={() => {
        send('USER.PAUSE')
      }}
      onSeek={(progress) => {
        send('USER.SEEK_INDEX', configForProgress(progress))
      }}
      onSeekIndex={(index) => {
        send('USER.SEEK_INDEX', { index: index, offset: 0 })
      }}
    ></PlayerOverlay>
  )
}
