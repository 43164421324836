import React from 'react'

import VitBuilder from './VitBuilder'
import Button from '@material-ui/core/Button'
import VitsAPI from '../../api/VitsAPI'
import NodeCardView from '../graph/nodes/NodeCardView'
import LightPlayer from '../player/LightPlayer'
import { ThumbnailView } from '../../ThumbnailView'

import AddIcon from '@material-ui/icons/Add'

function getYoutubeIdFromUrl(e) {
  var t = /(\?v=|&v=|\/\d\/|\/embed\/|\/v\/|\.be\/)([a-zA-Z0-9\-_]+)/,
    n = e,
    o = n.match(t)

  return o ? o[2] : null
}

export default function NewVitBuilder({
  parentVitlineId = null,
  index = -1,
  attachParentVitlineId = null,
  attachParentVitId = null,
  onSuccess = null,
  scrollingEnabled = false,
}) {
  const [query, setQuery] = React.useState('')
  const [builderVit, setBuilderVit] = React.useState(null)
  const youtubeVideoId = getYoutubeIdFromUrl(query)
  const [draft, draftVits, setDraftVits] = VitsAPI.useVitlineDraft()

  const updateVit = VitsAPI.useUpdateVitLineIndexMutation(
    parentVitlineId,
    index
  )

  const [vitAttach] = VitsAPI.useVitAttachMutation(attachParentVitId)
  const [vitlineAttach] = VitsAPI.useVitLineAttachMutation(
    attachParentVitlineId
  )

  const onSelect = React.useCallback((vit) => {
    if (parentVitlineId === draft.vitlineId) {
      const newVits = [...draftVits]
      newVits[index] = vit
      setDraftVits(newVits)
    } else if (parentVitlineId) {
      updateVit({
        variables: {
          vitId: vit.vitId,
        },
      })
    } else if (attachParentVitId) {
      vitAttach(vit.vitId)
    } else if (attachParentVitlineId) {
      vitlineAttach(vit.vitId)
    }

    if (onSuccess) {
      onSuccess(vit)
    }

    // TODO perhaps we should actually save it too?
    setBuilderVit(vit)
  })

  const onCreateNew = React.useCallback(() => {
    const sourcedVit = {
      start: 0,
      source: { videoId: youtubeVideoId },
    }
    setBuilderVit(sourcedVit)
  })

  if (builderVit) {
    return (
      <VitBuilder
        vit={builderVit}
        parentVitlineId={parentVitlineId}
        attachParentVitId={attachParentVitId}
        attachParentVitlineId={attachParentVitlineId}
        index={index}
        onCreate={(vit) => {
          if (onSuccess) {
            onSuccess(vit)
          }
        }}
        onReset={() => {
          setBuilderVit(null)
        }}
      ></VitBuilder>
    )
  }

  const hasParent =
    parentVitlineId || attachParentVitlineId || attachParentVitId
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: scrollingEnabled ? 'visible scroll' : undefined,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <label className="heading-2-character-style">
            {parentVitlineId
              ? 'SEARCH VITS OR PASTE YOUTUBE URL'
              : 'PASTE YOUTUBE URL'}
          </label>
          <input
            style={{
              display: 'flex',
              flexGrow: 1,
              marginLeft: '24px',
              marginRight: '24px',
            }}
            className="rounded-box code-character-style"
            type="text"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value)
            }}
          ></input>
        </div>

        {query.length === 0 && (
          <div
            style={{
              position: 'absolute',
              top: '20%',
              left: '20%',
              width: '60%',
              height: '60%',
            }}
          >
            <div
              className="node-attach node-thumbnail-img-clipped"
              style={{ fontSize: '400%' }}
            >
              <AddIcon fontSize="inherit"></AddIcon>
            </div>
          </div>
        )}

        {youtubeVideoId && (
          <div>
            <VitYoutubeView
              videoId={youtubeVideoId}
              onSelect={hasParent ? onSelect : undefined}
              onCreateNew={onCreateNew}
            ></VitYoutubeView>
          </div>
        )}
        {hasParent && !youtubeVideoId && query.length > 0 && (
          <div>
            <VitQueryView query={query} onSelect={onSelect}></VitQueryView>
          </div>
        )}
      </div>
    </div>
  )
}

function VitResultsView({ vits, onSelect, onCreateNew }) {
  const [selectedVitId, setSelectedVitId] = React.useState(null)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        {onCreateNew && (
          <Button color="primary" onClick={onCreateNew}>
            CREATE NEW
          </Button>
        )}
        {onSelect && (
          <Button
            disabled={!selectedVitId}
            color="primary"
            onClick={() => {
              const vitIndex = vits.findIndex((someVit) => {
                return someVit.vitId === selectedVitId
              })
              onSelect(vits[vitIndex])
            }}
          >
            CHOOSE
          </Button>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {(!vits || vits.length === 0) && (
          <div>No existing vits for that youtube URL.</div>
        )}
        {vits &&
          vits.map((vit, index) => {
            const isSelected = vit.vitId === selectedVitId
            return (
              <div
                key={index}
                style={{
                  position: 'relative',
                  width: '250px',
                  height: 'calc(250px * (11 / 16))',
                  backgroundColor: isSelected
                    ? 'var(--unnamed-color-e7f0eb)'
                    : undefined,
                  //border: isSelected
                  //  ? '2px solid var(--unnamed-color-137d3f)'
                  //  : '',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '10%',
                    left: '10%',
                    width: '80%',
                    height: '80%',
                  }}
                >
                  <CardRenderer
                    vit={vit}
                    isSelected={isSelected}
                    onSelect={() => {
                      setSelectedVitId(vit.vitId)
                    }}
                  ></CardRenderer>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

function CardRenderer({ vit, isSelected, onSelect }) {
  const image = VitsAPI.thumbnailForData(vit)
  const sequence = React.useMemo(() => [vit], [vit])

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
      }}
      onClick={onSelect}
    >
      <div
        className={'node'}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '80%',
        }}
      >
        <ThumbnailView image={image} showClip={true} />
        {isSelected && (
          <LightPlayer
            id={vit.vitId}
            sequence={sequence}
            color={'primary'}
            url={''}
            disableTimeline
            superLightMode
          ></LightPlayer>
        )}
      </div>
      <div
        className={'info'}
        style={{
          position: 'absolute',
          top: '80%',
          left: 0,
          width: '100%',
          height: '20%',
        }}
      >
        {vit ? (
          <div className={'info-box'}>
            <div className="info-box-title info-box-title-text">
              <div className={'max-lines-sm'}>{vit.title}</div>
            </div>
          </div>
        ) : (
          <div className="info-box">
            <div className="info-box-title info-box-title-text">
              <div className="max-lines-sm">EMPTY</div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function VitYoutubeView({ videoId, onSelect, onCreateNew }) {
  const [vits, loading] = VitsAPI.useSearchVideoVits(videoId)

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <VitResultsView
      vits={vits}
      onSelect={onSelect}
      onCreateNew={onCreateNew}
    ></VitResultsView>
  )
}

function VitQueryView({ query, onSelect }) {
  const [vits, loading] = VitsAPI.useSearchVits(query)

  if (loading) {
    return <div>Loading...</div>
  }

  return <VitResultsView vits={vits} onSelect={onSelect}></VitResultsView>
}
